import React, { Component } from 'react';

class Tech extends Component {
	state = {
		open: true
	};

	toggle = () => {
		this.setState({ open: ! this.state.open });
	};
	
	render() {
		const { factions, users } = this.props;
		const open = this.state.open ? ' open' : '';
		const techs = {
			aircraftArmor: 'Aircraft Armor', 
			aircraftWeapons: 'Aircraft Weapons',
			infantryArmor: 'Infantry Armor', 
			infantryWeapons: 'Infantry Weapons',
			vehicleArmor: 'Vehicle Armor', 
			vehicleWeapons: 'Vehicle Weapons',
			superweapon: 'Superweapon'
		};
		const factionGroups = Object.values(factions).reduce((groups, faction, index) => {
			const group = Math.floor(index / 2); 

			groups[group] = [].concat((groups[group] || []), faction); 

			return groups;
		}, []);

		return (
			<div className="status-province-large">
				<div className={`status-province-heading toggle${open}`} onClick={this.toggle}>Technology</div>
				<div className="replay-container">
				{factionGroups.map((g, k) => (
					<div className="replay-group" key={k}>
						<div className="replay-row header">
							<div className="replay-name"></div>
							{g.map(f => (
							<div className="replay-faction" key={f.id}>
								<div className={`owner user-colour faction-${f.id}`} title={f.abbreviation}></div>
							</div>
							))}
						</div>
						{Object.keys(techs).map(t => (
						<div className="replay-row" key={t}>
							<div>
								<div className="replay-name">{techs[t]}</div>
								{g.map(f => (
								<div className="replay-value" key={f.id}>
									{users[f.id].tech[t].level}
									{users[f.id].tech[t].active === true && ' (+)'}
								</div>
								))}
							</div>
						</div>
						))}
					</div>
				))}
				</div>
			</div>
		);
	}
};

export default Tech;
