import React, { Component } from 'react';

class EquipmentLoss extends Component {
	state = {
		open: true
	};

	groups = ['men', 'guns', 'vehicles', 'tanks', 'aircraft'];

	toggle = () => {
		this.setState({ open: ! this.state.open });
	};

	groupEquipment(combats) {
		const { equipment, factions, hour } = this.props;
		const gameEquipment = {};

		this.groups.forEach(e => {
			gameEquipment[e] = {};

			factions.forEach(f => {
				gameEquipment[e][f.id] = 0;
			});
		});

		Object.values(combats).forEach(c => {
			if (c.end <= hour && c.max_attacker_size > 0 && c.max_defender_size > 0) {
				c.equipmentLoss.forEach(e => {
					gameEquipment[equipment[e.equipment_id].toLowerCase()][e.faction_id] += e.value;
				});
			}
		});

		return gameEquipment;
	};

	render() {
		const { combats, factions } = this.props;
		const open = this.state.open ? ' open' : '';
		const gameEquipment = this.groupEquipment(combats);
		const capitalize = s => s[0].toUpperCase() + s.slice(1);
		const factionGroups = Object.values(factions).reduce((groups, faction, index) => {
			const group = Math.floor(index / 2); 

			groups[group] = [].concat((groups[group] || []), faction); 

			return groups;
		}, []);

		return (
			<div className="status-province-large">
				<div className={`status-province-heading toggle${open}`} onClick={this.toggle}>Equipment Loss</div>
				<div className="replay-container">
				{factionGroups.map((g, k) => (
					<div className="replay-group" key={k}>
						<div className="replay-row header">
							<div className="replay-name overview"></div>
							{g.map(f => (
							<div className="replay-faction overview" key={f.id}>
								<div className={`owner user-colour faction-${f.id}`} title={f.abbreviation}></div>
							</div>
							))}
						</div>
						{this.groups.map(e => (
							<React.Fragment key={e}>
							{gameEquipment[e] && <div className="replay-row overview">
								<div>
									<div className="replay-name overview">{capitalize(e)}</div>
									{g.map(f => (
									<div className="replay-value overview" key={f.id}>{Math.round(gameEquipment[e][f.id])}</div>
									))}
								</div>
							</div>}
							</React.Fragment>
						))}
					</div>
				))}
				</div>
			</div>
		);
	}
}

export default EquipmentLoss;
