import React, { Component } from 'react';
import Input from './input';
import Select from './select';
import Joi from 'joi-browser';

class Form extends Component {
	state = {
		data: {},
		errors: {}
	}

	validate = () => {
		const errors = {};
		const { error } = Joi.validate(this.state.data, this.schema, { abortEarly: false});

		if (! error) {
			return;
		}

		error.details.map(e => {
			return errors[e.path] = e.message;
		});

		return Object.keys(errors).length === 0 ? null : errors;
	};

	validateProperty = ({ name, value }) => {
		const obj = { [name]: value };
		const schema = { [name]: this.schema[name] };
		const { error } = Joi.validate(obj, schema);

		return (error) ? error.details[0].message : null;
	};

	handleChange({ currentTarget: input }, callback) {
		const errors = {...this.state.errors};
		const error = this.validateProperty(input);
		const data = {...this.state.data};

		if (error) {
			errors[input.name] = error;
		} else {
			delete errors[input.name];
		}

		data[input.name] = input.value;

		this.setState({ data, errors });

		if (callback) {
			callback.call(this, input.name, input.value);
		}
	};

	handleChecked({ currentTarget: input}, callback) {
		const data = {...this.state.data};

		data[input.name] = input.checked;

		this.setState({ data });

		if (callback) {
			callback.call(this, input.name, input.checked);
		}
	};

	handleSubmit = e => {
		e.preventDefault();

		const errors = this.validate();

		this.setState({ errors: errors || {} });

		if (errors) {
			return;
		}

		this.doSubmit();
	};

	renderButton = label => {
		return <button disabled={this.validate()} type="submit" className="btn btn-primary">{label}</button>
	};

	renderInput = (name, label, type = 'text', callback = null) => {
		const { data, errors } = this.state;

		return <Input name={name} type={type} label={label} value={data[name]} error={errors[name]} onChange={event => this.handleChange(event, callback)} />
	};

	renderSelect = (name, label, options, defaultOption, callback = null) => {
		const { data, errors } = this.state;

		return <Select name={name} label={label} value={data[name]} error={errors[name]} onChange={event => this.handleChange(event, callback)} options={options} defaultOption={defaultOption} />
	};
}

export default Form;
