import React, { Component } from 'react';
import ArmyCombatSummary from './armyCombatSummary.jsx';

class Army extends Component {
	displayAreaName(army) {
		const area = this.props.areas.find(a => a.id === army.game_area_id);

		return `${area.area.name}, ${area.area.region.name}`;
	};

	getUnitSummary(army) {
		const units = this.props.units;

		if (army.gameUnit.length === 0) {
			return [['Disbanded', null]];
		}

		const totals = army.gameUnit.reduce((totals, unit) => {
			if (! totals[unit.unit_id]) {
				totals[unit.unit_id] = {name: units[unit.unit_id].name, count: 0};
			}
			
			totals[unit.unit_id].count ++;

			return totals;
		}, {});

		const data = [];

		Object.values(totals).forEach(u => {
			data.push([u.name, u.count]);
		});

		return data;
	};

	getArmyEquipmentSummary(army) {
		const day = Math.floor(this.props.hour / 24);
		const snapshot = this.props.armySnapshots[day][army.id];
		const armySnapshotFields = this.props.armySnapshotFields;
		const capitalize = s => s[0].toUpperCase() + s.slice(1);
		const data = [];

		if (! snapshot) {
			return [['Disbanded', null]];
		}

		['men', 'aircraft', 'guns', 'tanks', 'vehicles'].forEach(e => {
			if (snapshot[armySnapshotFields[e]] > 0) {
				data.push([capitalize(e), snapshot[armySnapshotFields[e]]]);
			}
		});

		return data;
	};

	render() {
		const { areas, armies, combats, hour, ids } = this.props;
		const summaryArmies = armies.filter(a => ids.indexOf(a.id) !== -1);
		const factions = this.props.factions.reduce((map, faction) => {
			map[faction.id] = faction;

			return map;
		}, {});

		return (
			<div id="side-container">
			{summaryArmies.map(a => (
				<div className="group" key={a.id}>
					<div className="title">{a.name}</div>
					<div className="overview">
						<div className="row">
							<div className="text">Location</div>
							<div className="value">{this.displayAreaName(a)}</div>
						</div>
						<div className="row">
							<div className="text">Faction</div>
							<div className="value"><div className={`owner user-colour faction-${factions[a.faction_id].id}`}></div>{factions[a.faction_id].name}</div>
						</div>
					</div>
					<div className="title">Units</div>
					<div className="overview">
						<div className="data-row">
							{this.getUnitSummary(a).map((u, k) => (
								<React.Fragment key={k}>
									<div className="text">{u[0]}</div>
									<div className="value">{u[1]}</div>
								</React.Fragment>
							))}
						</div>
					</div>
					<div className="title">Equipment</div>
					<div className="overview">
						<div className="data-row">
							{this.getArmyEquipmentSummary(a).map((e, k) => (
								<React.Fragment key={k}>
									<div className="text">{e[0]}</div>
									<div className="value">{e[1]}</div>
								</React.Fragment>
							))}
						</div>
					</div>
					<div className="title">Engagements</div>
					<div className="overview">
						<ArmyCombatSummary areas={areas} army={a} combats={combats} hour={hour} />
					</div>
					<div className="overview">
						<div className="link army-movement" onClick={() => this.props.onToggleArmyPath(a.id)}>Toggle Army Movement Path</div>
					</div>
				</div>
			))}
			</div>
		);
	}
}

export default Army;
