import React from 'react';
import { formatDate } from '../utilities/date.js';
import cancelIcon from '../images/cancel.png';
import downIcon from '../images/down.png';
import upIcon from '../images/up.png';

const Development = ({ game, record, area, type, category, onCancel, onPriorityChange }) => {
	const target = type === 'structure' ? category[record.structure_id] : category[record.unit_id];
	const time = target.development_time * 86400;
	const finish = new Date(record.completion_date.date);
	const current = new Date(game.game_date.date);
	const offset = game.phase === 'combat-active' ? 1800 : 3600;
	const timeLeft = (finish.getTime() - current.getTime()) / 1000;
	const collection = type === 'structure' ? 'structureDevelopment' : 'unitDevelopment';
	let progressed = record.priority === 1 ? Math.round((time - timeLeft) / (time - offset) * 100) : Math.floor(record.progressed / time * 100);

	progressed = progressed <= 0 ? 0 : progressed >= 100 ? 100 : progressed;
	progressed = record.paused === 1 ? 0 : progressed;

	return (<div className="development">
		<div>
			{target.name}
			{record.priority > 1 && ` (Paused)`}
			{record.priority === 1 && record.paused === 1 && ` (Stopped)`}
			{record.priority === 1 && record.paused === 0 && <React.Fragment><br />Completion: {formatDate(finish)}</React.Fragment>}
			{record.priority !== 1 && record.paused === 0 && <React.Fragment><br />{progressed}% complete</React.Fragment>}
		</div>
		{record.priority === 1 && area[collection].length === 1 && <div className="progress-actions">
			<span className="link" onClick={() => onCancel(area.id, record.id, type)}><img alt="" src={cancelIcon} /></span>
		</div>}
		{record.priority === 1 && area[collection].length > 1 && <div className="progress-actions">
			<span className="link" onClick={() => onPriorityChange(area.id, record.id, type, 'down')}><img alt="" src={downIcon} />&nbsp;</span>
			<span className="link" onClick={() => onCancel(area.id, record.id, type)}><img alt="" src={cancelIcon} /></span>
		</div>}
		{record.priority !== 1 && <div className="progress-actions">
			{record.priority !== area[collection].length && <span className="link" onClick={() => onPriorityChange(area.id, record.id, type, 'down')}><img alt="" src={downIcon} />&nbsp;</span>}
			<span className="link" onClick={() => onPriorityChange(area.id, record.id, type, 'up')}><img alt="" src={upIcon} />&nbsp;</span>
			<span className="link" onClick={() => onCancel(area.id, record.id, type)}><img alt="" src={cancelIcon} /></span>
		</div>}
		{record.priority === 1 && <div className="progress-container active" title={`${progressed}%`}>
			<div className="progress-bar" style={{width: `${progressed}%`}}></div>
		</div>}
	</div>)
};

export default Development;
