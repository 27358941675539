import React, { Component } from 'react';
import { ReactComponent as Binoculars } from '../../images/binoculars.svg';
import { ReactComponent as Satellite } from '../../images/satellite.svg';
import { ReactComponent as Crates } from '../../images/crates.svg';
import { ReactComponent as Logistics } from '../../images/logistics.svg';
import { ReactComponent as Reallocation } from '../../images/reallocation.svg';
import { ReactComponent as Requisition } from '../../images/requisition.svg';
import { ReactComponent as Hospital } from '../../images/hospital.svg';
import { ReactComponent as Drills } from '../../images/drills.svg';
import { ReactComponent as Research } from '../../images/research.svg';
import { ReactComponent as Engineer } from '../../images/engineer.svg';
import { ReactComponent as Tactics } from '../../images/tactics.svg';
import { ReactComponent as Ammunition } from '../../images/ammunition.svg';
import { ReactComponent as Plan } from '../../images/plan.svg';
import { ReactComponent as Manpower } from '../../images/massmanpower.svg';

class Policy extends Component {
	state = {
		open: true
	};

	toggle = () => {
		this.setState({ open: ! this.state.open });
	};

	shouldComponentUpdate(nextProps, nextState) {
		if (nextState.open === this.state.open) {
			return false;
		}

		return true;
	};

	render() {
		const { factions, policies, users } = this.props;
		const open = this.state.open ? ' open' : '';
		const icons = {
			Binoculars: <Binoculars />,
			Satellite: <Satellite />,
			Crates: <Crates />,
			Logistics: <Logistics />,
			Reallocation: <Reallocation />,
			Requisition: <Requisition />,
			Hospital: <Hospital />,
			Drills: <Drills />,
			Research: <Research />,
			Engineer: <Engineer />,
			Tactics: <Tactics />,
			Ammunition: <Ammunition />,
			Plan: <Plan />,
			Manpower: <Manpower />
		};

		return (
			<div className="status-province-large">
				<div className={`status-province-heading toggle${open}`} onClick={this.toggle}>Policies</div>
				<div className="replay-container">
					<div className="replay-row header">
						<div className="replay-name grow"></div>
						{factions.map(f => (
						<div className="replay-faction" key={f.id}>
							<div className={`owner user-colour faction-${f.id}`} title={f.abbreviation}></div>
						</div>
						))}
					</div>
					<div className="replay-row">
						<div className="replay-name grow">Intelligence</div>
						{factions.map(f => (
						<div className="replay-value icon" key={f.id} title={policies[users[f.id].intelligence_policy_id].name}>
							{icons[policies[users[f.id].intelligence_policy_id].icon]}
						</div>
						))}
					</div>
					<div className="replay-row">
						<div className="replay-name grow">Logistics</div>
						{factions.map(f => (
						<div className="replay-value icon" key={f.id} title={policies[users[f.id].logistics_policy_id].name}>
							{icons[policies[users[f.id].logistics_policy_id].icon]}
						</div>
						))}
					</div>
					<div className="replay-row">
						<div className="replay-name grow">Economy</div>
						{factions.map(f => (
						<div className="replay-value icon" key={f.id} title={policies[users[f.id].economy_policy_id].name}>
							{icons[policies[users[f.id].economy_policy_id].icon]}
						</div>
						))}
					</div>
					<div className="replay-row">
						<div className="replay-name grow">Experience</div>
						{factions.map(f => (
						<div className="replay-value icon" key={f.id} title={policies[users[f.id].experience_policy_id].name}>
							{icons[policies[users[f.id].experience_policy_id].icon]}
						</div>
						))}
					</div>
					<div className="replay-row">
						<div className="replay-name grow">Technology</div>
						{factions.map(f => (
						<div className="replay-value icon" key={f.id} title={policies[users[f.id].technology_policy_id].name}>
							{icons[policies[users[f.id].technology_policy_id].icon]}
						</div>
						))}
					</div>
					<div className="replay-row">
						<div className="replay-name grow">Military</div>
						{factions.map(f => (
						<div className="replay-value icon" key={f.id} title={policies[users[f.id].military_policy_id].name}>
							{icons[policies[users[f.id].military_policy_id].icon]}
						</div>
						))}
					</div>
				</div>
			</div>
		);
	}
}

export default Policy;
