import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as AirForce } from '../images/airforce.svg';
import { ReactComponent as Area } from '../images/area.svg';
import { ReactComponent as Army } from '../images/army.svg';
import { ReactComponent as Fuel } from '../images/fuel.svg';
import { ReactComponent as Manpower } from '../images/manpower.svg';
import { ReactComponent as Money } from '../images/money.svg';
import { ReactComponent as Power } from '../images/power.svg';
import { ReactComponent as Supply } from '../images/supplies.svg';
import { formatDate } from '../utilities/date.js';
import menu from '../images/menu.png';

const Overview = ({ areas, armies, game, units, user, onQuit, onPause }) => {
	let unitCount = 0;
	let unitDevelopmentCount = 0;
	let airWingCount = 0;
	let airWingDevelopmentCount = 0;
	let controlledAreas = 0;

	armies.forEach(a => {
		if (a.type === 'air') {
			airWingCount += a.gameUnit.length;
		} else {
			a.gameUnit.forEach(u => {
				if (units[u.unit_id].unit_class_id === 5 || units[u.unit_id].unit_class_id === 6 || units[u.unit_id].unit_class_id === 10) {
					airWingCount ++;
				} else {
					unitCount ++;
				}
			});
		}
	});

	areas.forEach(a => {
		if (a.unitDevelopment) {
			a.unitDevelopment.forEach(u => {
				if (units[u.unit_id].unit_class_id === 5 || units[u.unit_id].unit_class_id === 6 || units[u.unit_id].unit_class_id === 10) {
					airWingDevelopmentCount ++;
				} else {
					unitDevelopmentCount ++;
				}
			});
		}
	});

	areas.forEach(a => {
		if (a.faction_id === user.faction_id) {
			controlledAreas ++;
		}
	});

	return (
		<div id="overview-container">
			<div title="Money">
				<Money width="16" height="16" fill="white" />
				<span>{user.money.toFixed(0)} ({user.resources.money})</span>
			</div>
			<div title="Supplies">
				<Supply width="16" height="16" fill="white" />
				<span>{user.supplies.toFixed(0)} ({user.resources.supplies})</span>
			</div>
			<div title="Fuel">
				<Fuel width="16" height="16" fill="white" />
				<span>{user.fuel.toFixed(0)} ({user.resources.fuel})</span>
			</div>
			<div title="Manpower">
				<Manpower width="16" height="16" fill="white" />
				<span>{user.manpower} ({user.resources.manpower})</span>
			</div>
			<div title="Power">
				<Power width="16" height="16" fill="white" />
				<span>{user.power}</span>
			</div>
			<div title="Areas">
				<Area width="16" height="16" fill="white" />
				<span>{controlledAreas}</span>
			</div>
			<div title="Land Divisions">
				<Army width="16" height="16" fill="white" />
				<span>{unitCount} {unitDevelopmentCount > 0 && `(+${unitDevelopmentCount})`}</span>
			</div>
			<div title="Air Divisions">
				<AirForce width="16" height="16" fill="white" />
				<span>{airWingCount} {airWingDevelopmentCount > 0 && `(+${airWingDevelopmentCount})`}</span>
			</div>
			<div>{formatDate(game.game_date.date)}</div>
			<div className="options-menu">
				<img src={menu} alt="" />
				<ul className="menu">
					<li><Link to="/game">Main Menu</Link></li>
					{!!game.paused && <li><Link to="/game/start">Start Game</Link></li>}
					{!game.paused && <li onClick={() => onPause()}>Pause Game</li>}
					<li onClick={() => onQuit()}>Quit Game</li>
					<li><Link to="/logout">Logout</Link></li>
				</ul>
			</div>
		</div>
	)
}

export default Overview;
