import React, { Component } from 'react';
import service from '../../services/structureService';

class Structure extends Component {
	state = {
		structures: []
	};

	componentDidMount = async () => {
		const { data: structures } = await service.all();

		this.setState({ structures });
	};

	render() {
		const { structures } = this.state;

		return (
			<React.Fragment>
				<div className="content_header"><h1>Structures</h1></div>
				<div className="content_content">
				{Object.keys(structures).map(faction => (
					<div className="sub_content" key={faction}>
						<div className="sub_content_heading">
							{faction}
						</div>
						<div>
							{structures[faction].map(structure => (
							<p key={structure.id}>
								<span className="sub_title">{structure.name}</span><br />
								Class: {structure.structure_class}<br />
								Prerequisites: {structure.prerequisites.join(', ')}<br />
								Cost: {structure.cost}<br />
								Power: {structure.power}<br />
								<br />
								{structure.description}
							</p>
						))}
						</div>
					</div>				
				))}
				</div>
			</React.Fragment>
		);
	};
}

export default Structure;
