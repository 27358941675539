import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as Area } from '../images/area.svg';
import { ReactComponent as Contract } from '../images/contract.svg';
import { ReactComponent as Expand } from '../images/expand.svg';
import { ReactComponent as Fuel } from '../images/fuel.svg';
import { ReactComponent as Gps } from '../images/gps.svg';
import { ReactComponent as HQ } from '../images/hq.svg';
import { ReactComponent as Manpower } from '../images/manpower.svg';
import { ReactComponent as Money } from '../images/money.svg';
import { ReactComponent as Paint } from '../images/paint.svg';
import { ReactComponent as Supply } from '../images/supplies.svg';
import { ReactComponent as SupplyFull } from '../images/supply-full.svg';
import Army from './map/army';
import Border from './map/border';
import Chat from './chat';
import Defs from './map/defs';
import MapArea from './map/area';
import Path from './map/path';
import Ping from './map/ping';
import ContextMenu from './contextMenu';
import Trade from './trade';
import Simplify from 'simplify-js';

class Map extends Component {
	state = {
		armyFocus: {},
		contextMenu: {},
		mapMode: 'area',
		mapUseMode: 'normal',
		mapType: 'zoom-1',
		selector: {
			active: false,
			x: 0,
			y: 0
		}
	};

	resourceThresholds = {
		manpower: [100, 25, 7],
		oil: [25, 7, 2],
		supplies: [40, 8, 2],
		wealth: [20, 5, 1]
	};

	painting = false;
	paintIndex = 0;
	paintPoints = [];
	hideInfo = null;
	mapTick = null;
	mapMovements = {};

	constructor(props) {
		super(props);

		this.info = React.createRef();
		this.paint = React.createRef();
		this.selector = React.createRef();
		this.svg = React.createRef();
	};

	componentDidMount() {
		window.addEventListener('keydown', this.handleKeyDown, false);
		window.addEventListener('keyup', this.handleKeyUp, false);
		window.addEventListener('mousemove', this.handlePan, false);
		document.addEventListener('mouseleave', this.handleLeave, false);
		document.addEventListener('visibilitychange', this.handleVisibilityChange, false);

		this.mapTick = window.setInterval(this.handleMapMove, 10);
	};

	componentWillUnmount()  {
		window.removeEventListener('keydown', this.handleKeyDown, false);
		window.removeEventListener('keyup', this.handleKeyUp, false);
		window.removeEventListener('mousemove', this.handlePan, false);
		document.removeEventListener('mouseleave', this.handleLeave, false);
		document.removeEventListener('visibilitychange', this.handleVisibilityChange, false);
		window.clearTimeout(this.hideInfo);
		window.clearInterval(this.mapTick);
	};

	getAreaClass(area, hasCombat, mapMode, user, capitals) {
		let className = '';

		switch (mapMode) {
			case 'manpower':
			case 'oil':
			case 'supplies':
			case 'wealth':
				if (area[mapMode] >= this.resourceThresholds[mapMode][0]) {
					className = 'capital';
				} else if (area[mapMode] >= this.resourceThresholds[mapMode][1]) {
					className = 'high';
				} else if (area[mapMode] >= this.resourceThresholds[mapMode][2]) {
					className = 'medium';
				} else {
					className = 'low';
				}

				user.alliance[area.faction_id] ? className += ' ours' : className += ' theirs';
			break;
			case 'hq':
				const { areas, armies, units } = this.props;

				const borderIds = area.area.border.reduce((ids, border) => {
					ids.push(border.area_2_id); 

					return ids;
				}, []);

				borderIds.push(area.area.id);
				const borders = areas.filter(a => borderIds.indexOf(a.area_id) !== -1 && a.faction_id === user.faction_id);
				const hq = armies.findIndex(a => borders.findIndex(b => b.id === a.game_area_id) !== -1 && a.gameUnit.findIndex(u => units[u.unit_id].unit_class_id === 8) !== -1) !== -1;

				className = hq ? 'hq' : 'no-hq';
			break;
			case 'supply-centre':
				if (area.gameFlag['area-supply-centre']) {
					className = 'centre-supply';
				} else if (area.gameFlag['area-supply-centre-full']) {
					className = 'full-supply';
				} else if (area.gameFlag['area-supply-centre-half']) {
					className = 'half-supply';
				} else {
					className = 'no-supply';
				}
			break;
			case 'area':
			default:
				className = `owner-${area.faction_id}`;

				if (hasCombat) {
					className += ' combat';
				}
			break;
		}

		if (capitals[area.id]) {
			className += ' capital';
		}

		return className;
	};

	getPathClass(path) {
		const user = this.props.user;

		return path.user === user.faction_id ? 'paint ours' : 'paint theirs';
	};

	getFilterName(area, selectedId, regionId) {
		const { borderingAreas, highlightedAreas, user } = this.props;

		if (regionId && area.area.region_id === regionId) {
			return 'url(#fog-highlight)';
		}

		if (area.faction_id !== user.faction_id && ! borderingAreas.find(a => a.id === area.id)) {
			if (selectedId === area.area.id || (highlightedAreas && highlightedAreas.hasOwnProperty(area.area.id))) {
				return 'url(#fog-highlight)';
			} else {
				return 'url(#fog)';
			}
		}

		if (selectedId === area.area.id || (highlightedAreas && highlightedAreas.hasOwnProperty(area.area.id))) {
			return 'url(#highlight)';
		}

		return '';
	};

	getDirectionalArrow(start, end) {
        let angle = Math.atan2(end.area.left_point - start.area.left_point, start.area.top_point - end.area.top_point);

        if (angle < 0) {
        	angle += Math.PI * 2;
        }

        angle *= (180 / Math.PI);

        if (angle > 337.5 || angle <= 22.5) {
        	return 'u';
        } else if (angle > 22.5 && angle <= 67.5) {
        	return 'ur';
        } else if (angle > 67.5 && angle <= 112.5) {
        	return 'r';
        } else if (angle > 112.5 && angle <= 157.5) {
        	return 'dr';
        } else if (angle > 157.5 && angle <= 202.5) {
        	return 'd';
        } else if (angle > 202.5 && angle <= 247.5) {
        	return 'dl';
        } else if (angle > 247.5 && angle <= 292.5) {
        	return 'l';
        } else {
        	return 'ul';
        }
	};

	getModeClass(mode) {
		return (this.state.mapMode === mode) ? 'mode active' : 'mode';
	};

	getUseModeClass(mode) {
		return (this.state.mapUseMode === mode) ? 'mode active' : 'mode';
	}

	calculateMapCoordinates(event) {
		const svg = this.svg.current;
		const theatre = this.props.theatre;
		const svgWidth = parseInt(svg.clientWidth);
		const svgHeight = parseInt(svg.clientHeight);
		const viewBox = svg.getAttribute('viewBox').split(' ');
		const viewBoxWidth = parseInt(viewBox[2]);
		const scaleDifference = (svgWidth / svgHeight) / (theatre.width / theatre.height);
		const widthScale = svgWidth / (viewBoxWidth * scaleDifference);
		const heightScale = svgHeight / parseInt(viewBox[3]);
		const x = parseInt((event.clientX - 316) / widthScale + parseInt(viewBox[0]) - ((viewBoxWidth * scaleDifference - viewBoxWidth) / 2));
		const y = parseInt((event.clientY - 25) / heightScale + parseInt(viewBox[1]));

		return {x, y};
	};

	handleContextMenu = (e, id) => {
		const { clientX, clientY } = e;
		const { activeAbility, areas, armies, game, location, selection, structures, units, user, onArmyAction, onNewStructure, onNewUnit } = this.props;
		const area = areas.find(a => a.area.id === parseInt(id));
		const areaMatch = location.pathname.match('/area/([0-9]+)');
		const armyMatch = location.pathname.match('/army/([0-9]+)');
		const structureMatch = location.pathname.match('/view/construction/([0-9]+)');
		const unitMatch = location.pathname.match('/view/unit/([0-9]+)');

		let selectedArmies = [];
		let autoMove = true;

		if (location.pathname === '/game/view/army/selection') {
			selectedArmies = armies.filter(a => selection.hasOwnProperty(a.id));
		} else if (location.pathname === '/game/view/area') {
			autoMove = false;
		} else if (areaMatch) {
			const sourceArea = areas.find(a => a.area.id === parseInt(areaMatch[1]));

			autoMove = false;
			selectedArmies = armies.filter(a => a.game_area_id === sourceArea.id);
		} else if (armyMatch) {
			selectedArmies = [armies.find(a => a.id === parseInt(armyMatch[1]))];
		} else if (structureMatch && area) {
			const structureId = parseInt(structureMatch[1]);
			const structure = structures[structureId];

			if (user.money >= structure.cost) {
				onNewStructure({ area_id: area.id, structure_id: structureId });
			}
		} else if (unitMatch && area) {
			const unitId = parseInt(unitMatch[1]);
			const unit = units[unitId];

			if (user.manpower >= unit.manpower && area.availableUnit && area.availableUnit.findIndex(u => u.id === unitId) !== -1) {
				onNewUnit({ area_id: area.id, unit_id: unitId });
			}
		} else if (location.pathname === '/game/view/army') {
			selectedArmies = armies;
		} else if (activeAbility) {
			this.props.onUsage(activeAbility, area.id);
		}

		if (e.ctrlKey || ! autoMove ) {
			let maxHeight = 0;
			let x = clientX;
			let y = clientY;
			let className = '';

			if (x + 202 > window.innerWidth) {
				x = window.innerWidth - 222;
			}

			if (x + 404 > window.innerWidth) {
				className += ' left';
			}

			if (area && area.availableStructure) {
				maxHeight = area.availableStructure.length + 1;
			}

			if (area && area.availableUnit) {
				maxHeight = area.availableUnit.length > maxHeight ? area.availableUnit.length : maxHeight;
			}

			maxHeight *= 16;

			if (y + maxHeight + 20 > window.innerHeight) {
				className += ' bottom';
			}

			const contextMenu = { visible: true, x, y, area, selectedArmies, units, user, className };

			this.setState({ contextMenu });
		} else if (selectedArmies.length) {
			const data = [];
			const targetId = area.id;

			selectedArmies.forEach(army => {
				let action = user.alliance[area.faction_id] ? 'move' : 'attack';

				if (action === 'attack' && army.type === 'air') {
					return;
				}

				if (action === 'attack' && game.phase !== 'combat' && game.phase !== 'combat-active') {
					return;
				}

				if (army.battleArmy.length) {
					if (action === 'attack') {
						return;
					}

					action = 'retreat';
				}

				if ((army.gameFlag.hasOwnProperty('army-entrenching') || army.gameFlag.hasOwnProperty('army-entrenching-fast') || 
					army.gameFlag.hasOwnProperty('army-entrenched') || army.gameFlag.hasOwnProperty('army-detrenching') || 
					army.gameFlag.hasOwnProperty('army-detrenching-fast')) && action !== 'retreat') {
					return;
				}

				return data.push({id: army.id, action, target_id: targetId, add: e.shiftKey});
			});

			if (data.length) {
				onArmyAction(data);
			}
		}

		e.preventDefault();
	};

	handleClick = e => {
		const contextMenu = {...this.state.contextMenu};

		if (contextMenu.visible === true) {
			contextMenu.visible = false;

			this.setState({ contextMenu });
		}
	};

	handleMouseDown = e => {
		if (e.target.closest('.mouse')) {
			return;
		}

		const theatre = this.props.theatre;

		if (this.state.mapUseMode === 'ping') {
			if (e.target.closest('.options')) {
				return;
			}

			const {x, y} = this.calculateMapCoordinates(e);

			if (x >= 0 && x <= theatre.width && y >= 0 && y <= theatre.height) {
				this.props.onSendMapPing(x, y);
			}

			return;
		} else if (this.state.mapUseMode === 'paint') {
			const {x, y} = this.calculateMapCoordinates(e);

			this.painting = true;

			if (! e.shiftKey) {
				this.paintIndex = 0;
				this.paintPoints = [];
			}

			this.paintPoints[this.paintIndex] = [{x, y}];

			return;
		}

		const element = this.selector.current;

		element.style.left = '0px';
		element.style.top = '0px';
		element.style.width = '0px';
		element.style.height = '0px';

		this.setState({ 
			selector: {
				active: true,
				x: e.clientX - 316,
				y: e.clientY - 25 + window.pageYOffset
			} 
		});
	};

	handleAreaNameDisplay = event => {
		const parent = event.target.parentNode;
		const element = this.info.current;

		if (event.target.nodeName === 'polyline') {
			return;
		}

		if (parent.nodeName !== 'a' || ! parent.dataset.name || this.state.selector.active) {
			element.style.display = 'none';

			window.clearTimeout(this.hideInfo);

			return;
		}

		element.innerText = parent.dataset.name;
		element.style.display = 'block';

		if (event.clientX + 132 > window.innerWidth) {
			element.style.right = '12px';
			element.style.left = 'unset';
		} else {
			element.style.left = `${event.clientX + 12}px`;
			element.style.right = 'unset';
		}

		element.style.top = `${event.clientY + 12}px`;

		if (this.hideInfo) {
			window.clearTimeout(this.hideInfo);
		}

		this.hideInfo = window.setTimeout(() => element.style.display = 'none', 1000);
	};

	handleMouseMove = e => {
		if (this.state.mapUseMode === 'paint' && this.painting) {
			const {x, y} = this.calculateMapCoordinates(e);

			this.paintPoints[this.paintIndex].push({x, y});

			const path = this.createPath();

			this.paint.current.setAttribute('d', path.join(' '));

			return;
		}

		if (! this.state.selector.active) {
			return false;
		}

		const { selector } = this.state;
		const element = this.selector.current;
		
		let left = selector.x;
		let top = selector.y;
		let width = e.clientX - selector.x - 316;
		let height = e.clientY - selector.y - 25 + window.pageYOffset;

		if (width <= 0) {
			left = e.clientX - 316;
			width = -width;
		}

		if (height <= 0) {
			top = e.clientY - 25;
			height = -height;
		}

		element.style.left = `${left}px`;
		element.style.top = `${top}px`;
		element.style.width = `${width}px`;
		element.style.height = `${height}px`;
		
		if (width > 4 && height > 4) {
			element.style.display = 'block';
		}

		e.preventDefault();
	};

	handleMouseUp = e => {
		if (this.state.mapUseMode === 'paint' && this.painting) {
			this.painting = false;

			if (e.shiftKey) {
				this.paintIndex ++;

				return;
			}

			const path = this.createPath();

			if (path.length > 1) {
				this.props.onSendMapPath(path.join(' '));
			}

			this.paint.current.setAttribute('d', '');
			this.paintIndex = 0;
			this.paintPoints = [];

			return;
		}

		if (! this.state.selector.active) {
			return false;
		}

		const { selector } = this.state;
		const { areas, theatre} = this.props;
		const element = this.selector.current
		const svg = this.svg.current;
		const svgWidth = parseInt(svg.clientWidth);
		const svgHeight = parseInt(svg.clientHeight);
		const viewBox = svg.getAttribute('viewBox').split(' ');
		const viewBoxWidth = parseInt(viewBox[2]);
		const scaleDifference = (svgWidth / svgHeight) / (theatre.width / theatre.height);
		const widthScale = svgWidth / (viewBoxWidth * scaleDifference);
		const heightScale = svgHeight / parseInt(viewBox[3]);
		let startX = 0;
		let startY = 0;
		let endX = 0;
		let endY = 0;

		if (e.clientX - 316 < selector.x) {
			endX = parseInt(selector.x / widthScale + parseInt(viewBox[0]) - ((viewBoxWidth * scaleDifference - viewBoxWidth) / 2));
			startX = parseInt(endX - parseInt(element.style.width) / widthScale);
		} else {
			startX = parseInt(selector.x / widthScale + parseInt(viewBox[0]) - ((viewBoxWidth * scaleDifference - viewBoxWidth) / 2));
			endX = parseInt(startX + parseInt(element.style.width) / widthScale);
		}

		if (e.clientY - 25 < selector.y) {
			endY = parseInt(selector.y / heightScale + parseInt(viewBox[1]));
			startY = parseInt(endY - parseInt(element.style.height) / heightScale);
		} else {
			startY = parseInt(selector.y / heightScale + parseInt(viewBox[1]));
			endY = parseInt(startY + parseInt(element.style.height) / heightScale);
		}

		element.style.display = 'none';

		if (startX === endX || startY === endY) {
			this.setState({ selector: {
				active: false
			}});

			return;
		}

		let selection = [...this.props.armies].reduce((count, army) => {
			let area = areas.find(a => a.id === army.game_area_id).area;

			if (endX - 4 > area.left_point && area.left_point - 4 > startX && endY - 4 > area.top_point && area.top_point - 4 > startY) {
				count[army.id] = true;
			}

			return count;
		}, {});

		if (e.shiftKey) {
			selection = Object.assign(selection, this.props.selection);
		}

		this.props.onSelection(selection, true);

		this.setState({ selector: {
			active: false
		}});
	};

	handleWheel = e => {
		if (e.target.closest('.mouse')) {
			return;
		}

		const svg = this.svg.current;
		const theatre = this.props.theatre;
		const viewBox = svg.getAttribute('viewBox').split(' ');
		const scale = theatre.width / theatre.height;
		const svgWidth = parseInt(svg.clientWidth);
		const svgHeight = parseInt(svg.clientHeight);
		const panWidthScale = svgWidth / theatre.width;
		const panHeightScale = svgHeight / theatre.height;
		let width = parseFloat(viewBox[2]);
		let height = parseFloat(viewBox[3]);

		if (e.deltaY > 0) {
			width += theatre.zoom;
			height += theatre.zoom / scale;
		} else {
			width -= theatre.zoom;
			height -= theatre.zoom / scale;
		}

		if (width >= theatre.width) {
			width = theatre.width;
			height = parseInt(theatre.width / scale);
		} else if (width <= theatre.width - theatre.zoom * 3) {
			width = theatre.width - theatre.zoom * 3;
			height = parseInt(width / scale);
		}

		let left = (parseFloat(viewBox[0]) + viewBox[2] / 2) - (width / 2);
		left = (left + width) * panWidthScale > svgWidth ? (svgWidth - width * panWidthScale) / panWidthScale : left;
		left = left <= 0 ? 0 : left;

		let top = (parseFloat(viewBox[1]) + viewBox[3] / 2) - (height / 2);
		top = (top + height) * panHeightScale > svgHeight ? (svgHeight - height * panHeightScale) / panHeightScale : top;
		top = top <= 0 ? 0 : top;

		svg.setAttribute('viewBox', `${left} ${top} ${width} ${height}`);

		const zoomLevel = theatre.zoom > 0 ? (theatre.width - width) / theatre.zoom + 1 : 1;

		this.setState({mapType: `zoom-${zoomLevel}`});
	};

	handleMapMove = () => {
		if (Object.keys(this.mapMovements).length === 0) {
			return;
		}

		const svg = this.svg.current;
		const theatre = this.props.theatre;
		const viewBox = svg.getAttribute('viewBox').split(' ');
		const svgWidth = parseInt(svg.clientWidth);
		const svgHeight = parseInt(svg.clientHeight);
		const panWidthScale = svgWidth / theatre.width;
		const panHeightScale = svgHeight / theatre.height;
		let left = parseFloat(viewBox[0]);
		let top = parseFloat(viewBox[1]);
		let width = parseFloat(viewBox[2]);
		let height = parseFloat(viewBox[3]);

		if (this.mapMovements.left) {
			left -= 4;
		} else if (this.mapMovements.right) {
			left += 4;
		}

		if (this.mapMovements.up) {
			top -= 4;
		} else if (this.mapMovements.down) {
			top += 4;
		}

		left = (left + width) * panWidthScale > svgWidth ? (svgWidth - width * panWidthScale) / panWidthScale : left;
		left = left <= 0 ? 0 : left;

		top = (top + height) * panHeightScale > svgHeight ? (svgHeight - height * panHeightScale) / panHeightScale : top;
		top = top <= 0 ? 0 : top;

		svg.setAttribute('viewBox', `${left} ${top} ${width} ${height}`);
	};

	handleKeyDown = e => {
		if (e.target.classList.contains('editable')) {
			return;
		}

		switch (e.key) {
			case 'a':
			case 'ArrowLeft':
				this.mapMovements.left = true;
			break;
			case 'w':
			case 'ArrowUp':
				this.mapMovements.up = true;
			break;
			case 'd':
			case 'ArrowRight':
				this.mapMovements.right = true;
			break;
			case 's':
			case 'ArrowDown':
				this.mapMovements.down = true;
			break;
			case 'Escape':
				if (this.state.mapUseMode === 'paint') {
					this.changeMapUseMode('paint');
					this.paint.current.setAttribute('d', '');
					this.painting = false;
					this.paintIndex = 0;
					this.paintPoints = [];
				} else if (this.state.mapUseMode === 'ping') {
					this.changeMapUseMode('ping');
				}

				return;
			default:
				return;
		}
	};

	handleKeyUp = e => {
		if (this.state.mapUseMode === 'paint' && this.painting === false) {
			if (e.key === 'Shift') {
				const path = this.createPath();

				if (path.length > 1) {
					this.props.onSendMapPath(path.join(' '));
				}

				this.paint.current.setAttribute('d', '');
				this.paintIndex = 0;
				this.paintPoints = [];
			}
		} else {
			if (e.target.classList.contains('editable')) {
				return;
			}

			switch (e.key) {
				case 'a':
				case 'ArrowLeft':
					delete this.mapMovements.left;
				break;
				case 'w':
				case 'ArrowUp':
					delete this.mapMovements.up;
				break;
				case 'd':
				case 'ArrowRight':
					delete this.mapMovements.right;
				break;
				case 's':
				case 'ArrowDown':
					delete this.mapMovements.down;
				break;
				default:
					return;
			}
		}
	};

	handleLeave = e => {
		if (e.clientY <= 0) {
			this.mapMovements.up = true;
		} else if (e.clientY >= window.innerHeight - 1) {
			this.mapMovements.down = true;
		}
	};

	handleVisibilityChange = () => {
		if (document.visibilityState === 'hidden') {
			this.mapMovements = {};

			window.clearInterval(this.mapTick);

			this.mapTick = null;
		} else if (! this.mapTick) {
			this.mapTick = window.setInterval(this.handleMapMove, 10);
		}
	};

	handlePan = e => {
		this.handleAreaNameDisplay(e);

		if (e.clientX === 0) {
			this.mapMovements.left = true;
		} else if (e.clientX === window.innerWidth - 1) {
			this.mapMovements.right = true;
		} else {
			delete this.mapMovements.left;
			delete this.mapMovements.right;
		}

		if (e.clientY === 0) {
			this.mapMovements.up = true;
		} else if (e.clientY === window.innerHeight - 1) {
			this.mapMovements.down = true;
		} else {
			delete this.mapMovements.up;
			delete this.mapMovements.down;
		}
	};

	handleClose = () => {
		const contextMenu = {...this.state.contextMenu};

		contextMenu.visible = false;

		this.setState({ contextMenu });
	};

	selectArmies = (areaId, gameAreaId, factionId) => {
		if (factionId !== this.props.user.faction_id) {
			this.props.history.push(`/game/view/area/${areaId}/armies/${factionId}`);

			this.setState({ armyFocus: {area: gameAreaId, faction: factionId} });

			return;
		}

		const selection = [...this.props.armies].reduce((count, army) => {
			if (army.game_area_id === gameAreaId) {
				count[army.id] = true;
			}

			return count;
		}, {});

		if (Object.values(selection).length === 1) {
			const armyId = Object.keys(selection).pop();

			this.props.history.push(`/game/view/army/${armyId}`);
		} else {
			this.props.onSelection(selection, true);
		}

		this.setState({ armyFocus: {area: gameAreaId, faction: factionId} });
	};

	changeMapMode = mode => {
		this.setState({ mapMode: mode });
	};

	changeMapUseMode = mode => {
		const currentMode = this.state.mapUseMode;

		if (mode === currentMode) {
			this.setState({ mapUseMode: 'normal' });
		} else {
			this.setState({ mapUseMode: mode });
		}
	};

	createPath() {
		let points = [];

		this.paintPoints.forEach(p => {
			const path = Simplify(p, 0.5).reduce((points, point, key) => {
				if (key === 0) {
					points.push(`M ${point.x} ${point.y}`);
				} else {
					points.push(`L ${point.x} ${point.y}`);
				}

				return points;
			}, []);

			points = points.concat(path);
		});

		return points;
	};

	render() {
		const { armyFocus, contextMenu, mapMode, mapType, mapUseMode, svg } = this.state;
		const { activeAbility, areas, armies, borderingAreas, combats, factions, fullscreen, game, location, pings, selection, theatre, user, users, onAcceptTrade, onArmyAction, onArmyMerge, onCancelTrade, onFullscreenToggle, onNewStructure, onNewUnit, onTrade } = this.props;
		const areaMatch = location.pathname.match('/area/([0-9]+)$');
		const armyMatch = location.pathname.match('/army/([0-9]+)');
		const regionMatch = location.pathname.match('/region/([0-9]+)');
		const capitals = Object.values(users).reduce((areas, user) => { 
			areas[user.capital_id] = true;

			return areas;
		}, {});
		const selectedAreaId = areaMatch ? parseInt(areaMatch[1]) : null;
		const selectedRegionId = regionMatch ? parseInt(regionMatch[1]) : null;
		const selectedArmies = armyMatch ? armies.filter(a => a.id === parseInt(armyMatch[1])) : selection && location.pathname === '/game/view/army/selection' ? armies.filter(a => selection.hasOwnProperty(a.id)) : [];
		const combatLocations = combats.reduce((locations, combat) => {
			locations[combat.game_area_id] = true;

			return locations;
		}, {});
		const focusArea = armyFocus.area && areas.find(a => a.id === armyFocus.area);
		const counterScale = theatre.counter_scale === 0 ? 1 : (mapType === 'zoom-3' || mapType === 'zoom-4' || theatre.zoom === 0) ? 0.65 : 0.85;
		const armyLocations = {};
		const armyMovements = {};
		let svgClass = `${mapMode} ${mapType} ${mapUseMode} theatre-${theatre.id}`;

		if (activeAbility) {
			svgClass += ' target';
		}

		borderingAreas.forEach(ba => {
			const total = Object.keys(ba.armies).length;
			let index = total - 1;
			let adjustIndex = true;

			for (const [k, a] of Object.entries(ba.armies)) {
				const f = parseInt(k);

				if (a.count > 0) {
					if (! armyLocations[ba.id]) {
						armyLocations[ba.id] = {};
						armyMovements[ba.id] = {};
					}

					a.index = index;
					a.total = total;

					if (armyFocus.area === ba.id && armyFocus.faction === f) {
						if (a.index === 0) {
							adjustIndex = false;
						}

						a.index = 0;
					} else if (armyFocus.area === ba.id && armyFocus.faction !== f && adjustIndex && index !== total - 1) {
						a.index ++;
					}

					armyLocations[ba.id][f] = a;

					Object.keys(a.actions).forEach(action => {
						a.actions[action].forEach(target => {
							let arrow = this.getDirectionalArrow(areas.find(m => m.id === ba.id), areas.find(m => m.id === target));

							if (! armyMovements[ba.id][f]) {
								armyMovements[ba.id][f] = {};
							}

							armyMovements[ba.id][f][arrow] = action;
						});
					});
				}

				index --;
			}
		});

		return <React.Fragment>
			<div id="map-container" tabIndex="0" onContextMenu={e => e.preventDefault()} onClick={this.handleClick} onMouseDown={this.handleMouseDown} onMouseMove={this.handleMouseMove} onMouseUp={this.handleMouseUp} onWheel={this.handleWheel}>
				<svg width="100%" height="100%" viewBox={`0 0 ${theatre.width} ${theatre.height}`} ref={this.svg} className={svgClass}>
					<Defs />
					{areas.map(a => (
						<Link to={`/game/view/area/${a.area.id}`} key={a.area.id} tabIndex="0" filter={this.getFilterName(a, selectedAreaId, selectedRegionId)} className={this.getAreaClass(a, combatLocations.hasOwnProperty(a.id), mapMode, user, capitals)} onContextMenu={e => this.handleContextMenu(e, a.area.id)} onMouseDown={e => e.preventDefault()} data-name={a.area.name}>
							<MapArea area={a.area} />
						</Link>
					))}
					<Border theatre={theatre} />
					{selectedArmies.map(a => a.armyAction && <Path key={a.id} areas={areas} army={a} />)}
					{areas.map(a => (
						<React.Fragment key={a.area.id}>
							{pings.hasOwnProperty(a.id) && <circle cx={a.area.left_point} cy={a.area.top_point} r="5" stroke="#FFFFFF" fill="none" strokeWidth="1">
								<animate attributeType="SVG" attributeName="r" begin="0s" dur="1s" repeatCount="indefinite" from="5" to="40" />
								<animate attributeType="CSS" attributeName="stroke-width" begin="0s" dur="1s" repeatCount="indefinite" from="5" to="0" />
								<animate attributeType="CSS" attributeName="opacity" begin="0s" dur="1s" repeatCount="indefinite" from="1" to="0" />
							</circle>}
							{armyLocations[a.id] && Object.keys(armyLocations[a.id]).map(f => (
								<React.Fragment key={f}>
									{(! armyFocus.area || (armyFocus.area !== a.id) || (armyFocus.area === a.id && armyFocus.faction !== f)) && <Army key={f} area={a} army={armyLocations[a.id][f]} movements={armyMovements[a.id][f]} scale={counterScale} onSelectArmies={this.selectArmies} />}
								</React.Fragment>
							))}
						</React.Fragment>
					))}
					{armyFocus.area && armyFocus.faction && armyLocations[armyFocus.area] && armyLocations[armyFocus.area][armyFocus.faction] && <Army area={focusArea} army={armyLocations[armyFocus.area][armyFocus.faction]} movements={armyMovements[armyFocus.area][armyFocus.faction]} scale={counterScale} onSelectArmies={this.selectArmies} />}
					<path ref={this.paint} className="paint" />
					{user.pings.map(p => <Ping key={p.id} ping={p} />)}
					{user.paths.map(p => <path key={p.id} d={p.path} className={this.getPathClass(p)} />)}
				</svg>
				{Object.keys(user.alliance).length > 1 && <div id="alliance" className="options">
					<Trade factions={factions} user={user} users={users} onAcceptTrade={onAcceptTrade} onCancelTrade={onCancelTrade} onTrade={onTrade} />
					<div className={this.getUseModeClass('ping')} onClick={() => this.changeMapUseMode('ping')}><Gps /></div>
					<div className={this.getUseModeClass('paint')} onClick={() => this.changeMapUseMode('paint')}><Paint /></div>
					<Chat user={user} users={users} />
				</div>}
				<div id="modes" className="options">
					<div onClick={() => this.changeMapMode('area')} className={this.getModeClass('area')}><Area /></div>
					<div onClick={() => this.changeMapMode('wealth')} className={this.getModeClass('wealth')}><Money /></div>
					<div onClick={() => this.changeMapMode('supplies')} className={this.getModeClass('supplies')}><Supply /></div>
					<div onClick={() => this.changeMapMode('oil')} className={this.getModeClass('oil')}><Fuel /></div>
					<div onClick={() => this.changeMapMode('manpower')} className={this.getModeClass('manpower')}><Manpower /></div>
					<div onClick={() => this.changeMapMode('hq')} className={this.getModeClass('hq')}><HQ /></div>
					{user.logistics_policy_id === 3 && <div onClick={() => this.changeMapMode('supply-centre')} className={this.getModeClass('supply-centre')}><SupplyFull /></div>}
					{fullscreen && <div className="mode" onClick={() => onFullscreenToggle(false)}><Contract /></div>}
					{!fullscreen && <div className="mode" onClick={() => onFullscreenToggle(true)}><Expand /></div>}
				</div>
				<div ref={this.selector} id="selector"></div>
				{game.phase === 'prepare' && <div id="combat-timer">Combat begins in {game.phase_duration}s</div>}
			</div>
			<div ref={this.info} id="area-information"></div>
			<ContextMenu {...this.props} 
				data={contextMenu} 
				game={game}
				onArmyAction={onArmyAction} 
				onArmyMerge={onArmyMerge}
				onClose={this.handleClose} 
				onNewStructure={onNewStructure} 
				onNewUnit={onNewUnit} 
			/>
		</React.Fragment>;
	}
}

export default Map;
