import React from 'react';

const UserOverview = ({ factions, user, users, onReady }) => {
	if (! user) {
		return;
	}

	const gameUsers = Object.values(users);
	const awaitingUsers = gameUsers.filter(u => ! u.user).length;
	const nonReadyUsers = gameUsers.filter(u => u.game_status === 'pending').length;
	const pausedUser = gameUsers.find(u => u.game_status === 'paused' || u.game_status === 'disconnected');
	const userDefeated = user.status === 'defeated';
	const userWon = user.status === 'won';
	const userLeft = user.status === 'left';

	return <div id="user-container">
		{gameUsers.map(u => (
			<div key={u.id} className="user-row">
				<div className={`user-colour faction-${u.faction_id}`}></div>
				<div className="user-faction">{factions[u.faction_id].name} ({factions[u.faction_id].abbreviation})</div>
				<div className="user-player">{u.user && u.user.handle}{! u.user && 'OPEN'}</div>
				<div className="user-action">
					{! awaitingUsers && u.user && u.user.id === user.user.id && (u.game_status === 'pending' || u.game_status === 'paused') && <button onClick={() => onReady()} className="button">Ready</button>}
					{! awaitingUsers && ! pausedUser && u.user && u.user.id === user.user.id && u.game_status === 'ready' && u.status !== 'left' && u.status !== 'won' && u.status !== 'defeated' && <button className="button ready">Ready</button>}
					{! awaitingUsers && ! pausedUser && u.user && u.user.id !== user.user.id && u.game_status === 'pending' && u.status !== 'left' && u.status !== 'won' && u.status !== 'defeated' && <button disabled={true} className="button">Ready</button>}
					{! awaitingUsers && ! pausedUser && u.user && u.user.id !== user.user.id && u.game_status === 'ready' && u.status !== 'left' && u.status !== 'won' && u.status !== 'defeated' && <button disabled={true} className="button ready">Ready</button>}
					{! awaitingUsers && u.user && u.user.id !== user.user.id && u.game_status === 'paused' && 'PAUSED'}
					{! awaitingUsers && u.user && u.user.id !== user.user.id && u.game_status === 'disconnected' && 'DISCONNECTED'}
					{u.user && u.status === 'defeated' && 'DEFEATED'}
					{u.user && u.status === 'won' && 'VICTORIOUS'}
					{u.user && u.status === 'left' && 'RESIGNED'}
				</div>
			</div>
		))}
		<div className="game-status">
			{awaitingUsers > 1 && `Waiting on ${awaitingUsers} more users to join the game`}
			{awaitingUsers === 1 && `Waiting on ${awaitingUsers} more user to join the game`}
			{awaitingUsers === 0 && nonReadyUsers > 1 && `Waiting on ${nonReadyUsers} users to be ready`}
			{awaitingUsers === 0 && nonReadyUsers === 1 && `Waiting on ${nonReadyUsers} user to be ready`}
			{awaitingUsers === 0 && nonReadyUsers === 0 && ! pausedUser && ! userDefeated && ! userWon && ! userLeft && 'Game starting!'}
			{userDefeated && 'You have been defeated!'}
			{userWon && 'You are victorious!'}
			{pausedUser && pausedUser.pauses !== 1 && `${pausedUser.user.handle} has ${pausedUser.pauses} pauses remaining (${pausedUser.pause_time}s)`}
			{pausedUser && pausedUser.pauses === 1 && `${pausedUser.user.handle} has ${pausedUser.pauses} pause remaining (${pausedUser.pause_time}s)`}
		</div>
	</div>;
}

export default UserOverview;
