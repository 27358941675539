import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import Joi from 'joi-browser';
import Form from '../common/form';
import Auth from '../../services/authService';
import User from '../../services/userService';

class RegisterForm extends Form {
	state = {
		data: {
			username: '',
			password: '',
			handle: ''
		},
		errors: {}
	};

	schema = {
		username: Joi.string().required().min(3).label('Username'),
		password: Joi.string().required().label('Password'),
		handle: Joi.string().required().min(3).label('Display Name')
	};

	doSubmit = async () => {
		try {
			const { data } = await User.save(this.state.data);

			Auth.loginWithToken(data.jwt);

			window.location = '/';
		} catch (e) {
			if (e.response && e.response.status === 400) {
				const errors = e.response.data;

				this.setState({ errors });
			}
		}
	};

	render() {
		if (Auth.getUser()) {
			return <Redirect to="/" />
		}
		return (
			<div className="home-outer">
				<div className="home-inner">
					<div className="container">
						<div className="heading">Register</div>
						<form onSubmit={this.handleSubmit}>
							{this.renderInput('username', 'Username')}
							{this.renderInput('password', 'Password', 'password')}
							{this.renderInput('handle', 'Display Name')}
							{this.renderButton('Register')}
						</form>
						<p><Link to="/">Login</Link></p>
					</div>
				</div>
			</div>
		);
	}
}

export default RegisterForm;
