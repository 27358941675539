import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Structure extends Component {
	getClassName(structureId, availableAreas) {
		if (availableAreas === 0) {
			return 'status-army disabled';
		}

		return 'status-army';
	};

	getStructureContent(structure) {
		const structures = this.props.structures;
		const structurePrerequisite = [];

		if (structure.structurePrerequisite) {
			structure.structurePrerequisite.forEach(s => {
				structurePrerequisite.push(structures[s.prerequisite_id].name);
			});
		}

		return <React.Fragment>
			<div className="status-create-name">
				{structure.name}
			</div>
			<div className="status-create-type">
				{structure.structureClass.name}
			</div>
			<div className="status-create-name-small">
				Requires: {structurePrerequisite.sort((a, b) => a.localeCompare(b)).join(', ')}
			</div>
		</React.Fragment>;
	};

	render() {
		const user = this.props.user;
		const areas = this.props.areas.filter(a => a.availableStructure.length);
		const structures = Object.values(this.props.structures).filter(s => s.factions[user.faction_id]).sort((a, b) => a.name.localeCompare(b.name));
		const availableAreas = [...structures].reduce((count, structure) => {
			count[structure.id] = areas.filter(a => a.availableStructure.findIndex(s => s.id === structure.id) !== -1).length;

			return count;
		}, {});

		return <React.Fragment>
		{structures.map(s => (
			<div className={this.getClassName(s.id, availableAreas[s.id])} key={s.id}>
				{availableAreas[s.id] !== 0 && <Link to={`/game/view/construction/${s.id}`} className="link-block">
					{this.getStructureContent(s)}
				</Link>}
				{!availableAreas[s.id] && <div className="link-block">
					{this.getStructureContent(s)}
				</div>}
			</div>
		))}
		</React.Fragment>;
	}
}

export default Structure;
