import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Structure extends Component {
	render() {
		const { areas, structures } = this.props;
		const { id, structureId } = this.props.match.params;
		const area = areas.find(a => a.area.id === parseInt(id));
		const structure = structures[structureId];

		return (
			<React.Fragment>
				<div className="status-province">
					<div className="status-province-area">{structure.name}</div>
				</div>
				<div className="status-province-large">
					<div className="status-province-heading">Area</div>
					<div className="status-province-list">
						<div className="status-province-list-name"><Link className="link" to={`/game/view/area/${area.area.id}`}>{area.area.name}</Link></div>
					</div>
				</div>
				<div className="status-province-large">
				    <div className="status-province-heading">Information</div>
					<div className="status-province-list" id="armyList">
						<div>
							<div className="status-province-list-name">Faction</div>
							<div className="status-province-list-count">
								{structure.faction && structure.faction.abbreviation}
								{!structure.faction && 'All'}
							</div>
						</div>
						<div>
							<div className="status-province-list-name">Class</div>
							<div className="status-province-list-count">{structure.structureClass.name}</div>
						</div>
						<div>
							<div className="status-province-list-name">Cost</div>
							<div className="status-province-list-count">{structure.cost}</div>
						</div>
						<div>
							<div className="status-province-list-name">Development Time</div>
							<div className="status-province-list-count">{structure.development_time}</div>
						</div>
						<div>
							<div className="status-province-list-name">Power</div>
							<div className="status-province-list-count">{structure.power}</div>
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default Structure;
