import React from 'react';
import CreateForm from './createForm';
import GameService from '../../services/gameService';

class JoinForm extends CreateForm {
	doSubmit = async () => {
		const { data } = this.state;

		data.join = true;

		try {
			const { data: game } = await GameService.save(data);

			if (game) {
				sessionStorage.setItem('game', JSON.stringify(game));

				this.props.history.replace('/game/view');
			}
		} catch (e) {
			if (e.response && e.response.status === 400) {
				const errors = e.response.data;

				this.setState({ errors });
			}
		}
	};

	render() {
		const { factions, theatres } = this.state;

		return (
			<form onSubmit={this.handleSubmit}>
				<div className="content_header"><h1>Join Game</h1></div>
				<div className="content_content">
					<div className="create-title">Theatre:</div>
					<div className="create-list">
						{this.renderSelect('theatreId', null, theatres, 'Any', this.selectTheatre)}
					</div>
					<div className="create-title">Faction:</div>
					<div className="create-list">
						{this.renderSelect('factionId', null, factions, 'Any')}
					</div>
					<div className="create-title">Detailed Combat?</div>
					<div className="create-list">
						<input name="enhancedCombat" type="checkbox" value="1" checked={this.state.data.enhancedCombat} onChange={event => this.handleChecked(event)} />
					</div>
					<div className="create-title">{this.renderButton('Join Game')}</div>
				</div>
			</form>
		);
	}
}

export default JoinForm;
