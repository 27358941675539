import React from 'react';
import { Link } from 'react-router-dom';

const CombatHistoryView = ({ combats, completedCombats, equipment, factions, match, user }) => {
	const combat = [...combats, ...completedCombats].find(c => c.id === parseInt(match.params.id));

	if (! combat) {
		return null;
	}

	const equipmentLoss = Object.keys(combat.equipmentLoss);
	const equipmentTypes = {};
	const equipmentLossTypes = {};
	const sides = {attacker: [], defender: []};
	const capitalize = s => s[0].toUpperCase() + s.slice(1);

	for (const [faction_id, side] of Object.entries(combat.participants)) {
		sides[side].push(parseInt(faction_id));
	}

	['men', 'aircraft', 'guns', 'tanks', 'vehicles'].forEach(e => {
		if (combat[`attacker_${e}`] || combat[`defender_${e}`]) {
			equipmentTypes[e] = true;
		}
	});

	equipmentLoss.forEach(f => {
		Object.keys(combat.equipmentLoss[f]).forEach(e => {
			equipmentLossTypes[e] = true;
		});
	});

	const factionGroups = [];

	[...sides.attacker, ...sides.defender].forEach((f, k) => {
		const group = Math.floor(k / 2); 

		factionGroups[group] = [].concat((factionGroups[group] || []), factions[f]); 
	});

	return (
		<React.Fragment>
			<div className="status-province">
				<div className="status-province-area">{combat.battle_name}</div>
			</div>
			<div className="status-province-large">
				<div className="status-province-heading">Strength</div>
				<div className="combat-column">
					{sides.attacker.map(a => (
						<div key={a} className={`owner user-colour faction-${a}`} title={factions[a].abbreviation}></div>
					))}
					<div className="combat-divisions">{combat.max_attacker_size} Divisions</div>
					{Object.keys(equipmentTypes).map(e => (
						<div key={e}>{capitalize(e)}: {Math.round(combat[`attacker_${e}`])}</div>
					))}
				</div>
				<div className="combat-column">
					{sides.defender.map(a => (
						<div key={a} className={`owner user-colour faction-${a}`} title={factions[a].abbreviation}></div>
					))}
					<div className="combat-divisions">{combat.max_defender_size} Divisions</div>
					{Object.keys(equipmentTypes).map(e => (
						<div key={e}>{capitalize(e)}: {Math.round(combat[`defender_${e}`])}</div>
					))}
				</div>
			</div>
			<div className="status-province-large">
				<div className="status-province-heading">Equipment Losses</div>
				{factionGroups.map((g, k) => (
				<div className="losses-group" key={k}>
					<div className="losses-row header">
						<div className="losses-name"></div>
						{g.map(f => (
						<div className="losses-faction" key={f.id}>
							<div className={`owner user-colour faction-${f.id}`} title={f.abbreviation}></div>
						</div>
						))}
					</div>
					{Object.keys(equipmentLossTypes).map(e => (
					<div className="losses-row" key={e}>
						<div>
							<div className="losses-name">{equipment[e].name}</div>
							{g.map(f => (
							<React.Fragment key={f.id}>
								{combat.equipmentLoss[f.id][e] && <div className="losses-value">{Math.round(combat.equipmentLoss[f.id][e].value)}</div>}
								{!combat.equipmentLoss[f.id][e] && <div className="losses-value">0</div>}
							</React.Fragment>
							))}
						</div>
					</div>
					))}
				</div>
				))}			
			</div>
			<Link to="/game/view/stats/combat" className="link offset">Back</Link>
		</React.Fragment>
	);
}

export default CombatHistoryView;
