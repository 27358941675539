import React from 'react';

const Defs = () => {
	return (
		<defs>
			<marker id="arrow-move" viewBox="0 0 10 10" refX="0" refY="5" orient="auto" fill="blue" stroke="none">
				<path d="M 0 0 L 10 5 L 0 10 z" />
			</marker>
			<marker id="arrow-attack" viewBox="0 0 10 10" refX="0" refY="5" orient="auto" fill="red" stroke="none">
				<path d="M 0 0 L 10 5 L 0 10 z" />
			</marker>
			<marker id="arrow-retreat" viewBox="0 0 10 10" refX="0" refY="5" orient="auto" fill="yellow" stroke="none">
				<path d="M 0 0 L 10 5 L 0 10 z" />
			</marker>
			<marker id="arrow-redeployment" viewBox="0 0 10 10" refX="0" refY="5" orient="auto" fill="green" stroke="none">
				<path d="M 0 0 L 10 5 L 0 10 z" />
			</marker>
			<pattern id="diagonalHatch" width="4" height="4" patternTransform="rotate(45 0 0)" patternUnits="userSpaceOnUse">
				<rect width="1" height="4" transform="translate(0,0)" fill="black" />
			</pattern>
			<filter id="fog">
				<feComponentTransfer>
					<feFuncR type="linear" slope="0.5" />
					<feFuncG type="linear" slope="0.5" />
					<feFuncB type="linear" slope="0.5" />
				</feComponentTransfer>
			</filter>
			<filter id="fog-highlight">
				<feComponentTransfer>
					<feFuncR type="linear" slope="0.3" />
					<feFuncG type="linear" slope="0.3" />
					<feFuncB type="linear" slope="0.3" />
				</feComponentTransfer>
			</filter>
			<filter id="highlight">
				<feComponentTransfer>
					<feFuncR type="linear" slope="0.7" />
					<feFuncG type="linear" slope="0.7" />
					<feFuncB type="linear" slope="0.7" />
				</feComponentTransfer>
			</filter>
		</defs>
	);
};

export default Defs;
