import React, { Component } from 'react';
import Army from './map/army';
import Area from '../services/areaService';
import Polylabel from 'polylabel';

class PoleOfInaccessability extends Component {
	state = {
		active: false,
		areas: null,
		borders: null,
		element: null,
		marked: {},
		start: {left: null, top: null}
	}

	async componentDidMount() {
		const response = await Area.coordinates(4);

		this.setState({ areas: response.data.areas, borders: response.data.borders });
	};

	handleMouseDown = (event, type, id) => {
		if (this.state.active === false) {
			const ctm = event.target.parentNode.getCTM();

			this.setState({ active: id, start: {left: ctm.e, top: ctm.f} });
		} else {
			this.setState({ active: false });
		}
	};

	handleMouseMove = event => {
		if (! this.state.active) {
			return;
		}

		const areas = [...this.state.areas];
		const area = areas.findIndex(a => a.id === this.state.active);
		const x = event.clientX / 2 + window.scrollX / 2;
		const y = event.clientY / 2 + window.scrollY / 2;

		areas[area].area.left_point = x;
		areas[area].area.top_point = y;

		this.setState({ areas });
	};

	markArea = id => {
		const marked = this.state.marked;

		marked[id] = true;

		this.setState({ marked });
	};

	getFill(id) {
		return this.state.marked[id] ? 'black' : 'cadetblue';
	};

	submitData() {
		const areas = this.state.areas;
		const data = [];

		areas.forEach(a => {
			data.push({id: a.id, left: a.area.left_point, top: a.area.top_point});
		});

		Area.poi(data);
	};

	calculatePOI() {
		const areas = [...this.state.areas];

		areas.forEach(a => {
			const data = [];

			a.areaCoordinate.forEach(ac => {
				const c = ac.coordinates.split(' ');
				const set = [];

				c.forEach(i => {
					const pair = i.split(',').map(x => parseInt(x));

					set.push(pair);
				});

				data.push(set);
			});

			const result = Polylabel(data, 10000);

			a.area.left_point = result[0];
			a.area.top_point = result[1];
		});

		this.setState({ areas });
	};

	render() {
		const { areas, borders, marked } = this.state;
		const armyLocations = {};
		const armyMovements = {};

		if (! areas) {
			return null;
		}

		return (
			<div>
				<svg width="2328" height="1600" viewBox="0 0 1164 800" onMouseMove={event => this.handleMouseMove(event)}>
					<defs>
						<marker id="arrow-move" viewBox="0 0 10 10" refX="0" refY="5" orient="auto" fill="blue" stroke="none">
							<path d="M 0 0 L 10 5 L 0 10 z" />
						</marker>
						<marker id="arrow-attack" viewBox="0 0 10 10" refX="0" refY="5" orient="auto" fill="red" stroke="none">
							<path d="M 0 0 L 10 5 L 0 10 z" />
						</marker>
						<marker id="arrow-retreat" viewBox="0 0 10 10" refX="0" refY="5" orient="auto" fill="yellow" stroke="none">
							<path d="M 0 0 L 10 5 L 0 10 z" />
						</marker>
						<marker id="arrow-redeployment" viewBox="0 0 10 10" refX="0" refY="5" orient="auto" fill="green" stroke="none">
							<path d="M 0 0 L 10 5 L 0 10 z" />
						</marker>
						<pattern id="diagonal-hatch" width="4" height="4" patternTransform="rotate(45 0 0)" patternUnits="userSpaceOnUse">
							<rect width="1" height="4" transform="translate(0,0)" fill="black" />
						</pattern>
						<filter id="fog">
							<feComponentTransfer>
								<feFuncR type="linear" slope="0.5" />
								<feFuncG type="linear" slope="0.5" />
								<feFuncB type="linear" slope="0.5" />
							</feComponentTransfer>
						</filter>
						<filter id="fog-highlight">
							<feComponentTransfer>
								<feFuncR type="linear" slope="0.3" />
								<feFuncG type="linear" slope="0.3" />
								<feFuncB type="linear" slope="0.3" />
							</feComponentTransfer>
						</filter>
						<filter id="highlight">
							<feComponentTransfer>
								<feFuncR type="linear" slope="0.7" />
								<feFuncG type="linear" slope="0.7" />
								<feFuncB type="linear" slope="0.7" />
							</feComponentTransfer>
						</filter>
					</defs>
					{areas.map(a => (
						<React.Fragment key={a.id}>
							<g tabIndex="0" fill={this.getFill(a.id)} onMouseDown={event => event.preventDefault()} onClick={() => this.markArea(a.id)}>
							{a.areaCoordinate.map(c => (
								<React.Fragment key={c.id}>
									<polygon points={c.coordinates} />
								</React.Fragment>
							))}
							</g>
						</React.Fragment>
					))}
					{borders.map(b => (
						<polyline key={b.id} points={b.coordinates} className={b.type} />
					))}
					{areas.map(a => (
						<React.Fragment key={a.id}>
							{! marked[a.id] && 
							<Army 
								area={a} 
								army={armyLocations[a.id]} 
								largeMovementIndicator={false}
								movements={armyMovements[a.id]}
								scale={1} 
								onMouseDown={this.handleMouseDown} 
							/>}
						</React.Fragment>
					))}
				</svg>
				<button onClick={() => this.submitData()}>Update</button>
				<button onClick={() => this.calculatePOI()}>Calculate</button>
			</div>
		);
	}
}

export default PoleOfInaccessability;
