import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Unit extends Component {
	renderTechBonus(unit, armyUnit, field) {
		return unit[field] + armyUnit[field];
	};

	render() {
		const { armies, units } = this.props;
		const { id, unitId } = this.props.match.params;
		const army = armies.find(a => a.id === parseInt(id));
		const unit = units[unitId];
		const armyUnit = army.gameUnit.find(u => u.unit_id === parseInt(unitId));

		return (
			<React.Fragment>
				<div className="status-province">
					<div className="status-province-area">{unit.name}</div>
				</div>
				<div className="status-province-large">
					<div className="status-province-heading">Army</div>
					<div className="status-province-list">
						<div className="status-province-list-name"><Link to={`/game/view/army/${army.id}`} className="link">{army.name}</Link></div>
					</div>
				</div>
				<div className="status-province-large">
					<div className="status-province-heading">Information</div>
					<div className="status-province-list">
						<div>
							<div className="status-province-list-name">Faction</div>
							<div className="status-province-list-count">
								{unit.faction && unit.faction.abbreviation}
								{!unit.faction && 'All'}
							</div>
						</div>
						<div>
							<div className="status-province-list-name">Unit Class</div>
							<div className="status-province-list-count">{unit.unitClass.name}</div>
						</div>
						<div>
							<div className="status-province-list-name">Development Time</div>
							<div className="status-province-list-count">{unit.development_time}</div>
						</div>
						<div>
							<div className="status-province-list-name">Soft Attack</div>
							<div className="status-province-list-count">{this.renderTechBonus(unit, armyUnit, 'soft_attack')}</div>
						</div>
						<div>
							<div className="status-province-list-name">Hard Attack</div>
							<div className="status-province-list-count">{this.renderTechBonus(unit, armyUnit, 'hard_attack')}</div>
						</div>
						<div>
							<div className="status-province-list-name">Air Attack</div>
							<div className="status-province-list-count">{this.renderTechBonus(unit, armyUnit, 'air_attack')}</div>
						</div>
						<div>
							<div className="status-province-list-name">Defensiveness</div>
							<div className="status-province-list-count">{this.renderTechBonus(unit, armyUnit, 'defensiveness')}</div>
						</div>
						<div>
							<div className="status-province-list-name">Toughness</div>
							<div className="status-province-list-count">{this.renderTechBonus(unit, armyUnit, 'toughness')}</div>
						</div>
						<div>
							<div className="status-province-list-name">Air Defence</div>
							<div className="status-province-list-count">{this.renderTechBonus(unit, armyUnit, 'air_defence')}</div>
						</div>
						<div>
							<div className="status-province-list-name">Softness</div>
							<div className="status-province-list-count">{unit.softness}</div>
						</div>
						<div>
							<div className="status-province-list-name">Speed</div>
							<div className="status-province-list-count">{this.renderTechBonus(unit, armyUnit, 'speed')}</div>
						</div>
					</div>
				</div>
				<div className="status-province-large">
					<div className="status-province-heading">Equipment</div>
					{unit.unitEquipment.map(e => (
					<div className="status-province-list" key={e.id}>
						<div>
							<div className="status-province-list-name">{e.equipment.name}</div>
							<div className="status-province-list-count">{e.value}</div>
						</div>
					</div>
					))}
				</div>
			</React.Fragment>
		)
	}
}

export default Unit;
