import http from './httpService';

function allUsers() {
	return http.get('/users');
}

function getUser(id) {
	return http.get('/users/' + id);
}

function saveUser(user) {
	if (user.id) {
		const data = { ...user };

		delete data.id;

		return http.put('/users/' + user.id, data);
	}

	return http.post('/users/', user);
}

function deleteUser(id) {
	return http.delete('/users/' + id);
}

export default {
	all: allUsers,
	get: getUser,
	save: saveUser,
	delete: deleteUser
};
