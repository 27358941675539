import React from 'react';
import Joi from 'joi-browser';
import Form from '../common/form';

class SplitForm extends Form {
	state = {
		army: null,
		fromUnits: [],
		toUnits: [],
		data: {
			splitFrom: [],
			splitTo: null
		},
		errors: {}
	};

	constructor(props) {
		super(props);

		this.state.army = props.army;
		this.state.fromUnits = props.army.gameUnit;
		this.state.data.splitFrom = props.army.gameUnit.map(u => u.id);
	}

	static getDerivedStateFromProps(props, state) {
		let reset = false;

		state.fromUnits.forEach(u => {
			if (! props.army.gameUnit.find(gu => gu.id === u.id)) {
				reset = true;
			}
		});

		state.toUnits.forEach(u => {
			if (! props.army.gameUnit.find(gu => gu.id === u.id)) {
				reset = true;
			}
		});

		if (props.army.gameUnit.length !== state.fromUnits.length + state.toUnits.length) {
			reset = true;
		}

		if (reset) {
			return {
				army: props.army,
				fromUnits: props.army.gameUnit,
				toUnits: [],
				data: {
					splitFrom: props.army.gameUnit.map(u => u.id),
					splitTo: null
				}
			};
		}

		return null;
	};

	schema = {
		splitTo: Joi.array().min(1).required().label('To'),
		splitFrom: Joi.array().min(1).required().label('From'),
	};

	moveUnit = (source, id) => {
		let fromUnits = [...this.state.fromUnits];
		let toUnits = [...this.state.toUnits];

		if (source === 'new') {
			const unit = fromUnits.find(u => parseInt(u.id) === id);

			fromUnits = fromUnits.filter(u => parseInt(u.id) !== id);
			toUnits.push(unit);

			const data = {splitFrom: fromUnits.map(u => u.id), splitTo: toUnits.map(u => u.id)};

			this.setState({ data, fromUnits, toUnits });
		} else if (source === 'old') {
			const unit = toUnits.find(u => parseInt(u.id) === id);

			toUnits = toUnits.filter(u => parseInt(u.id) !== id);
			fromUnits.push(unit);

			const data = {splitFrom: fromUnits.map(u => u.id), splitTo: toUnits.map(u => u.id)};

			this.setState({ data, fromUnits, toUnits });
		}
	};

	onDragStart = (event, source, id) => {
		event.dataTransfer.setData('id', id);
		event.dataTransfer.setData('source', source);
	};

	onDragOver = event => {
		event.preventDefault();
	};

	onDrop = (event, source) => {
		const id = event.dataTransfer.getData('id');
		const dragSource  = event.dataTransfer.getData('source');

		if (source !== dragSource) {
			this.moveUnit(source, parseInt(id));
		}
	};

	doSubmit = () => {
		const data = {...this.state.data, id: this.props.army.id};
		
		this.props.onSplit(data);
	};

	render() {
		const { fromUnits, toUnits } = this.state;
		const { units } = this.props;

		return (
			<div className="split-army-container">
				<form onSubmit={this.handleSubmit}>
					<div className="split-unit-list" onDragOver={(event) => this.onDragOver(event)} onDrop={(event) => this.onDrop(event, 'old')}>
					{fromUnits.map(u => (
						<div key={u.id} draggable onDragStart={(event) => this.onDragStart(event, 'old', u.id)} onDoubleClick={() => this.moveUnit('new', u.id)} className="split-unit">
							{units[u.unit_id].name} - {Math.floor(u.strength / 10000)} / {Math.floor(u.organisation / 10000)}
						</div>
					))}
					</div>
					<div className="split-unit-list" onDragOver={(event) => this.onDragOver(event)} onDrop={(event) => this.onDrop(event, 'new')}>
					{toUnits.map(u => (
						<div key={u.id} draggable onDragStart={(event) => this.onDragStart(event, 'new', u.id)} onDoubleClick={() => this.moveUnit('old', u.id)} className="split-unit">
							{units[u.unit_id].name} - {Math.floor(u.strength / 10000)} / {Math.floor(u.organisation / 10000)}
						</div>
					))}
					</div>
					<div className="split-army-buttons">
						<button disabled={this.validate()} type="submit" name="split-army">Split Army</button>
					</div>
				</form>
			</div>
		);
	}
}

export default SplitForm;
