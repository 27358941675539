import http from './httpService';

function allAreas() {
	return http.get('/areas');
}

function getAreas(id) {
	return http.get('/areas/' + id);
}

function getCoordinates(id) {
    return http.get('/areas/coordinates/' + id);
}

function poi(data) {
    return http.put('/areas/poi', data);
}

export default {
	all: allAreas,
	get: getAreas,
    coordinates: getCoordinates,
    poi: poi
};
