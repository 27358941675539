import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Combat from '../combat';
import ContentEditable from '../common/contentEditable';
import MergeForm from '../forms/mergeForm';
import MissionForm from '../forms/missionForm';
import SplitForm from '../forms/splitForm';
import { ReactComponent as Fuel } from '../../images/fuel.svg';
import { ReactComponent as Supply } from '../../images/supplies.svg';
import { ReactComponent as HQ } from '../../images/hq.svg';
import { ReactComponent as SupplyHalf } from '../../images/supply-half.svg';
import { ReactComponent as SupplyFull } from '../../images/supply-full.svg';
import { ReactComponent as Entrench } from '../../images/entrench.svg';
import { ReactComponent as Tactics } from '../../images/tactics.svg';
import { ReactComponent as Ammunition } from '../../images/ammunition.svg';
import { ReactComponent as Plan } from '../../images/plan.svg';
import { ReactComponent as Manpower } from '../../images/massmanpower.svg';
import { ReactComponent as Buff } from '../../images/uparrow.svg';
import { ReactComponent as Reserve } from '../../images/reserve.svg';
import { ReactComponent as Star } from '../../images/star.svg';
import { ReactComponent as Split } from '../../images/split.svg';
import { ReactComponent as Loan } from '../../images/loan.svg';
import { ReactComponent as Return } from '../../images/return.svg';

class Army extends Component {
	renderAbility(army, ability, title, cost, Icon) {
		const { units, user } = this.props;
		const canUseAbility = !army.armyAction.length && !army.battleArmy.length && !army.gameFlag.hasOwnProperty('army-plan-offensive');
		const isFortify = army.gameFlag.hasOwnProperty('army-entrenching') || army.gameFlag.hasOwnProperty('army-detrenching');

		if (ability === 'unit-blitz' && user.military_policy_id !== 11) {
			return null;
		}

		if (ability === 'unit-shock-and-awe' && user.military_policy_id !== 12) {
			return null;
		}

		if (ability === 'army-plan-offensive' && user.military_policy_id !== 13) {
			return null;
		}

		if (ability === 'unit-unrelenting-assault' && user.military_policy_id !== 14) {
			return null;
		}

		if (isFortify || ! canUseAbility) {
			return <div className="army-ability inactive" title={title}>
				{Icon}
			</div>;
		}

		if (ability === 'army-reserve') {
			if (army.reserve === 0) {
				return <div className="army-ability" title={title} onClick={() => this.props.onUsage(ability, [army.id])}>
					{Icon}
				</div>;
			} else {
				return <div className="army-ability active" title={title} onClick={() => this.props.onUsage(ability, [army.id])}>
					{Icon}
				</div>;
			}
		}

		if (cost > 0 && !army.supplied) {
			return <div className="army-ability inactive" title={`${title}: Army is not supplied`}>
				{Icon}
			</div>;
		}

		let fuelCost = 0;
		let supplyCost = 0;
		let logistics = 0;
		let unitFlagActive = false;

		army.gameUnit.forEach(u => {
			let unit = units[u.unit_id];

			if (unit.unitClass.id === 11) {
				logistics = u.experience;
			}

			unitFlagActive = unitFlagActive === false ? u.gameFlag.hasOwnProperty(ability) : unitFlagActive;
			unitFlagActive = unitFlagActive === false ? u.gameFlag.hasOwnProperty('unit-plan-offensive') : unitFlagActive;
		});

		if (unitFlagActive) {
			return <div className="army-ability inactive" title={`${title}: One or more units in this army is already using this ability`}>
				{Icon}
			</div>;
		}

		army.gameUnit.forEach(u => {
			let unit = units[u.unit_id];
			let modifier = logistics > 0 ? (1 - (0.2 * (1 + logistics / 10000))) : 1;

			if (user.military_policy_id === 14 && unit.unitClass.id === 1) {
				modifier -= 0.2;
			}

			supplyCost += unit.supply_consumption * modifier;
			fuelCost += unit.fuel_consumption * modifier;
		});

		if (cost) {
			supplyCost *= cost;

			if (supplyCost > 0 && supplyCost > user.supplies) {
				const supplyDeficit = (supplyCost - user.supplies).toFixed(2);

				return <div className="army-ability inactive" title={`${title}: We require ${supplyDeficit} more supplies`}>
					{Icon}
				</div>;
			}

			fuelCost *= cost;

			if (fuelCost > 0 && fuelCost > user.fuel) {
				const fuelDeficit = (fuelCost - user.fuel).toFixed(2);

				return <div className="army-ability inactive" title={`${title}: We require ${fuelDeficit} more fuel`}>
					{Icon}
				</div>;
			}
		}

		if (canUseAbility) {
			return <div className="army-ability" title={title} onClick={() => this.props.onUsage(ability, [army.id])}>
				{Icon}
			</div>;
		}

		return <div className="army-ability inactive" title={title}>
			{Icon}
		</div>;
	};

	renderFlagTitle(flags, gameDate) {
		const flag = flags['unit-blitz'] || flags['unit-shock-and-awe'] || flags['unit-plan-offensive'] || flags['unit-unrelenting-assault'];
		const endDate = new Date(flag.flag_date.date);
		const current = new Date(gameDate.date);
		const timeLeft = (endDate.getTime() - current.getTime()) / 1000;
		const daysLeft = (timeLeft / 86400).toFixed(1);

		return `Unit is on an offensive for ${daysLeft} days`;
	};

	handleExpedition = (factionId, army) => {
		const area = this.props.areas.find(a => a.id === army.game_area_id);

		this.props.onExpedition(factionId, [army.id]);
		this.props.history.replace(`/game/view/area/${area.area_id}/armies/${factionId}`);
	};

	render() {
		const { areas, armies, combats, game, units, user, onAction, onMerge, onRename, onSplit, onUsage } = this.props;
		const { action, id } = this.props.match.params;
		const army = armies.find(a => a.id === parseInt(id));

		if (! army) {
			return null;
		}

		const area = areas.find(a => a.id === army.game_area_id);

		const borderIds = area.area.border.reduce((ids, border) => {
			ids.push(border.area_2_id); 

			return ids;
		}, []);

		borderIds.push(area.area.id);

		const borders = areas.filter(a => borderIds.indexOf(a.area_id) !== -1 && a.faction_id === user.faction_id);
		const areaArmies = armies.filter(a => a.game_area_id === army.game_area_id && a.id !== army.id && a.initial_faction_id === army.initial_faction_id);
		const transportCapacity = army.gameUnit.filter(u => units[u.unit_id].unit_class_id === 10).length * 3;
		const hq = armies.findIndex(a => borders.findIndex(b => b.id === a.game_area_id) !== -1 && a.gameUnit.findIndex(u => units[u.unit_id].unit_class_id === 8) !== -1) !== -1;
		let transportableUnits = 0;
		let combatUnits = 0;
		let commandLimit = 0;
		let totalStrength = 0;
		let averageOrganisation = 0;
		let activeBuff = false;

		army.gameUnit.forEach(u => {
			if (units[u.unit_id].unit_class_id !== 5 && units[u.unit_id].unit_class_id !== 6 && units[u.unit_id].unit_class_id !== 10) {
				transportableUnits ++;
			}

			if (units[u.unit_id].unit_class_id !== 10 && units[u.unit_id].unit_class_id !== 11) {
				totalStrength += u.strength;
				averageOrganisation += u.organisation;

				combatUnits ++;
				
				if (units[u.unit_id].unit_class_id === 1 && user.military_policy_id === 14) {
					commandLimit += 0.8;
				} else {
					commandLimit ++;
				}
			}

			if (u.gameFlag.hasOwnProperty('unit-blitz') || u.gameFlag.hasOwnProperty('unit-shock-and-awe') || u.gameFlag.hasOwnProperty('unit-plan-offensive') ||
				u.gameFlag.hasOwnProperty('unit-unrelenting-assault')) {
				activeBuff = true;
			}
		});

		const overCommandLimit = hq ? commandLimit > 18 : commandLimit > 12;
		const isFortify = army.gameFlag.hasOwnProperty('army-entrenching') || army.gameFlag.hasOwnProperty('army-detrenching') || army.defensive_bonus !== 0;
		const canUseAbility = !army.armyAction.length && !army.battleArmy.length && !army.gameFlag.hasOwnProperty('army-plan-offensive');
		const allianceMember = Object.values(user.alliance).find(a => a !== user.faction_id);

		averageOrganisation /= combatUnits;

		if (! Number.isInteger(commandLimit)) {
			commandLimit = commandLimit.toFixed(1);
		}

		return (
			<React.Fragment>
				<div className="status-army">
					<div className="status-army-area">
						<ContentEditable element={army} length="64" onChange={onRename} />
					</div>
				</div>
				<div className="status-province-large">
					<div className="status-province-heading">Mission</div>
					<div className="status-province-list">
						<div className="status-province-list-name">
							<div className="status-army-container">
								<MissionForm 
									areas={areas}
									army={army} 
									game={game} 
									units={units}
									user={user}
									onAction={onAction} />
								</div>
						</div>
					</div>
				</div>
				{!!army.battleArmy.length && <React.Fragment>
					{army.battleArmy.map(b => <Combat key={b.battle_id} combats={combats} id={b.battle_id} />)}
				</React.Fragment>}
				<div className="status-province-large">
					<div className="status-province-heading">Army</div>
					<div className="status-province-list">
						<div className="army-area-name">
							<Link className="link" to={`/game/view/area/${area.area.id}`}>{area.area.name}</Link>
						</div>
						<div className="status-icons">
							{(user.supplies > 0 && !!army.supplied) && <div className="icon" title="Army is supplied"><Supply fill="white" /></div>}
							{(user.supplies === 0 || !army.supplied) && <div className="icon" title="Army is not supplied"><Supply fill="red" /></div>}
							{user.fuel > 0 && <div className="icon" title="Army is fueled"><Fuel fill="white" /></div>}
							{user.fuel === 0 && <div className="icon" title="Army is not fueled"><Fuel fill="red" /></div>}
							{!!hq && <div className="icon" title="Army has an HQ bonus"><HQ fill="white" /></div>}
							{area.gameFlag && area.gameFlag['area-supply-centre-full'] && <div className="icon" title="Army has a supply depot bonus">
								<SupplyFull fill="white" />
							</div>}
							{area.gameFlag && ! area.gameFlag['area-supply-centre-full'] && area.gameFlag['area-supply-centre-half'] && 
								<div className="icon" title="Army has a supply depot bonus"><SupplyHalf fill="white" /></div>}
							{activeBuff && <div className="icon" title="Army is currently on an offensive"><Buff fill="white" /></div>}
						</div>
						<div className="status-province-list-name">
							<br />
							{!overCommandLimit && <React.Fragment>{commandLimit} divisions&nbsp;</React.Fragment>}
							{!!overCommandLimit && <React.Fragment><span className="red">{commandLimit} divisions</span>&nbsp;</React.Fragment>}
							({Math.round(totalStrength / 100)} / {Math.floor(averageOrganisation / 10000)})<br />
							Movement Speed: {army.speed} km/h <br />
							Transport Capacity: {transportableUnits} / {transportCapacity}
						</div>
					</div>
				</div>
				<div className="status-province-large">
					<div className="status-province-heading">Divisions</div>
					<div className="status-province-list">
					{army.gameUnit.map(u => (
						<div className="unit-container" key={u.id}>
							<div className="unit-name">
								<Link className="link" to={`/game/view/army/${army.id}/unit/${u.unit_id}`}>{units[u.unit_id].name}</Link>
							</div>
							<div className="unit-experience" title={Math.floor(u.experience / 100).toFixed(0)}>
							{[...Array(Math.floor(u.experience / 2000)).keys()].map(e => (
								<Star key={e} />
							))}
							</div>
							<div className="unit-values">
								{Math.round(u.strength / 10000)} / {Math.round(u.organisation / 10000)}
								{(u.gameFlag.hasOwnProperty('unit-blitz') || u.gameFlag.hasOwnProperty('unit-shock-and-awe') || 
									u.gameFlag.hasOwnProperty('unit-plan-offensive') || u.gameFlag.hasOwnProperty('unit-unrelenting-assault')) && 
									<div className="icon" title={this.renderFlagTitle(u.gameFlag, game.game_date)}><Buff /></div>}
							</div>
						</div>
					))}
					</div>
				</div>
				<div className="status-province-large">
					<div className="status-province-heading">Merge Armies</div>
					<div className="status-province-list army-merge">
					{(!!army.armyAction.length || isFortify === true) && <React.Fragment>You cannot merge any armies into this army</React.Fragment>}
					{!army.armyAction.length && isFortify === false && !areaArmies.length && <React.Fragment>There are no other armies in this area to merge with</React.Fragment>}
					{!army.armyAction.length && isFortify === false && !!areaArmies.length && <MergeForm army={army} areaArmies={areaArmies} onMerge={onMerge} units={units} />}
					</div>
				</div>
				{canUseAbility === true && isFortify === false && action === 'split' && <React.Fragment>
					<div className="status-province-large">
						<div className="status-province-heading">Split Army</div>
						<div className="status-province-list">
							<SplitForm army={army} onSplit={onSplit} units={units} />
						</div>
					</div>
				</React.Fragment>}
				<div className="army-abilities">
					{!!allianceMember && <React.Fragment>
						{army.initial_faction_id === user.faction_id && <div className="army-ability" title="Send Expeditionary Force">
							<Loan onClick={() => this.handleExpedition(allianceMember, army)}/>
						</div>}
						{army.initial_faction_id !== user.faction_id && <div className="army-ability" title="Return Expeditionary Force">
							<Return onClick={() => this.handleExpedition(allianceMember, army)}/>
						</div>}
					</React.Fragment>}
					{(canUseAbility === false || isFortify === true) && 
						<div className="army-ability inactive" title="You cannot split any units from this army">
							<Split />
						</div>
					}
					{canUseAbility === true && isFortify === false && action !== 'split' && 
						<div className="army-ability" title="Split Army">
							<Link className="link" to={`/game/view/army/${army.id}/split`}>
								<Split />
							</Link>
						</div>
					}
					{canUseAbility === true && isFortify === false && action === 'split' && 
						<div className="army-ability active" title="Split Army">
							<Split />
						</div>
					}
					{this.renderAbility(army, 'army-reserve', 'Reserve', 0, <Reserve />)}
					{this.renderAbility(army, 'unit-blitz', 'Blitz', 336, <Tactics />)}
					{this.renderAbility(army, 'unit-shock-and-awe', 'Shock and Awe', 336, <Ammunition />)}
					{this.renderAbility(army, 'army-plan-offensive', 'Plan Offensive', 0, <Plan />)}
					{this.renderAbility(army, 'unit-unrelenting-assault', 'Unrelenting Assault', 168, <Manpower />)}
					{canUseAbility && (army.defensive_bonus !== 24 || army.gameFlag.hasOwnProperty('army-detrenching')) && 
						!army.gameFlag.hasOwnProperty('army-entrenching') && 
						<div className="army-ability" title="Fortify Position">
							<Entrench onClick={() => onUsage('army-entrench', [army.id])}/>
						</div>
					}
					{canUseAbility && !army.gameFlag.hasOwnProperty('army-detrenching') && (army.gameFlag.hasOwnProperty('army-entrenching') || 
						army.defensive_bonus > 0) && 
						<div className="army-ability active" title="Dismantle Fortifications">
							<Entrench onClick={() => onUsage('army-detrench', [army.id])}/>
						</div>
					}
					{!canUseAbility && 
						<div className="army-ability inactive" title="Fortify Position">
							<Entrench />
						</div>
					}
				</div>
			</React.Fragment>
		);
	}
}

export default Army;