import React, { Component } from 'react';

class Unit extends Component {
	state = {
		open: true
	};

	toggle = () => {
		this.setState({ open: ! this.state.open });
	};

	groupUnits(armies) {
		const { factions, units } = this.props;
		const gameUnits = {};

		Object.keys(units).forEach(u => {
			gameUnits[u] = {};

			factions.forEach(f => {
				gameUnits[u][f.id] = 0;
			});
		});

		armies.forEach(a => {
			a.gameUnit.forEach(u => {
				gameUnits[u.unit_id][a.faction_id] ++;
			});
		});

		Object.keys(gameUnits).forEach(u => {
			let remove = true;

			factions.forEach(f => {
				if (gameUnits[u][f.id]) {
					remove = false;
				}
			});

			if (remove) {
				delete gameUnits[u];
			}
		});

		return gameUnits;
	};

	render() {
		const { armies, factions, units } = this.props;
		const gameUnits = this.groupUnits(armies);
		const open = this.state.open ? ' open' : '';
		const groupedUnits = [];
		const totals = {};

		Object.keys(units).forEach(u => {
			groupedUnits.push({id: u, name: units[u].name});
		});

		factions.forEach(f => {
			totals[f.id] = 0;

			Object.keys(gameUnits).forEach(u => {
				totals[f.id] += gameUnits[u][f.id];
			});
		});

		const factionGroups = Object.values(factions).reduce((groups, faction, index) => {
			const group = Math.floor(index / 2); 

			groups[group] = [].concat((groups[group] || []), faction); 

			return groups;
		}, []);

		return (
			<div className="status-province-large">
				<div className={`status-province-heading toggle${open}`} onClick={this.toggle}>Units</div>
				<div className="replay-container">
				{factionGroups.map((g, k) => (
					<div className="replay-group" key={k}>
						<div className="replay-row header">
							<div className="replay-name"></div>
							{g.map(f => (
							<div className="replay-faction" key={f.id}>
								<div className={`owner user-colour faction-${f.id}`} title={f.abbreviation}></div>
							</div>
							))}
						</div>
						{groupedUnits.sort((a, b) => a.name.localeCompare(b.name)).map(u => (
							<React.Fragment key={u.id}>
							{gameUnits[u.id] &&	<div className="replay-row">
								<div>
									<div className="replay-name">{units[u.id].name}</div>
									{g.map(f => (
									<div className="replay-value" key={f.id}>{gameUnits[u.id][f.id]}</div>
									))}
								</div>
							</div>}
							</React.Fragment>
						))}
						<div className="replay-row total">
							<div>
								<div className="replay-name">&nbsp;</div>
								{g.map(f => (
								<div className="replay-value" key={f.id}>{totals[f.id]}</div>
								))}
							</div>
						</div>
					</div>
				))}
				</div>
			</div>
		);
	}
}

export default Unit;
