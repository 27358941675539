import http from './httpService';

function allUnits() {
	return http.get('/units');
}

function saveUnit(unit) {
	if (unit.id) {
		const data = { ...unit };

		delete data.id;

		return http.put('/units/' + unit.id, data);
	}

	return http.post('/units/', unit);
}

function getUnit(id) {
	return http.get('/units/' + id);
}

export default {
	all: allUnits,
	save: saveUnit,
	get: getUnit
};
