import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as Fuel } from '../../images/fuel.svg';
import { ReactComponent as Manpower } from '../../images/manpower.svg';
import { ReactComponent as Money } from '../../images/money.svg';
import { ReactComponent as Supply } from '../../images/supplies.svg';

class Region extends Component {
	render() {
		const { areas, factions } = this.props;
		const { id } = this.props.match.params;
		const regionAreas = areas.filter(a => a.area.region.id === parseInt(id));
		let wealth = 0;
		let supplies = 0;
		let oil = 0;
		let manpower = 0;

		regionAreas.forEach(a => {
			wealth += parseFloat(a.wealth);
			supplies += parseFloat(a.supplies);
			oil += parseFloat(a.oil);
			manpower += parseFloat(a.manpower);
		});

		return (
			<React.Fragment>
				<div className="status-province">
					<div className="status-province-area">{regionAreas[0].area.region.name}</div>
				</div>
				<div className="status-province-large">
					<div className="status-province-heading">Resources</div>
					<div className="status-province-list">
						<div className="area-summary">
							<div>
								<Money />
								<span>{wealth.toFixed(1)}</span>
							</div>
							<div>
								<Supply />
								<span>{supplies.toFixed(1)}</span>
							</div>
							<div>
								<Fuel />
								<span>{oil.toFixed(1)}</span>
							</div>
							<div>
								<Manpower /> 
								<span>{manpower.toFixed(1)}</span>
							</div>
						</div>
					</div>
				</div>
				<div className="status-province-large">
					<div className="status-province-heading">Areas</div>
					<div className="status-province-list">
					{[...regionAreas].sort((a, b) => a.area.name.localeCompare(b.area.name)).map(a => (
						<React.Fragment key={a.area.id}>
							<div className="status-province-list-name">
								<Link className="link" to={`/game/view/area/${a.area.id}`}>{a.area.name}</Link>
							</div>
							<div className="status-province-list-count">
								<div className={`owner user-colour faction-${a.faction_id}`} title={factions[a.faction_id].abbreviation}></div>
							</div>
						</React.Fragment>
					))}
					</div>
				</div>
			</React.Fragment>
		)
	}
}

export default Region;
