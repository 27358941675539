import React, { Component } from 'react';

class CombatView extends Component {
	displayProgress(battle) {
		if (battle.current_progress >= 50) {
			return `The attacker has made ${battle.current_progress}% progress in defeating the defender`;
		}

		const progress = 100 - battle.current_progress;

		return `The defender has made ${progress}% progress in repelling the attacker`;
	};

	getPercentage(value) {
		return Math.round(value / 1000000 * 100);
	};

	render() {
		const { combats } = this.props;
		const { id } = this.props.match.params;
		const combat = combats.find(c => c.id === parseInt(id));

		if (! combat) {
			return null;
		}

		return (
			<React.Fragment>
				<div className="status-province-large">
					<div className="status-province-heading">
						{combat.battle_name}
					</div>
					<div className="status-province-list">
						<div className="status-province-list-name">
							<div className='battle-progress'>
								<div className="progress-container" title={this.displayProgress(combat)}>
									<div className="progress-bar" style={{width: `${combat.current_progress}%`}}></div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="status-province-large attacker-container">
					<div className="status-province-heading">
						Attacker
					</div>
					<div className="status-province-list">
					{combat.battle.attacker.map((a, key) => (
						<div key={key} className="battle-unit">
							<div className="status-battle-name">
								<div>{a.name}</div>
								<div className="progress-container strength-container first" title={this.getPercentage(a.strength)}>
									<div className="progress-bar" style={{width: this.getPercentage(a.strength) + '%'}}></div>
								</div>
								<div className="progress-container strength-container" title={this.getPercentage(a.organisation)}>
									<div className="progress-bar" style={{width: this.getPercentage(a.organisation) + '%'}}></div>
								</div>                        
							</div>
						</div>
					))}
					</div>
				</div>
				<div className="status-province-large defender-container">
					<div className="status-province-heading">
						Defender
					</div>
					<div className="status-province-list">
					{combat.battle.defender.map((a, key) => (
						<div key={key} className="battle-unit">
							<div className="status-battle-name">
								<div>{a.name}</div>
								<div className="progress-container strength-container first" title={this.getPercentage(a.strength)}>
									<div className="progress-bar" style={{width: this.getPercentage(a.strength) + '%'}}></div>
								</div>
								<div className="progress-container strength-container" title={this.getPercentage(a.organisation)}>
									<div className="progress-bar" style={{width: this.getPercentage(a.organisation) + '%'}}></div>
								</div>                        
							</div>
						</div>
					))}
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default CombatView;
