import React, { Component } from 'react';

class Structure extends Component {
	state = {
		open: true
	};

	toggle = () => {
		this.setState({ open: ! this.state.open });
	};

	groupStructures(areas) {
		const { structures, factions } = this.props;
		const gameStructures = {};

		Object.keys(structures).forEach(s => {
			gameStructures[s] = {};

			factions.forEach(f => {
				gameStructures[s][f.id] = 0;
			});
		});

		areas.forEach(a => {
			Object.keys(a.gameStructure).forEach(s => {
				gameStructures[s][a.faction_id] += a.gameStructure[s];
			});
		});

		Object.keys(gameStructures).forEach(s => {
			let remove = true;

			factions.forEach(f => {
				if (gameStructures[s][f.id]) {
					remove = false;
				}
			});

			if (remove) {
				delete gameStructures[s];
			}
		});

		return gameStructures;
	};

	render() {
		const { areas, factions, structures } = this.props;
		const gameStructures = this.groupStructures(areas);
		const open = this.state.open ? ' open' : '';
		const groupedStructures = [];
		const totals = {};

		Object.keys(structures).forEach(s => {
			groupedStructures.push({id: s, name: structures[s]});
		});	

		factions.forEach(f => {
			totals[f.id] = 0;

			Object.keys(gameStructures).forEach(s => {
				totals[f.id] += gameStructures[s][f.id];
			});
		});

		const factionGroups = Object.values(factions).reduce((groups, faction, index) => {
			const group = Math.floor(index / 2); 

			groups[group] = [].concat((groups[group] || []), faction); 

			return groups;
		}, []);

		return (
			<div className="status-province-large">
				<div className={`status-province-heading toggle${open}`} onClick={this.toggle}>Installations</div>
				<div className="replay-container">
				{factionGroups.map((g, k) => (
					<div className="replay-group" key={k}>
						<div className="replay-row header">
							<div className="replay-name"></div>
							{g.map(f => (
							<div className="replay-faction" key={f.id}>
								<div className={`owner user-colour faction-${f.id}`} title={f.abbreviation}></div>
							</div>
							))}
						</div>
						{groupedStructures.sort((a, b) => a.name.localeCompare(b.name)).map(s => (
							<React.Fragment key={s.id}>
							{gameStructures[s.id] && <div className="replay-row">
								<div>
									<div className="replay-name">{structures[s.id]}</div>
									{g.map(f => (
									<div className="replay-value" key={f.id}>{gameStructures[s.id][f.id]}</div>
									))}
								</div>
							</div>}
							</React.Fragment>
						))}
						<div className="replay-row total">
							<div>
								<div className="replay-name">&nbsp;</div>
								{g.map(f => (
								<div className="replay-value" key={f.id}>{totals[f.id]}</div>
								))}
							</div>
						</div>
					</div>
				))}
				</div>
			</div>
		);
	}
}

export default Structure;
