import React from 'react';
import { ReactComponent as AntiAir } from '../../images/aa.svg';
import { ReactComponent as Armor } from '../../images/armor.svg';
import { ReactComponent as Artillery } from '../../images/artillery.svg';
import { ReactComponent as Bomber } from '../../images/bomber.svg';
import { ReactComponent as Fighter } from '../../images/fighter.svg';
import { ReactComponent as HQ } from '../../images/headquarters.svg';
import { ReactComponent as Infantry } from '../../images/infantry.svg';
import { ReactComponent as Maintenance } from '../../images/maintenance.svg';
import { ReactComponent as Medical } from '../../images/medical.svg';
import { ReactComponent as Transport } from '../../images/transport.svg';

const Unit = ({ unit, draggable, onDragStart, onDragEnd }) => {
	return (
		<div draggable={draggable} className="combat-unit" onDragStart={event => onDragStart(event, unit)} onDragEnd={event => onDragEnd(event)}>
			<div className="combat-unit-name">{unit.unit_name}</div>
			<div className="combat-unit-nato">
				{unit.unit_class_id === 1 && <Infantry />}
				{(unit.unit_class_id === 2 || unit.unit_class_id === 3 || unit.unit_class_id === 4) && <Armor />}
				{unit.unit_class_id === 5 && <Fighter />}
				{unit.unit_class_id === 6 && <Bomber />}
				{unit.unit_class_id === 7 && <Artillery />}
				{unit.unit_class_id === 8 && <HQ />}
				{unit.unit_class_id === 9 && <AntiAir />}
				{unit.unit_class_id === 10 && <Transport />}
				{unit.unit_class_id === 11 && <Maintenance />}
				{unit.unit_class_id === 12 && <Medical />}
			</div>
			<div className="combat-unit-strength">
				<meter low="33" high="66" optimum="100" min="0" max="100" value={unit.strength} title={`Strength: ${unit.strength}`} />
			</div>
			<div className="combat-unit-organisation">
				<meter low="33" high="66" optimum="100" min="0" max="100" value={unit.organisation} title={`Organisation: ${unit.organisation}`} />
			</div>
		</div>
	);
}

Unit.defaultProps = {
	draggable: false
};

export default Unit;
