import React, { Component } from 'react';

class Overview extends Component {
	state = {
		open: true
	};

	toggle = () => {
		this.setState({ open: ! this.state.open });
	};

	render() {
		const { areas, armies, factions, users } = this.props;
		const open = this.state.open ? ' open' : '';
		const stats = {areas: {}, armies: {}, units: {}, structures: {}, trained: {}, built: {}, tech: {}};

		factions.forEach(f => {
			Object.keys(stats).forEach(s => {
				stats[s][f.id] = 0;
			});
		});

		areas.forEach(a => {
			stats.areas[a.faction_id] ++;

			Object.keys(a.gameStructure).forEach(s => {
				stats.structures[a.faction_id] += a.gameStructure[s];
			});
		});

		armies.forEach(a => {
			if (a.gameUnit.length) {
				stats.armies[a.faction_id] ++;
				stats.units[a.faction_id] += a.gameUnit.length;
			}
		});

		Object.values(users).forEach(u => {
			stats.built[u.faction_id] = u.built;
			stats.trained[u.faction_id] = u.trained;

			Object.keys(u.tech).forEach(t => {
				stats.tech[u.faction_id] += u.tech[t].level;
			});
		});

		const factionGroups = Object.values(factions).reduce((groups, faction, index) => {
			const group = Math.floor(index / 2); 

			groups[group] = [].concat((groups[group] || []), faction); 

			return groups;
		}, []);

		return (
			<div className="status-province-large">
				<div className={`status-province-heading toggle${open}`} onClick={this.toggle}>Overview</div>
				<div className="replay-container">
				{factionGroups.map((g, k) => (
					<div className="replay-group" key={k}>
						<div className="replay-row header">
							<div className="replay-name"></div>
							{g.map(f => (
							<div className="replay-faction" key={f.id}>
								<div className={`owner user-colour faction-${f.id}`} title={f.abbreviation}></div>
							</div>
							))}
						</div>
						<div className="replay-row">
							<div>
								<div className="replay-name">Areas</div>
								{g.map(f => (
								<div className="replay-value" key={f.id}>{stats.areas[f.id]}</div>
								))}
							</div>
						</div>
						<div className="replay-row">
							<div>
								<div className="replay-name">Installations</div>
								{g.map(f => (
								<div className="replay-value" key={f.id}>{stats.structures[f.id]}</div>
								))}
							</div>
						</div>
						<div className="replay-row">
							<div>
								<div className="replay-name">Constructed</div>
								{g.map(f => (
								<div className="replay-value" key={f.id}>
									{stats.built[f.id]}
									{users[f.id].building > 0 && ` (${users[f.id].building})`}
								</div>
								))}
							</div>
						</div>
						<div className="replay-row">
							<div>
								<div className="replay-name">Armies</div>
								{g.map(f => (
								<div className="replay-value" key={f.id}>{stats.armies[f.id]}</div>
								))}
							</div>
						</div>
						<div className="replay-row">
							<div>
								<div className="replay-name">Units</div>
								{g.map(f => (
								<div className="replay-value" key={f.id}>{stats.units[f.id]}</div>
								))}
							</div>
						</div>
						<div className="replay-row">
							<div>
								<div className="replay-name">Trained</div>
								{g.map(f => (
								<div className="replay-value" key={f.id}>
									{stats.trained[f.id]}
									{users[f.id].training > 0 && ` (${users[f.id].training})`}
								</div>
								))}
							</div>
						</div>
						<div className="replay-row">
							<div>
								<div className="replay-name">Technology</div>
								{g.map(f => (
								<div className="replay-value" key={f.id}>{stats.tech[f.id]}</div>
								))}
							</div>
						</div>
					</div>
					))}
				</div>
			</div>
		);
	}
}

export default Overview;
