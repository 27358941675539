import React from 'react';

const Select = ({ name, label, options, error, defaultOption, ...others }) => {
	return (
		<div className="form-group">
			<label htmlFor={name}>{label}</label>
			<select name={name} {...others} className="form-control" id={name}>
			{defaultOption && <option key="" value="">{defaultOption}</option>}
			{options.map(o => (
				<option key={o.id} value={o.id}>{o.name}</option>
			))}
			</select>
			{error && <div className="alert alert-danger">{error}</div>}
		</div>
	);
}

export default Select;
