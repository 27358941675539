import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import GameService from '../../services/gameService';
import { formatDate } from '../../utilities/date';

class History extends Component {
	state = {
		games: []
	};

	async componentDidMount() {
		const { data: games } = await GameService.all();

		this.setState({ games });
	};

	render() {
		const games = this.state.games;

		if (! games.length) {
			return <p>No games played</p>;
		}

		return (
			<React.Fragment>
				<div className="content_header"><h1>Game History</h1></div>
				<div className="content_content">
					<table className="striped">
						<thead>
							<tr>
								<th width="10%">Allies</th>
								<th width="20%">Opponents</th>
								<th width="12%">Theatre</th>
								<th width="15%">Start Date</th>
								<th width="15%">End Date</th>
								<th width="10%">Result</th>
								<th width="10%">Condition</th>
								<th width="8%">&nbsp;</th>
							</tr>
						</thead>
						<tbody>
						{games.map(g => (
							<tr key={g.id}>
								<td>
								{g.allies.map(o => (
									<div key={o.id}>{o.user} ({o.faction})</div>
								))}
								</td>
								<td>
								{g.opponents.map(o => (
									<div key={o.id}>{o.user} ({o.faction})</div>
								))}
								</td>
								<td>{g.theatre}</td>
								<td>{formatDate(g.start_time)}</td>
								<td>{g.end_time && formatDate(g.end_time)}</td>
								<td>
									{g.result === 'won' && 'Victory'}
									{g.result === 'active' && 'Ongoing'}
									{g.result !== 'won' && g.result !== 'active' && 'Defeat'}
								</td>
								<td>{g.condition}</td>
								<td>{g.end_time && <Link to={`/game/replay/${g.id}`} className="link">View Replay</Link>}</td>
							</tr>
						))}
						</tbody>
					</table>
				</div>
			</React.Fragment>
		);
	};
};

export default History;
