import React, { Component } from 'react';
import { ReactComponent as Gear } from '../../images/gear.svg';
import { ReactComponent as Pause } from '../../images/pause.svg';
import { ReactComponent as Play } from '../../images/play.svg';
import { formatDate } from '../../utilities/date.js';

class Control extends Component {
	state = {
		settings: false
	};

	constructor(props) {
		super(props);

		this.range = React.createRef();
		//this.counter = React.createRef();
	};

	componentDidMount() {
		this.range.current.value = 0;
		//this.counter.current.value = 1;
	};

	componentDidUpdate(props) {
		this.range.current.value = this.props.hour;
		//this.counter.current.value = this.props.counterScale;
	};

	handlePlay() {
		this.props.onPlay();
	};

	handlePause() {
		this.props.onPause();
	};

	handleCombatPause({ target }) {
		this.props.onCombatPause(target.checked);
	};

	handleCounterScaleChange({ target }) {
		this.props.onCounterScaleChange(parseFloat(target.value));
	};

	handleDateChange({ target }) {
		this.props.onDateChange(parseInt(target.value));
	};

	handleHistoryVisibilityChange(id) {
		this.props.onHistoryVisibilityChange(id);
	};

	handleMovementIndicatorChange({ target }) {
		this.props.onMovementIndicatorChange(target.checked);
	};

	handlePointOfViewChange(id) {
		this.props.onPointOfViewChange(id);
	};

	handleSpeedChange({ target }) {
		this.props.onSpeedChange(parseFloat(target.value));
	};

	toggleSettings() {
		this.setState({ settings: ! this.state.settings });
	}

	render() {
		const { combatPause, factions, largeMovementIndicator, isActive, game, speed } = this.props;

		return (
			<React.Fragment>
				<div id="control-container">
					<div className="game-controls">
						{!isActive && <Play onClick={() => this.handlePlay()} />}
						{isActive && <Pause onClick={() => this.handlePause()} />}
						<Gear onClick={() => this.toggleSettings()} />
					</div>
					<div className="game-start">00:00 January 1, 2020</div>
					<div className="game-progress">
						<input className="progress" type="range" min="0" ref={this.range} max={game.length} step="1" onMouseDown={() => this.handlePause()} onMouseUp={event => this.handleDateChange(event)} />
					</div>
					<div className="game-end">{formatDate(this.props.game.game_date)}</div>
				</div>
				<div id="replay-settings" className={this.state.settings ? 'open' : ''}>
					<div className="setting-option">
						<label htmlFor="replay-speed">Replay Speed:</label>
						<select name="replay-speed" id="replay-speed" onChange={event => this.handleSpeedChange(event)} value={speed}>
							<option value="0.5">0.5</option>
							<option value="1">1</option>
							<option value="1.5">1.5</option>
							<option value="2">2</option>
						</select>
					</div>
					<div className="setting-option">
						<label>Point of View:</label>
						<span onClick={() => this.handlePointOfViewChange()}>All</span>
						{factions.map(f => <div key={f.id} className={`owner user-colour faction-${f.id}`} onClick={() => this.handlePointOfViewChange(f.id)}></div>)}
					</div>
					<div className="setting-option">
						<label>Show History:</label>
						{factions.map(f => <div key={f.id} className={`owner user-colour faction-${f.id}`} onClick={() => this.handleHistoryVisibilityChange(f.id)}></div>)}
					</div>
					<div className="setting-option">
						<input type="checkbox" name="replay-combat-pause" id="replay-combat-pause" defaultChecked={combatPause} onChange={event => this.handleCombatPause(event)} />
						<label htmlFor="replay-combat-pause">Pause when combat commences</label>
					</div>
					<div className="setting-option">
						<input type="checkbox" name="replay-large-movement" id="replay-large-movement" defaultChecked={largeMovementIndicator} onChange={event => this.handleMovementIndicatorChange(event)} />
						<label htmlFor="replay-large-movement">Show large movement indicators</label>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default Control;
