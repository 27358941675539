import axios from 'axios';
//import logger from './logService.js';

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

axios.interceptors.response.use(null, error => {
    const { response } = error;

    if (! (response && response.status >= 400 && response.status < 500)) {
        //logger.log(error);
    }

    return Promise.reject(error);
});

function setToken(token) {
    if (token) {
        axios.defaults.headers.common['X-Auth-Token'] = token;
    }
}

export default {
    get: axios.get,
    post: axios.post,
    put: axios.put,
    patch: axios.patch,
    delete: axios.delete,
    setToken: setToken
}
