import React, { Component } from 'react';
import { ReactComponent as Satellite } from '../../images/satellite.svg';
import { ReactComponent as Reallocation } from '../../images/reallocation.svg';
import { ReactComponent as InfantryArmor } from '../../images/infantrytecharmor.svg';
import { ReactComponent as InfantryWeapon } from '../../images/infantrytechweapon.svg';
import { ReactComponent as VehicleArmor } from '../../images/vehicletecharmor.svg';
import { ReactComponent as VehicleWeapon } from '../../images/vehicletechweapon.svg';
import { ReactComponent as AircraftArmor } from '../../images/airtecharmor.svg';
import { ReactComponent as AircraftWeapon } from '../../images/airtechweapon.svg';
import { ReactComponent as ParticleCannon } from '../../images/particlecannon.svg';
import { ReactComponent as NuclearMissile } from '../../images/nuke.svg';
import { ReactComponent as Earthquake } from '../../images/earthquake.svg';
import { ReactComponent as Money } from '../../images/money.svg';
import { ReactComponent as Fuel } from '../../images/fuel.svg';
import { ReactComponent as Supply } from '../../images/supplies.svg';

class TechView extends Component {
	tech = {
		infantry_armor: {
			name: 'Infantry Armor',
			field: 'infantry_armor_tech',
			flag: 'infantry-armor',
			researchFlag: 'infantry-armor-research'
		},
		infantry_weapons: {
			name: 'Infantry Weapons',
			field: 'infantry_weapons_tech',
			flag: 'infantry-weapons',
			researchFlag: 'infantry-weapons-research'
		},
		vehicle_armor: {
			name: 'Vehicle Armor',
			field: 'vehicle_armor_tech',
			flag: 'vehicle-armor',
			researchFlag: 'vehicle-armor-research'
		},
		vehicle_weapons: {
			name: 'Vehicle Weapons',
			field: 'vehicle_weapons_tech',
			flag: 'vehicle-weapons',
			researchFlag: 'vehicle-weapons-research'
		},
		aircraft_armor: {
			name: 'Aircraft Armor',
			field: 'aircraft_armor_tech',
			flag: 'aircraft-armor',
			researchFlag: 'aircraft-armor-research'
		},
		aircraft_weapons: {
			name: 'Aircraft Weapons',
			field: 'aircraft_weapons_tech',
			flag: 'aircraft-weapons',
			researchFlag: 'aircraft-weapons-research'
		}
	};

	superweapons = {
		particle_cannon: {
			name: 'Particle Cannon',
			cooldownFlag: 'superweapon-ion-cooldown',
			field: 'superweapon_tech',
			firingFlag: 'superweapon-ion-firing',
			flag: 'superweapon-ion',
			researchFlag: 'superweapon-ion-research',
			researchPrerequisite: 'superweapon-ion-structure',
			superweaponId: 1
		},
		nuclear_missile: {
			name: 'Nuclear Missile',
			cooldownFlag: 'superweapon-nuke-cooldown',
			field: 'superweapon_tech',
			firingFlag: 'superweapon-nuke-firing',
			flag: 'superweapon-nuke',
			researchFlag: 'superweapon-nuke-research',
			researchPrerequisite: 'superweapon-nuke-structure',
			superweaponId: 2
		},
		tectonic_disruptor: {
			name: 'Tectonic Disruptor',
			cooldownFlag: 'superweapon-earthquake-cooldown',
			field: 'superweapon_tech',
			firingFlag: 'superweapon-earthquake-firing',
			flag: 'superweapon-earthquake',
			researchFlag: 'superweapon-earthquake-research',
			researchPrerequisite: 'superweapon-earthquake-structure',
			superweaponId: 3
		}
	};

	componentWillUnmount = () => {
		this.props.onActivate(null);
	};

	renderProgress(flag, date, prefix) {
		const current = new Date(date.date);
		const flagDate = new Date(flag.flag_date.date);
		const time = flag.flag.value * 3600;
		const timeLeft = (flagDate.getTime() - current.getTime()) / 1000;
		const days = (timeLeft / 86400).toFixed(1);

		let progressed = Math.floor((time - timeLeft) / time * 100);
		let suffix = days === 1 ? 'Day' : 'Days';

		progressed = progressed <= 0 ? 0 : progressed >= 100 ? 100 : progressed;

		return (
			<React.Fragment>
				{prefix} {days} {suffix} ({progressed}%)
			</React.Fragment>
		);
	};

	renderTechOption(user, tech) {
		const flags = user.gameFlag;
		const techLevel = flags[tech.flag] ? Object.keys(flags[tech.flag]).length : 0;
		const currentResearch = flags.hasOwnProperty(tech.researchFlag);
		const techCap = 10000 + techLevel * 1000;
		const percentage = Math.floor(user[tech.field] / techCap * 100);

		if ((user.technology_policy_id === 9 && techLevel >= 5) || (user.technology_policy_id === 10 && techLevel >= 3)) {
			return 'No further research available';
		}

		if (currentResearch) {
			return <button name="research" onClick={() => this.props.onUsage(tech.researchFlag)}>Pause</button>;
		}

		if (user.technology_policy_id === 9 && (flags.hasOwnProperty('infantry-armor-research') || flags.hasOwnProperty('infantry-weapons-research') || 
			flags.hasOwnProperty('vehicle-armor-research') || flags.hasOwnProperty('vehicle-weapons-research') || flags.hasOwnProperty('aircraft-armor-research') || 
			flags.hasOwnProperty('aircraft-weapons-research'))) {
			return `Research is currently unavailable`;
		}

		return <button name="research" onClick={() => this.props.onUsage(tech.researchFlag)}>Research Level {techLevel + 1} ({percentage}%)</button>;
	};

	renderTechCost(user, tech) {
		const flags = user.gameFlag;
		const techLevel = flags[tech.flag] ? Object.keys(flags[tech.flag]).length : 0;
		const currentResearch = flags.hasOwnProperty(tech.researchFlag);
		const techCap = 10000 + techLevel * 1000;
		const percentage = Math.floor(user[tech.field] / techCap * 100);

		if ((user.technology_policy_id === 9 && techLevel >= 5) || (user.technology_policy_id === 10 && techLevel >= 3)) {
			return null;
		}

		if (user.power <= 0 && currentResearch ) {
			return `On Hold: Low Power`;
		}

		if (currentResearch) {
			return `Currently Researching: ${percentage}%`;
		}

		let activeResearches = 0;

		Object.keys(this.tech).forEach(i => {
			if (flags.hasOwnProperty(this.tech[i].researchFlag)) {
				activeResearches ++;
			}
		});

		const cost = 10 * Math.pow(2, activeResearches);

		return (
			<React.Fragment>
				<div className="ability-cost"><Money /><span>{cost}</span></div>
				<div className="ability-cost"><Supply /><span>{cost}</span></div>
				<div className="ability-cost"><Fuel /><span>{cost}</span></div>
			</React.Fragment>
		);
	};

	renderSuperweaponOption(user, tech) {
		const flags = user.gameFlag;
		const techCap = 25000;
		const percentage = Math.floor(user[tech.field] / techCap * 100);

		if (flags.hasOwnProperty(tech.researchFlag)) {
			return <button name="research" onClick={() => this.props.onUsage(tech.researchFlag)}>Pause</button>;
		}

		return <button name="research" onClick={() => this.props.onUsage(tech.researchFlag)}>Research Superweapon ({percentage}%)</button>;
	};

	renderSuperweaponCost(user, tech) {
		const flags = user.gameFlag;
		const currentResearch = flags.hasOwnProperty(tech.researchFlag);
		const techCap = 25000;
		const percentage = Math.floor(user[tech.field] / techCap * 100);
		const cost = 100;

		if (currentResearch) {
			return `Currently Researching: ${percentage}%`;
		}

		return (
			<React.Fragment>
				<div className="ability-cost"><Money /><span>{cost}</span></div>
				<div className="ability-cost"><Supply /><span>{cost}</span></div>
				<div className="ability-cost"><Fuel /><span>{cost}</span></div>
			</React.Fragment>
		);
	};

	getTechLevel(user, tech) {
		const techLevel = user.gameFlag[tech] ? Object.keys(user.gameFlag[tech]).length : 0;

		if (techLevel >= 1) {
			return ` (Level ${techLevel})`;
		}

		return null;
	};

	getResearchModifier(user) {
		return 100 + (user.gameFlag['research-centre'] ? Object.keys(user.gameFlag['research-centre']).length * 5 : 0);
	};

	render() {
		const { game, user, onActivate, onUsage } = this.props;
		const hasPower = user.power > 0;
		const icons = {
			infantry_armor: <InfantryArmor />,
			infantry_weapons: <InfantryWeapon />,
			vehicle_armor: <VehicleArmor />,
			vehicle_weapons: <VehicleWeapon />,
			aircraft_armor: <AircraftArmor />,
			aircraft_weapons: <AircraftWeapon />,
			particle_cannon: <ParticleCannon />,
			nuclear_missile: <NuclearMissile />,
			tectonic_disruptor: <Earthquake />
		};

		let cost = 0;
		let activeResearches = 0;

		if (hasPower) {
			Object.keys(this.tech).forEach(i => {
				if (user.gameFlag.hasOwnProperty(this.tech[i].researchFlag)) {
					cost += 10 * Math.pow(2, activeResearches);
					activeResearches ++;
				}
			});

			Object.keys(this.superweapons).forEach(i => {
				if (user.gameFlag.hasOwnProperty(this.superweapons[i].researchFlag)) {
					cost += 100;
				}
			});
		}

		return (
			<React.Fragment>
				<div className="ability-heading">
					Technology
				</div>
				{user.technology_policy_id === 9 && <div className="ability-heading research">
					Research Modifier: {this.getResearchModifier(user)}%
				</div>}
				<div className="ability-heading research">
					<div className="research-cost">Research Cost:</div>
					<div className="research-cost-item"><Money /><span>{cost}</span></div>
					<div className="research-cost-item"><Supply /><span>{cost}</span></div>
					<div className="research-cost-item"><Fuel /><span>{cost}</span></div>
				</div>
				{Object.keys(this.tech).map(i => (
				<div className="ability-container" key={i}>
					<div className="ability-icon">
						{icons[i]}
					</div>
					<div className="ability-information">
						<div className="ability-name">{this.tech[i].name}{this.getTechLevel(user, this.tech[i].flag)}</div>
						<div className="ability-usage tech">
							{this.renderTechOption(user, this.tech[i])}
						</div>
						<div className="ability-usage tech">
							{this.renderTechCost(user, this.tech[i])}
						</div>
					</div>
				</div>
				))}
				{Object.keys(this.superweapons).map(i => <React.Fragment key={i}>
				{user.gameFlag.hasOwnProperty(this.superweapons[i].researchPrerequisite) && 
				!user.gameFlag.hasOwnProperty(this.superweapons[i].flag) && user.superweapon_id === this.superweapons[i].superweaponId &&
				<div className="ability-container">
					<div className="ability-icon">
						{icons[i]}
					</div>
					<div className="ability-information">
						<div className="ability-name">{this.superweapons[i].name}</div>
						<div className="ability-usage tech">
							{this.renderSuperweaponOption(user, this.superweapons[i])}
						</div>
						<div className="ability-usage tech">
							{this.renderSuperweaponCost(user, this.superweapons[i])}
						</div>
					</div>
				</div>}
				</React.Fragment>)}
				<div className="ability-heading">
					Abilities
				</div>
				{Object.keys(this.superweapons).map(i => <React.Fragment key={i}>
				{user.gameFlag.hasOwnProperty(this.superweapons[i].flag) && user.gameFlag.hasOwnProperty(this.superweapons[i].researchPrerequisite) &&
				<div className="ability-container">
					<div className="ability-icon">
						{icons[i]}
					</div>
					<div className="ability-information">
						<div className="ability-name">{this.superweapons[i].name}</div>
						<div className="ability-usage">
							{hasPower === true && !user.gameFlag.hasOwnProperty(this.superweapons[i].cooldownFlag) && 
								!user.gameFlag.hasOwnProperty(this.superweapons[i].firingFlag) &&
								<button name="use-superweapon" onClick={() => onActivate(this.superweapons[i].flag)}>Activate</button>
							}
							{hasPower === false && !user.gameFlag.hasOwnProperty(this.superweapons[i].cooldownFlag) && 
								!user.gameFlag.hasOwnProperty(this.superweapons[i].firingFlag) && `Unavailable: Low Power`
							}
							{user.gameFlag.hasOwnProperty(this.superweapons[i].firingFlag) && `Firing Weapon`}
							{user.gameFlag.hasOwnProperty(this.superweapons[i].cooldownFlag) && 
								this.renderProgress(user.gameFlag[this.superweapons[i].cooldownFlag], game.game_date, 'Recharging:')
							}
						</div>
					</div>
				</div>}
				</React.Fragment>)}
				{user.intelligence_policy_id === 1 && <div className="ability-container">
					<div className="ability-icon">
						<Satellite />
					</div>
					<div className="ability-information">
						<div className="ability-name">Spy Satellite</div>
						<div className="ability-usage">
							{hasPower === true && !user.gameFlag.hasOwnProperty('spy-satellite-cooldown') && 
								!user.gameFlag.hasOwnProperty('spy-satellite-reveal') && 
								<button name="use-spy-satellite" onClick={() => onUsage('spy-satellite')}>Activate</button>
							}
							{hasPower === false && !user.gameFlag.hasOwnProperty('spy-satellite-cooldown') && 
								!user.gameFlag.hasOwnProperty('spy-satellite-reveal') && `Unavailable: Low Power`
							}
							{user.gameFlag.hasOwnProperty('spy-satellite-reveal') && 
								this.renderProgress(user.gameFlag['spy-satellite-reveal'], game.game_date, 'Active For:')
							}
							{user.gameFlag.hasOwnProperty('spy-satellite-cooldown') && 
								this.renderProgress(user.gameFlag['spy-satellite-cooldown'], game.game_date, 'Recharging:')
							}
						</div>
					</div>
				</div>}
				{user.economy_policy_id === 5 && <div className="ability-container">
					<div className="ability-icon">
						<Reallocation />
					</div>
					<div className="ability-information">
						<div className="ability-name">Focus Production</div>
						<div className="ability-usage">
							{hasPower === true && !user.gameFlag.hasOwnProperty('focus-production-money') && 
								!user.gameFlag.hasOwnProperty('focus-production-fuel') && !user.gameFlag.hasOwnProperty('focus-production-supplies') && 
								<React.Fragment>
									<button name="focus-production" onClick={() => onUsage('focus-production-money')}>Money</button>&nbsp;
									<button name="focus-production" onClick={() => onUsage('focus-production-fuel')}>Fuel</button>&nbsp;
									<button name="focus-production" onClick={() => onUsage('focus-production-supplies')}>Supplies</button>
								</React.Fragment>
							}
							{hasPower === false && !user.gameFlag.hasOwnProperty('focus-production-money') && 
								!user.gameFlag.hasOwnProperty('focus-production-fuel') && !user.gameFlag.hasOwnProperty('focus-production-supplies') && 
								`Unavailable: Low Power`
							}
							{user.gameFlag.hasOwnProperty('focus-production-money') && 
								this.renderProgress(user.gameFlag['focus-production-money'], game.game_date, 'Money:')
							}
							{user.gameFlag.hasOwnProperty('focus-production-fuel') && 
								this.renderProgress(user.gameFlag['focus-production-fuel'], game.game_date, 'Fuel:')
							}
							{user.gameFlag.hasOwnProperty('focus-production-supplies') && 
								this.renderProgress(user.gameFlag['focus-production-supplies'], game.game_date, 'Supplies:')
							}
						</div>
					</div>
				</div>}
			</React.Fragment>
		);
	};
}

export default TechView;
