import React from 'react';
import Form from '../common/form';
import { formatDate } from '../../utilities/date.js';

class MissionForm extends Form {
	state = {
		action: null
	};

	onAction = (army, action, areaId) => {
		this.props.onAction([{id: army.id, action, target_id: areaId}]);

		this.setState({ action: null });
	};

	changeAction = action => {
		this.setState({ action });
	};

	render() {
		const { areas, army, game, inactive, units, user } = this.props;
		const { action } = this.state;

		if (! army || ! areas) {
			return null;
		}

		const area  = areas.find(a => a.id === army.game_area_id);
		const canAttack = army.type === 'land';
		const canAirStrike = army.type === 'air' || army.gameUnit.findIndex(u => units[u.unit_id].unit_class_id === 5 || units[u.unit_id].unit_class_id === 6) !== -1;
		const canBarrage = army.gameUnit.findIndex(u => units[u.unit_id].unit_class_id === 7) !== -1;
		const borderIds = area.area.border.reduce((ids, border) => {
			ids.push(border.area_2_id); 

			return ids;
		}, []);
		const borders = areas.filter(a => borderIds.indexOf(a.area_id) !== -1);
		const friendlyAreas = borders.filter(a => user.alliance[a.faction_id]);
		const hostileAreas = borders.filter(a => ! user.alliance[a.faction_id]);
		const actionMap = new Map();
		const friendlyActionMap = new Map();
		const hostileActionMap = new Map();
		const armyAction = army.armyAction.length ? army.armyAction[0] : null;
		const armyTarget = armyAction ? areas.find(a => a.id === armyAction.target_id) : null;

		if (army.battleArmy.length) {
			if (army.battleArmy[0].side === 'defender' || (army.battleArmy[1] && army.battleArmy[1].side === 'defender')) {
				actionMap.retreat = 'Retreat';
				friendlyAreas.map(a => friendlyActionMap.set(a.area.id, a.area.name));
			}
		}

		if (! actionMap.get('retreat')) {
			if (friendlyAreas.length) {
				actionMap.set('move', 'Move');
				friendlyAreas.map(a => friendlyActionMap.set(a.area.id, a.area.name));
			}

			if (game.phase === 'combat') {
				if (hostileAreas.length) {
					if (canAttack) {
						actionMap.set('attack', 'Attack');
						actionMap.set('support-attack', 'Support Attack');
					}

					if (canAirStrike) {
						actionMap.set('air-strike', 'Air Strike');
					}

					if (canBarrage) {
						actionMap.set('artillery-barrage', 'Artillery Barrage');
					}

					hostileAreas.map(a => hostileActionMap.set(a.area.id, a.area.name));
				}
			}

			if (armyAction && armyAction.action !== 'retreat') {
				actionMap.set('cancel', 'Cancel Action');
			}
		}

		const friendlyActions = [...friendlyActionMap];
		const hostileActions = [...hostileActionMap];
		const capitalize = s => s[0].toUpperCase() + s.slice(1).replace('_', ' ');
		let isDefending = false;

		army.battleArmy.length && army.battleArmy.forEach(b => {
			if (b.side === 'defender') {
				isDefending = true;

				return;
			}
		});

		const current = new Date(game.game_date.date);
		const offset = game.phase === 'combat-active' ? 1800 : 3600;
		let progressed = null;
		let finish = null;
		let destination = null;

		if (isDefending) {
			return `Defending ${area.area.name}`;
		} else if (army.gameFlag.hasOwnProperty('army-entrenching')) {
			const entrenchFlag = army.gameFlag['army-entrenching'];
			const entrenchTime = entrenchFlag.value * 3600;
			finish = new Date(entrenchFlag.flag_date.date);
			const entrenchTimeLeft = (finish.getTime() - current.getTime()) / 1000;

			progressed = Math.round((entrenchTime - entrenchTimeLeft) / (entrenchTime - offset) * 100);
			progressed = progressed <= 0 ? 0 : progressed >= 100 ? 100 : progressed;

			return <React.Fragment>
				<div className="status-army-action locked">
					Fortifying Position
				</div>
				<div className="status-army-action-progress">
					<span className="status-army-action-arrival">Completion: {formatDate(finish)}</span>
					<div className="progress-container active" title={`${progressed}%`}>
						<div className="progress-bar" style={{width: `${progressed}%`}}></div>
					</div>
				</div>
			</React.Fragment>;
		} else if (army.gameFlag.hasOwnProperty('army-detrenching')) {
			const detrenchFlag = army.gameFlag['army-detrenching'];
			const detrenchTime = detrenchFlag.value * 3600;
			finish = new Date(detrenchFlag.flag_date.date);
			const detrenchTimeLeft = (finish.getTime() - current.getTime()) / 1000;

			progressed = Math.round((detrenchTime - detrenchTimeLeft) / (detrenchTime - offset) * 100);
			progressed = progressed <= 0 ? 0 : progressed >= 100 ? 100 : progressed;

			return <React.Fragment>
				<div className="status-army-action locked">
					Dismantling Fortifications
				</div>
				<div className="status-army-action-progress">
					<span className="status-army-action-arrival">Completion: {formatDate(finish)}</span>
					<div className="progress-container active" title={`${progressed}%`}>
						<div className="progress-bar" style={{width: `${progressed}%`}}></div>
					</div>
				</div>
			</React.Fragment>;
		} else if (army.gameFlag.hasOwnProperty('army-plan-offensive')) {
			const planFlag = army.gameFlag['army-plan-offensive'];
			const planTime = planFlag.value * 3600;
			finish = new Date(planFlag.flag_date);
			const planTimeLeft = planFlag.flag_time - game.game_time;

			progressed = Math.round((planTime - planTimeLeft) / (planTime - offset) * 100);
			progressed = progressed <= 0 ? 0 : progressed >= 100 ? 100 : progressed;

			return <React.Fragment>
				<div className="status-army-action locked">
					Planning Offensive
				</div>
				<div className="status-army-action-progress">
					<span className="status-army-action-arrival">Completion: {formatDate(finish)}</span>
					<div className="progress-container active" title={`${progressed}%`}>
						<div className="progress-bar" style={{width: `${progressed}%`}}></div>
					</div>
				</div>
			</React.Fragment>;
		} else if (army.defensive_bonus > 0) {
			return <React.Fragment>
				<div className="status-army-action locked">
					Position has been Fortified
				</div>
			</React.Fragment>;
		}

		if (armyAction && (armyAction.action === 'move' || armyAction.action === 'redeployment' || armyAction.action === 'retreat' || ((armyAction.action === 'attack' || armyAction.action === 'air_strike') && armyAction.action_date))) {
			const travelTime = armyAction.travel_time * 3600;
			finish = new Date(armyAction.action_date.date);
			const timeLeft = (finish.getTime() - current.getTime()) / 1000;

			progressed = Math.round((travelTime - timeLeft) / (travelTime - offset) * 100);
			progressed = progressed <= 0 ? 0 : progressed >= 100 ? 100 : progressed;

			destination = areas.find(a => a.id === army.armyAction[army.armyAction.length - 1].target_id);
		}

		if (armyAction && (armyAction.action === 'retreat' || armyAction.action === 'redeployment')) {
			return <React.Fragment>
				<div className="status-army-action locked">
					{armyAction.action === 'retreat' && `Retreating to ${armyTarget.area.name}`}
					{armyAction.action === 'redeployment' && `Redeploying to ${armyTarget.area.name}`}
				</div>
				<div className="status-army-action-progress">
					<span className="status-army-action-arrival">Arrival: {formatDate(finish)}</span>
					<div className="progress-container active" title={`${progressed}%`}>
						<div className="progress-bar" style={{width: `${progressed}%`}}></div>
					</div>
				</div>
			</React.Fragment>;
		}

		return (
			<form onSubmit={this.handleSubmit}>
				{(!action || action === 'split') && !inactive && <div className="status-army-action">
					<div className="link" onClick={() => this.changeAction('mission')}>
						{armyAction && armyAction.action === 'attack' && `Attacking ${armyTarget.area.name}`}
						{armyAction && armyAction.action === 'support_attack' && `Support Attacking ${armyTarget.area.name}`}
						{armyAction && armyAction.action === 'air_strike' && `Attacking ${armyTarget.area.name} with Air Strike`}
						{armyAction && armyAction.action === 'artillery_barrage' && `Attacking ${armyTarget.area.name} with Artillery Barrage`}
						{armyAction && armyAction.action === 'move' && `Moving to ${armyTarget.area.name}`}
						{armyAction && armyAction.action === 'retreat' && `Retreating to ${armyTarget.area.name}`}
						{armyAction && armyAction.action === 'redeployment' && `Redeploying to ${armyTarget.area.name}`}
						{armyAction && destination && destination.id !== armyTarget.id && ` (${destination.area.name})`}
						{!army.armyAction.length && !army.battleArmy.length && 'This army has no mission set'}
					</div>
				</div>}
				{action && action === 'mission' && !inactive && <React.Fragment>
					<div className="link" onClick={() => this.changeAction(null)}>Back</div>
					{actionMap.get('move') && <div className="link" onClick={() => this.changeAction('move')}>Move</div>}
					{actionMap.get('attack') && <div className="link" onClick={() => this.changeAction('attack')}>Attack</div>}
					{actionMap.get('support-attack') && <div className="link" onClick={() => this.changeAction('support_attack')}>Support Attack</div>}
					{actionMap.get('air-strike') && <div className="link" onClick={() => this.changeAction('air_strike')}>Air Strike</div>}
					{actionMap.get('artillery-barrage') && <div className="link" onClick={() => this.changeAction('artillery_barrage')}>Artillery Barrage</div>}
					{actionMap.get('retreat') && <div className="link" onClick={() => this.changeAction('retreat')}>Retreat</div>}
					{actionMap.get('cancel') && <div className="link" onClick={() => this.onAction(army, 'cancel')}>Cancel Action</div>}
				</React.Fragment>}
				{action && (action === 'move' || action === 'retreat') && !!friendlyActions && <React.Fragment>
					<div className="link" onClick={() => this.changeAction('mission')}>Back</div>
					{friendlyActions.map(a => (
						<div key={a[0]} className="link" onClick={() => this.onAction(army, action, a[0])}>{capitalize(action)} to {a[1]}</div>
					))}
				</React.Fragment>}
				{action && (action === 'attack' || action === 'support_attack') && !!hostileActions && <React.Fragment>
					<div className="link" onClick={() => this.changeAction('mission')}>Back</div>
					{hostileActions.map(a => (
						<div key={a[0]} className="link" onClick={() => this.onAction(army, action, a[0])}>{capitalize(action)} {a[1]}</div>
					))}
				</React.Fragment>}
				{armyAction && !action && progressed !== null && <div className="status-army-action-progress">
					<span className="status-army-action-arrival">Arrival: {formatDate(finish)}</span>
					<div className="progress-container active" title={`${progressed}%`}>
						<div className="progress-bar" style={{width: `${progressed}%`}}></div>
					</div>
				</div>}
			</form>
		);
	}
}

export default MissionForm;
