import http from './httpService';

function allGames() {
	return http.get('/games');
}

function getGame(id) {
	return http.get('/games/' + id);
}

function saveGame(game) {
	if (game.id) {
		return http.put('/games/' + game.id, game);
	}

	return http.post('/games', game);
}

export default {
	all: allGames,
	get: getGame,
	save: saveGame
};
