import React, { Component } from 'react';
import { formatDate } from '../../utilities/date';
import Unit from './unit';

class View extends Component {
	state = {
		activeUnit: {}
	};

	getPositionClass(position, units) {
		const combat = this.props.combat;
		const activeUnit = this.state.activeUnit;
		let className = 'combat-position';

		if (units >= combat.width || (position.position >= Math.floor((combat.width - units) / 2) && position.position < Math.floor((combat.width + units) / 2))) {
			if (position.line === 'backline') {
				className += ' backline';
			} else {
				className += ' frontline';
			}

			if (activeUnit.game_unit_id && position.line === activeUnit.unit_line && activeUnit.id !== position.id) {
				className += ' open';
			}
		}

		return className;
	};

	handleDragStart = (event, unit) => {
		event.target.classList.add('moving');
		event.dataTransfer.setData('id', unit.game_unit_id);

		this.setState({ activeUnit: unit });
	};

	handleDragEnd = event => {
		event.target.classList.remove('moving');

		this.setState({ activeUnit: {} });
	};

	handleDragOver = (event, line) => {
		const activeUnit = this.state.activeUnit;

		if (line !== 'reserves') {
			const position = event.target.closest('.combat-position');

			if (! position) {
				return false;
			}

			if (! position.classList.contains(line.line) || activeUnit.unit_line !== line.line || activeUnit.id === line.id) {
				return false;
			}

			position.classList.add('active');
		}

		event.preventDefault();
	};

	handleDragLeave = event => {
		const position = event.target.closest('.combat-position');

		if (position) {
			position.classList.remove('active');
		}

		event.preventDefault();
	};

	handleDrop = (event, lineId) => {
		const id = parseInt(event.dataTransfer.getData('id'));
		const activeUnit = this.state.activeUnit;
		const position = event.target.closest('.combat-position');

		if (position) {
			position.classList.remove('active');
		}

		if (activeUnit.line === 'reserves' && lineId === 'reserves') {
			return;
		}

		this.props.onMoveUnit(this.props.combat.id, id, lineId);
	};

	render() {
		const { combat, game, user } = this.props;
		const opponent = Object.keys(combat.participants).find(p => parseInt(p) !== user.faction_id);
		const displayClass = combat.width > 22 ? 'thin' : 'normal';
		let backlineUnits = 0;
		let frontlineUnits = 0;

		Object.values(combat.battle.backline[user.faction_id]).forEach(u => {
			if (u.game_unit_id) {
				backlineUnits ++;
			}
		});

		Object.values(combat.battle.frontline[user.faction_id]).forEach(u => {
			if (u.game_unit_id) {
				frontlineUnits ++;
			}
		});

		Object.values(combat.battle.reserves[user.faction_id]).forEach(u => {
			if (u.game_unit_id) {
				if (u.unit_line === 'backline') {
					backlineUnits ++;
				} else {
					frontlineUnits ++;
				}
			}
		});

		return (
			<div id="combat-container" className={displayClass}>
				<div id="combat-timer" className="top">
					{game.phase === 'combat-prepare' && `Combat begins in ${game.phase_duration}s`}
				</div>
				<div id="combat-top">
					<div id="combat-name">{combat.battle_name}</div>
				</div>
				<div id="combat-options">
					{formatDate(game.game_date.date, true)}
				</div>
				<div id="combat-display">
					<div className="combat-army">
						<div className="combat-line backline">
							{combat.battle.backline[opponent].map((u, k) => <div key={k} className="combat-position"></div>)}
						</div>
						<div className="combat-line frontline">
							{combat.battle.frontline[opponent].map((u, k) => <div key={k} className="combat-position"></div>)}
						</div>
					</div>
					<div className="combat-army">
						<div className="combat-line frontline">
							{combat.battle.frontline[user.faction_id].map((u, k) => <div key={k} className={this.getPositionClass(u, frontlineUnits)} onDragLeave={event => this.handleDragLeave(event)} onDragOver={event => this.handleDragOver(event, u)} onDrop={event => this.handleDrop(event, u.id)}>
								{!!u.game_unit_id && <Unit key={u.id} unit={u} draggable={true} onDragStart={this.handleDragStart} onDragEnd={this.handleDragEnd} />}
							</div>)}
						</div>
						<div className="combat-line backline">
							{combat.battle.backline[user.faction_id].map((u, k) => <div key={k} className={this.getPositionClass(u, backlineUnits)} onDragLeave={event => this.handleDragLeave(event)} onDragOver={event => this.handleDragOver(event, u)} onDrop={event => this.handleDrop(event, u.id)}>
								{!!u.game_unit_id && <Unit key={u.id} unit={u} draggable={true} onDragStart={this.handleDragStart} onDragEnd={this.handleDragEnd} />}
							</div>)}
						</div>
					</div>
				</div>
				<div id="combat-reserves">
					<div id="combat-reserves-inner" onDragLeave={event => this.handleDragLeave(event)} onDragOver={event => this.handleDragOver(event, 'reserves')} onDrop={event => this.handleDrop(event, 'reserves')}>
						{combat.battle.reserves[user.faction_id].map(u => <Unit key={u.game_unit_id} unit={u} draggable={true} onDragStart={this.handleDragStart} onDragEnd={this.handleDragEnd} />)}
					</div>
				</div>
			</div>
		);
	}
}

export default View;
