import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import Joi from 'joi-browser';
import Form from '../common/form';
import Auth from '../../services/authService';
import Game from '../../services/gameService';

class LoginForm extends Form {
	state = {
		data: {
			username: '',
			password: ''
		},
		errors: {}
	};

	schema = {
		username: Joi.string().required().label('Username'),
		password: Joi.string().required().label('Password')
	};

	doSubmit = async () => {
		try {
			await Auth.login(this.state.data);

			const { data: game} = await Game.get('me');
			const { state } = this.props.location;

			if (game && Object.keys(game).length) {
				sessionStorage.setItem('game', JSON.stringify(game));
			}

			window.location = state ? state.from.pathname : '/game';
		} catch (e) {
			if (e.response && e.response.status === 400) {
				const errors = { ...this.state.errors };

				errors.username = e.response.data;

				this.setState({ errors });
			}
		}
	};

	render() {
		if (Auth.getUser()) {
			return <Redirect to="/game" />
		}
		return (
			<div className="home-outer">
				<div className="home-inner">
					<div className="container">
						<div className="heading">Login</div>
						<form onSubmit={this.handleSubmit}>
							{this.renderInput('username', 'Username')}
							{this.renderInput('password', 'Password', 'password')}
							{this.renderButton('Login')}
						</form>
						<p><Link to="/register">Register</Link></p>
					</div>
				</div>
			</div>
		);
	}
}

export default LoginForm;
