import React, { Component } from 'react';

class Resource extends Component {
	state = {
		stockpile: true,
		growth: true
	};

	toggle = category => {
		if (category === 'stockpile') {
			this.setState({ stockpile: ! this.state.stockpile });
		} else {
			this.setState({ growth: ! this.state.growth });
		}
	};

	render() {
		const { factions, hour, userSnapshotFields, users } = this.props;
		const day = Math.floor(hour / 24);
		const stockpileOpen = this.state.stockpile ? ' open' : '';
		const growthOpen = this.state.growth ? ' open' : '';
		const capitalize = s => s[0].toUpperCase() + s.slice(1).replace('_change', '');

		const factionGroups = Object.values(factions).reduce((groups, faction, index) => {
			const group = Math.floor(index / 2); 

			groups[group] = [].concat((groups[group] || []), faction); 

			return groups;
		}, []);

		return (
			<React.Fragment>
				<div className="status-province-large">
					<div className={`status-province-heading toggle${stockpileOpen}`} onClick={() => this.toggle('stockpile')}>Resource Stockpiles</div>
					<div className="replay-container">
					{factionGroups.map((g, k) => (
						<div className="replay-group" key={k}>
							<div className="replay-row header">
								<div className="replay-name overview"></div>
								{g.map(f => (
								<div className="replay-faction overview" key={f.id}>
									<div className={`owner user-colour faction-${f.id}`} title={f.abbreviation}></div>
								</div>
								))}
							</div>
							{['money', 'fuel', 'supplies', 'manpower', 'power'].map(v => (
							<div className="replay-row" key={v}>
								<div>
									<div className="replay-name overview">{capitalize(v)}</div>
									{g.map(f => (
									<div className="replay-value overview" key={f.id}>
										{users[f.id].snapshots[day] && Math.round(users[f.id].snapshots[day][userSnapshotFields[v]])}
										{!users[f.id].snapshots[day] && '-'}
									</div>
									))}
								</div>
							</div>
							))}
						</div>
					))}
					</div>
				</div>
				<div className="status-province-large">
					<div className={`status-province-heading toggle${growthOpen}`} onClick={() => this.toggle('growth')}>Resource Growth</div>
					<div className="replay-container">
					{factionGroups.map((g, k) => (
						<div className="replay-group" key={k}>
							<div className="replay-row header">
								<div className="replay-name overview"></div>
								{g.map(f => (
								<div className="replay-faction overview" key={f.id}>
									<div className={`owner user-colour faction-${f.id}`} title={f.abbreviation}></div>
								</div>
								))}
							</div>
							{['money_change', 'fuel_change', 'supplies_change', 'manpower_change'].map(v => (
							<div className="replay-row" key={v}>
								<div>
									<div className="replay-name overview">{capitalize(v)}</div>
									{g.map(f => (
									<div className="replay-value overview" key={f.id}>
										{users[f.id].snapshots[day] && users[f.id].snapshots[day][userSnapshotFields[v]]}
										{!users[f.id].snapshots[day] && '-'}
									</div>
									))}
								</div>
							</div>
							))}
						</div>
						))}
					</div>
				</div>
			</React.Fragment>
		);
	}
};

export default Resource;
