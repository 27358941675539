import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Combat extends Component {
	displayProgress(combat) {
		if (combat.current_progress >= 50) {
			return `The attacker has made ${combat.current_progress}% progress in defeating the defender`;
		}

		const progress = (100 - combat.current_progress).toFixed(2);

		return `The defender has made ${progress}% progress in repelling the attacker`;
	};

	getAreaName(id) {
		const area = this.props.areas.find(a => a.id === id);

		return area.area.name;
	};

	render() {
		const { combats } = this.props;

		if (! combats) {
			return;
		}

		return (
			<React.Fragment>
			{combats.map(c => (
				<div key={c.id} className="status-battle battle-container">
					<div className="status-battle-area">
						{this.getAreaName(c.game_area_id)}
					</div>
					<div className="status-battle-name">
						<Link className="link" to={`/game/view/combat/${c.id}`}>{c.battle_name}</Link>
					</div>
					<div className="status-battle-progress">
						<div className="status-battle-count-left">
							{c.battle.attacker.length}
						</div>
						<div className="progress-container large-container" title={this.displayProgress(c)}>
							<div className="progress-bar" style={{width: `${c.current_progress}%`}}></div>
						</div>
						<div className="status-battle-count-right">
							{c.battle.defender.length}
						</div>
					</div>
				</div>
			))}
			</React.Fragment>
		);
	}
}

export default Combat;
