import React from 'react';
import { formatDate } from '../../utilities/date.js';

const History = ({ history, id }) => {
	return (
		<div className="history-container">
			<div className={`owner user-colour faction-${id}`}></div>
			{history.map(row => (
				<div key={row.id} className="history-entry" title={row.value}>
					{formatDate(row.history_date)} : {row.value}
				</div>
			))}
		</div>
	);
}

export default History;
