export function formatDate(date, shortMonth = false, dateOnly = false) {
	date = date instanceof Date ? date : new Date(date);

	const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
	const shortMonths = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
	const minutes = ('0' + date.getMinutes()).slice(-2);
	const hours = ('0' + date.getHours()).slice(-2);
	const month = shortMonth ? shortMonths[date.getMonth()] : months[date.getMonth()];
	const day = date.getDate();
	const year = date.getFullYear();

	if (dateOnly) {
		return `${month} ${day}, ${year}`;
	}

	return `${hours}:${minutes} ${month} ${day}, ${year}`;
}
