import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Stats extends Component {
	render() {
		const { areas, armies, combats, completedCombats, equipment, factions, structures, units, user } = this.props;
		const areaStructures = {};
		const armyUnits = {};
		const armyEquipment = {};
		const losses = {};
		const history = {win: 0, loss: 0};

		armies.forEach(a => {
			a.gameUnit.forEach(u => {
				let unit = units[u.unit_id];

				unit.unitEquipment.forEach(e => {
					if (! armyEquipment[e.equipment.id]) {
						armyEquipment[e.equipment.id] = {id: e.equipment.id, name: e.equipment.name, value: 0};
					}

					armyEquipment[e.equipment.id].value += e.value;
				});

				if (! armyUnits[unit.id]) {
					armyUnits[unit.id] = {id: unit.id, name: unit.name, value: 0};
				}

				armyUnits[unit.id].value ++;
			});
		});

		areas.forEach(a => {
			if (a.faction_id === user.faction_id) {
				a.gameStructure.forEach(s => {
					let structure = structures[s.structure_id];

					if (! areaStructures[structure.id]) {
						areaStructures[structure.id] = {id: structure.id, name: structure.name, value: 0};
					}

					areaStructures[structure.id].value ++;
				});
			}
		});

		combats.forEach(c => {
			Object.values(c.equipmentLoss).forEach(e => {
				Object.values(e).forEach(l => {
					let unitEquipment = equipment[l.equipment_id];

					if (! losses[unitEquipment.id]) {
						losses[unitEquipment.id] = {id: unitEquipment.id, name: unitEquipment.name};

						Object.values(factions).forEach(f => {
							losses[unitEquipment.id][f.id] = {id: f.id, abbreviation: f.abbreviation, value: 0};
						});
					}

					losses[unitEquipment.id][l.faction_id].value += l.value;
				});
			});
		});

		completedCombats.forEach(c => {
			if (c.max_attacker_size > 0 && c.max_defender_size > 0) {
				Object.values(c.equipmentLoss).forEach(e => {
					Object.values(e).forEach(l => {
						let unitEquipment = equipment[l.equipment_id];

						if (! losses[unitEquipment.id]) {
							losses[unitEquipment.id] = {id: unitEquipment.id, name: unitEquipment.name};

							Object.values(factions).forEach(f => {
								losses[unitEquipment.id][f.id] = {id: f.id, abbreviation: f.abbreviation, value: 0};
							});
						}

						losses[unitEquipment.id][l.faction_id].value += l.value;
					});
				});

				if (c.participants[user.faction_id]) {
					if ((c.participants[user.faction_id] === 'attacker' && c.result === 'Attacker') || (c.participants[user.faction_id] === 'defender' && c.result === 'Defender')) {
						history.win ++;
					} else {
						history.loss ++;
					}
				}
			}
		});

		const factionGroups = Object.values(factions).reduce((groups, faction, index) => {
			const group = Math.floor(index / 2); 

			groups[group] = [].concat((groups[group] || []), faction); 

			return groups;
		}, []);

		return (
			<React.Fragment>
				<div className="status-province-large">
					<div className="status-province-heading">Installations</div>
					{Object.keys(areaStructures).sort((a, b) => areaStructures[b].value - areaStructures[a].value).map(s => (
					<div className="status-province-list" key={s}>
						<div>
							<div className="status-province-list-name">
								<Link to={`/game/view/construction/${s}`} className="link">{areaStructures[s].name}</Link>
							</div>
							<div className="status-province-list-count">
								{areaStructures[s].value}
							</div>
						</div>
					</div>
					))}
				</div>
				<div className="status-province-large">
					<div className="status-province-heading">Units</div>
					{Object.keys(armyUnits).sort((a, b) => armyUnits[b].value - armyUnits[a].value).map(u => (
					<div className="status-province-list" key={u}>
						<div>
							<div className="status-province-list-name"><Link to={`/game/view/unit/${u}`} className="link">{armyUnits[u].name}</Link></div>
							<div className="status-province-list-count">{armyUnits[u].value}</div>
						</div>
					</div>
					))}
				</div>
				<div className="status-province-large">
					<div className="status-province-heading">Equipment</div>
					{Object.keys(armyEquipment).sort((a, b) => armyEquipment[b].value - armyEquipment[a].value).map(e => (
					<div className="status-province-list" key={e}>
						<div>
							<div className="status-province-list-name">{armyEquipment[e].name}</div>
							<div className="status-province-list-count">{armyEquipment[e].value}</div>
						</div>
					</div>
					))}
				</div>
				<div className="status-province-large">
					<div className="status-province-heading">Equipment Losses</div>
					{factionGroups.map((g, k) => (
					<div className="losses-group" key={k}>
						<div className="losses-row header">
							<div className="losses-name"></div>
							{g.map(f => (
							<div className="losses-faction" key={f.id}>
								<div className={`owner user-colour faction-${f.id}`} title={f.abbreviation}></div>
							</div>
							))}
						</div>
						{Object.values(losses).map(l => (
						<div className="losses-row" key={l.id}>
							<div>
								<div className="losses-name">{l.name}</div>
								{g.map(f => (
								<div className="losses-value" key={f.id}>{Math.round(l[f.id].value)}</div>
								))}
							</div>
						</div>
						))}
					</div>
					))}
				</div>
				<div className="status-province-large">
					<div className="status-province-heading">Combat History</div>
					<div>
						<div>
							<div className="status-province-list-name">Victories</div>
							<div className="status-province-list-count">{history.win}</div>
						</div>
						<div>
							<div className="status-province-list-name">Defeats</div>
							<div className="status-province-list-count">{history.loss}</div>
						</div>
						<div>
							<div className="status-province-list-name">Total</div>
							<div className="status-province-list-count">{history.win + history.loss}</div>
						</div>
						<div className="combat-history-link">
							<Link to="/game/view/stats/combat" className="link">View Detailed History</Link>
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	};
};

export default Stats;
