import React, { Component } from 'react';

class ContentEditable extends Component {
	handleKeyDown = event => {
		const keys = {
			'backspace': 8,
			'enter': 13,
			'shift': 16,
			'ctrl': 17,
			'alt': 18,
			'delete': 46,
			'leftArrow': 37,
			'upArrow': 38,
			'rightArrow': 39,
			'downArrow': 40,
		};

		const utils = {
			special: {},
			navigational: {},
			submit: {},
			isSpecial(event) {
				return typeof this.special[event.keyCode] !== 'undefined';
			},
			isNavigational(event) {
				return typeof this.navigational[event.keyCode] !== 'undefined';
			},
			isSubmit(e) {
				return typeof this.submit[event.keyCode] !== 'undefined';
			}
		};

		utils.special[keys['backspace']] = true;
		utils.special[keys['shift']] = true;
		utils.special[keys['ctrl']] = true;
		utils.special[keys['alt']] = true;
		utils.special[keys['delete']] = true;

		utils.navigational[keys['upArrow']] = true;
		utils.navigational[keys['downArrow']] = true;
		utils.navigational[keys['leftArrow']] = true;
		utils.navigational[keys['rightArrow']] = true;

		utils.submit[keys['enter']] = true;

		const value = event.target.innerText.trim();
		const length = value.length;
		const selection = window.getSelection();
		const isSpecial = utils.isSpecial(event);
		const isNavigational = utils.isNavigational(event);
		const isSubmit = utils.isSubmit(event);
		let hasSelection = false;

		if (selection) {
			hasSelection = !!selection.toString();
		}

		if (isSpecial || isNavigational) {
			return true;
		}

		if (isSubmit) {
			event.preventDefault();
			event.target.blur();

			return false;
		}

		if (length >= this.props.length && !hasSelection) {
			event.preventDefault();

			return false;
		}
	};

	handleBlur = event => {
		const value = event.target.innerText.trim();

		if (value.length === 0) {
			event.target.textContent = this.props.element.name;
		} else if (value.length >= this.props.length) {
			event.target.textContent = this.props.element.name;
		} else {
			this.props.onChange(this.props.element, value);
		}
	};

	render() {
		return (
			<span suppressContentEditableWarning="true" contentEditable="true" className="editable" onBlur={this.handleBlur} onKeyDown={this.handleKeyDown}>
				{this.props.element.name}
			</span>
		);
	}
}

export default ContentEditable;
