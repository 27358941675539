import React, { Component } from 'react';
import { formatDate } from '../../utilities/date';
import Countdown from './countdown';
import Unit from './unit';

class Active extends Component {
	state = {
		activeTarget: {},
		activeUnit: {}
	};

	getPath(sourceFaction, sourceLine, sourcePosition, targetFaction, targetLine, targetPosition, displayClass) {
		const source = document.querySelectorAll(`.combat-army.faction-${sourceFaction} .${sourceLine} .combat-position`)[sourcePosition];
		const target = document.querySelectorAll(`.combat-army.faction-${targetFaction} .${targetLine} .combat-position`)[targetPosition];

		if (! source || ! target) {
			return;
		}

		const sourceLeft = source.offsetLeft + (displayClass === 'thin' ? 25.5 : 35);
		const sourceTop = source.offsetTop + 50;
		const targetLeft = target.offsetLeft + (displayClass === 'thin' ? 25.5 : 35);
		const targetTop = target.offsetTop + 50;

		return `M ${sourceLeft} ${sourceTop} L ${targetLeft} ${targetTop}`;

		//return `M ${sourceLeft} ${sourceTop} A ${Math.abs(targetLeft - sourceLeft)} ${Math.abs(targetTop - sourceTop)} 0 0 0 ${targetLeft} ${targetTop}`;
	};

	getPositionClass(position, units, attacked, targets, moves) {
		const combat = this.props.combat;
		let className = 'combat-position';

		if (units >= combat.width || (position.position >= Math.floor((combat.width - units) / 2) && position.position < Math.floor((combat.width + units) / 2))) {
			if (position.line === 'backline') {
				className += ' backline';
			} else {
				className += ' frontline';
			}
		}

		if (targets && targets[position.line][position.position] && position.game_unit_id) {
			className += ' attack';
		}

		if (moves && moves[position.line][position.position] && position.game_unit_id && ! position.action_time) {
			className += ' move';
		}

		if (attacked[position.faction_id][position.line][position.position]) {
			className += ' attacked';
		}

		return className;
	};

	getAvailableMoves() {
		const { combat, user } = this.props;
		const unit = this.state.activeUnit;
		const moves = {backline: {}, frontline: {}};

		if (! unit.id) {
			return moves;
		}

		if (combat.battle[unit.line][user.faction_id][unit.position - 1]) {
			moves[unit.line][unit.position - 1] = true;
		}

		if (combat.battle[unit.line][user.faction_id][unit.position + 1]) {
			moves[unit.line][unit.position + 1] = true;
		}

		return moves;
	};

	getAvailableTargets() {
		const { combat, user } = this.props;
		const unit = this.state.activeUnit;
		const opponent = Object.keys(combat.participants).find(p => parseInt(p) !== user.faction_id);
		const targets = {backline: {}, frontline: {}};
		const primaryTarget = {line: null, position: null};

		if (! unit.id) {
			return targets;
		}

		if (unit.unit_class_id === 5 || unit.unit_class_id === 6) {
			for (let x = 0; x < combat.width; x++) {
				targets.backline[x] = true;
				targets.frontline[x] = true;
			}

			return targets;
		} else if (unit.unit_class_id === 7) {
			targets.backline[unit.position] = true;
			targets.frontline[unit.position] = true;

			if (unit.position > 1) {
				targets.backline[unit.position - 1] = true;
				targets.frontline[unit.position - 1] = true;
				targets.frontline[unit.position - 2] = true;
			} else if (unit.position > 0) {
				targets.backline[unit.position - 1] = true;
				targets.frontline[unit.position - 1] = true;
			}

			if (unit.position < combat.width - 1) {
				targets.backline[unit.position + 1] = true;
				targets.frontline[unit.position + 1] = true;
				targets.frontline[unit.position + 2] = true;
			} else if (unit.position < combat.width - 2) {
				targets.backline[unit.position + 1] = true;
				targets.frontline[unit.position + 1] = true;
			}

			return targets;
		}

		const side = unit.position <= combat.width / 2 ? 'left' : 'right';
		const adjacentPosition = side === 'left' ? unit.position + 1 : unit.position - 1;

		if (combat.battle.frontline[opponent][unit.position].game_unit_id > 0) {
			primaryTarget.line = 'frontline';
			primaryTarget.position = unit.position;
		} else if (combat.battle.frontline[opponent][adjacentPosition].game_unit_id > 0) {
			primaryTarget.line = 'frontline';
			primaryTarget.position = adjacentPosition;
		} else if (combat.battle.backline[opponent][unit.position].game_unit_id > 0) {
			primaryTarget.line = 'backline';
			primaryTarget.position = unit.position;
		} else if (combat.battle.backline[opponent][adjacentPosition].game_unit_id > 0) {
			primaryTarget.line = 'backline';
			primaryTarget.position = adjacentPosition;
		} else {
			if (side === 'left') {
				for (let x = 0; x < combat.width; x++) {
					if (combat.battle.backline[opponent][x].game_unit_id > 0) {
						targets.backline[x] = true;
					}

					if (combat.battle.frontline[opponent][x].game_unit_id > 0) {
						targets.frontline[x] = true;
					}

					if (targets.backline[x] || targets.frontline[x]) {
						return targets;
					}
				}
			} else {
				for (let x = combat.width - 1; x >= 0; x--) {
					if (combat.battle.backline[opponent][x].game_unit_id > 0) {
						targets.backline[x] = true;
					}

					if (combat.battle.frontline[opponent][x].game_unit_id > 0) {
						targets.frontline[x] = true;
					}

					if (targets.backline[x] || targets.frontline[x]) {
						return targets;
					}
				}
			}
		}

		if (primaryTarget.line === 'backline') {
			targets[primaryTarget.line][primaryTarget.position] = true;
		} else if (unit.unit_id === 1) {
			targets[primaryTarget.line][primaryTarget.position] = true;
		} else if (unit.unit_class_id === 1 || unit.unit_class_id === 2 || unit.unit_class_id === 3) {
			if (unit.position === primaryTarget.position) {
				targets[primaryTarget.line][primaryTarget.position] = true;

				if (primaryTarget.position > 0) {
					targets[primaryTarget.line][primaryTarget.position - 1] = true;
				}

				if (primaryTarget < combat.width - 1) {
					targets[primaryTarget.line][primaryTarget.position + 1] = true;
				}
			} else {
				targets[primaryTarget.line][primaryTarget.position] = true;
			}
		} else if (unit.unit_class_id === 4) {
			if (unit.position === primaryTarget.position) {
				targets[primaryTarget.line][primaryTarget.position] = true;

				if (primaryTarget.position > 1) {
					targets[primaryTarget.line][primaryTarget.position - 1] = true;
					targets[primaryTarget.line][primaryTarget.position - 2] = true;
				} else if (primaryTarget.position > 0) {
					targets[primaryTarget.line][primaryTarget.position - 1] = true;
				}

				if (primaryTarget.position < combat.width - 2) {
					targets[primaryTarget.line][primaryTarget.position + 1] = true;
					targets[primaryTarget.line][primaryTarget.position + 2] = true;
				} else if (primaryTarget < combat.width - 1) {
					targets[primaryTarget.line][primaryTarget.position + 1] = true;
				}
			} else if (unit.position < primaryTarget.position) {
				targets[primaryTarget.line][primaryTarget.position] = true;
				targets[primaryTarget.line][primaryTarget.position + 1] = true;
			} else {
				targets[primaryTarget.line][primaryTarget.position] = true;
				targets[primaryTarget.line][primaryTarget.position - 1] = true;
			}
		}

		return targets;
	};

	handleDragStart = (event, unit) => {
		event.target.classList.add('moving');
		event.dataTransfer.setData('id', unit.id);
		event.dataTransfer.setData('position', unit.position);
		event.dataTransfer.setData('unit_id', unit.game_unit_id);

		this.setState({ activeUnit: unit });
	};

	handleDragEnd = event => {
		event.target.classList.remove('moving');

		this.setState({ activeTarget: {}, activeUnit: {} });
	};

	handleDragOver = (event, line) => {
		const combat = this.props.combat;
		const { activeTarget, activeUnit } = this.state;
		const position = event.target.closest('.combat-position');

		if (combat.phase !== 'prepare') {
			return false;
		}

		if (activeUnit && activeUnit.line !== 'reserves' && line !== 'reserves') {
			if (! position) {
				return false;
			}

			if (! position.classList.contains('attack') && ! position.classList.contains('move')) {
				return false;
			}
		} else if (line !== 'reserves') {
			if (! position) {
				return false;
			}

			if (! position.classList.contains(line.line) || activeUnit.unit_line !== line.line || activeUnit.id === line.id) {
				return false;
			}
		}

		if (line.id !== activeTarget.id) {
			this.setState({ activeTarget: line });

			position.classList.add('active');
		}

		event.preventDefault();
	};

	handleDragLeave = event => {
		const position = event.target.closest('.combat-position');

		if (position && this.state.activeTarget.id) {
			position.classList.remove('active');

			this.setState({ activeTarget: {} });
		}

		event.preventDefault();
	};

	handleDrop = (event, action, lineId) => {
		const combat = this.props.combat;
		const id = parseInt(event.dataTransfer.getData('id'));
		const unitId = parseInt(event.dataTransfer.getData('unit_id'));
		const position = event.target.closest('.combat-position');

		if (position) {
			position.classList.remove('active');
		}

		if (combat.phase !== 'prepare') {
			return false;
		}

		if (action === 'attack') {
			this.props.onAttackUnit(this.props.combat.id, id, lineId);
		} else if (action === 'move') {
			this.props.onMoveUnit(this.props.combat.id, unitId, lineId);
		}
	};

	change = direction => {
		const { combat, combats } = this.props;
		const index = combats.findIndex(c => c.id === combat.id);
		let combatId = null;

		if (direction === 'prev') {
			if (index === 0) {
				combatId = combats.length - 1;
			} else {
				combatId = index - 1;
			}
		} else {
			if (index === combats.length - 1) {
				combatId = 0;
			} else {
				combatId = index + 1;
			}
		}

		return this.props.history.push(`/game/view/combat/${combats[combatId].id}`);
	};

	close = () => {
		return this.props.history.push('/game/view/combat');
	};

	render() {
		const { combat, combats, game, user } = this.props;
		const { activeTarget, activeUnit } = this.state;
		const opponent = Object.keys(combat.participants).find(p => parseInt(p) !== user.faction_id);
		const availableTargets = this.getAvailableTargets();
		const availableMoves = this.getAvailableMoves();
		const attacked = {};
		const canDrag = combat.phase === 'prepare';
		const canChange = combats.length > 1;
		const displayClass = combat.width > 22 ? 'thin' : 'normal';
		let backlineUnits = 1;
		let frontlineUnits = 1;

		if (combat.result === 'Attacker' || combat.result === 'Defender') {
			return this.props.history.replace('/game/view/combat');
		}

		Object.keys(combat.participants).forEach(u => {
			attacked[u] = {backline: {}, frontline: {}};
		})

		Object.values(combat.battle.backline[user.faction_id]).forEach(u => {
			if (u.game_unit_id) {
				backlineUnits ++;

				if (combat.phase === 'attack' && u.target_line && u.target_position >= 0) {
					attacked[opponent][u.target_line][u.target_position] = true;
				}
			}
		});

		Object.values(combat.battle.frontline[user.faction_id]).forEach(u => {
			if (u.game_unit_id) {
				frontlineUnits ++;

				if (combat.phase === 'attack' && u.target_line && u.target_position >= 0) {
					attacked[opponent][u.target_line][u.target_position] = true;
				}
			}
		});

		Object.values(combat.battle.backline[opponent]).forEach(u => {
			if (u.game_unit_id && combat.phase === 'attack' && u.target_line && u.target_position >= 0) {
				attacked[user.faction_id][u.target_line][u.target_position] = true;
			}
		});

		Object.values(combat.battle.frontline[opponent]).forEach(u => {
			if (u.game_unit_id && combat.phase === 'attack' && u.target_line && u.target_position >= 0) {
				attacked[user.faction_id][u.target_line][u.target_position] = true;
			}
		});

		return (
			<div id="combat-container" className={displayClass}>
				<div id="combat-timer" className="top">
					{combat.phase === 'prepare' && `Combat phase in ${combat.phase_duration}s`}
					{(combat.phase === 'target' || combat.phase === 'attack') && 'Attacking'}
				</div>
				<div id="combat-top">
					<div id="combat-name">{combat.battle_name}</div>
				</div>
				<div id="combat-options">
					{formatDate(game.game_date.date, true)}
					{canChange && <div className="combat-change" onClick={() => this.change('prev')}>&lt;</div>}
					{canChange && <div className="combat-change" onClick={() => this.change('next')}>&gt;</div>}
					<div id="combat-close" onClick={() => this.close()}>X</div>
				</div>
				<div id="combat-display">
					<div className={`combat-army faction-${opponent}`}>
						<div className="combat-line backline">
							{combat.battle.backline[opponent].map((u, k) => <div key={k} className={this.getPositionClass(u, null, attacked, availableTargets)} onDragLeave={event => this.handleDragLeave(event)} onDragOver={event => this.handleDragOver(event, u)} onDrop={event => this.handleDrop(event, 'attack', u.id)}>
								{!!u.game_unit_id && <Unit key={u.id} unit={u} />}
							</div>)}
						</div>
						<div className="combat-line frontline">
							{combat.battle.frontline[opponent].map((u, k) => <div key={k} className={this.getPositionClass(u, null, attacked, availableTargets)} onDragLeave={event => this.handleDragLeave(event)} onDragOver={event => this.handleDragOver(event, u)} onDrop={event => this.handleDrop(event, 'attack', u.id)}>
								{!!u.game_unit_id && <Unit key={u.id} unit={u} />}
							</div>)}
						</div>
					</div>
					<div className={`combat-army faction-${user.faction_id}`}>
						<div className="combat-line frontline">
							{combat.battle.frontline[user.faction_id].map(u => <div key={u.id} className={this.getPositionClass(u, frontlineUnits, attacked, null, availableMoves)} onDragLeave={event => this.handleDragLeave(event)} onDragOver={event => this.handleDragOver(event, u)} onDrop={event => this.handleDrop(event, 'move', u.id)}>
								{!!u.game_unit_id && <Unit key={u.id} unit={u} draggable={canDrag && ! u.action_time} onDragStart={this.handleDragStart} onDragEnd={this.handleDragEnd} />}
								{u.action_time && <Countdown time={u.action_time} type={displayClass} />}
							</div>)}
						</div>
						<div className="combat-line backline">
							{combat.battle.backline[user.faction_id].map(u => <div key={u.id} className={this.getPositionClass(u, backlineUnits, attacked, null, availableMoves)} onDragLeave={event => this.handleDragLeave(event)} onDragOver={event => this.handleDragOver(event, u)} onDrop={event => this.handleDrop(event, 'move', u.id)}>
								{!!u.game_unit_id && <Unit key={u.id} unit={u} draggable={canDrag && ! u.action_time} onDragStart={this.handleDragStart} onDragEnd={this.handleDragEnd} />}
								{u.action_time && <Countdown time={u.action_time} type={displayClass} />}
							</div>)}
						</div>
					</div>
				</div>
				<div id="combat-reserves">
					<div id="combat-reserves-inner" onDragLeave={event => this.handleDragLeave(event)} onDragOver={event => this.handleDragOver(event, 'reserves')} onDrop={event => this.handleDrop(event, 'move', 'reserves')}>
						{combat.battle.reserves[user.faction_id].map(u => <div key={u.game_unit_id} className="combat-position">
							<Unit unit={u} draggable={canDrag && ! u.action_time && u.organisation > 25} onDragStart={this.handleDragStart} onDragEnd={this.handleDragEnd} />
							{u.action_time && <Countdown time={u.action_time} />}
						</div>)}
					</div>
				</div>
				<svg id="combat-animations" width="100%" height="100%">
					<defs>
						<marker id="arrow-move" viewBox="0 0 10 10" refX="0" refY="5" markerWidth="5" markerHeight="5" orient="auto" fill="red" stroke="none">
							<path d="M 0 0 L 10 5 L 0 10 z" />
						</marker>
						<marker id="arrow-attack" viewBox="0 0 10 10" refX="0" refY="5" markerWidth="5" markerHeight="5" orient="auto" fill="red" stroke="none">
							<path d="M 0 0 L 10 5 L 0 10 z" />
						</marker>
					</defs>
					{activeTarget.id && activeUnit.id && activeTarget.faction_id !== activeUnit.faction_id && <path className="combat-path prepare" markerEnd="url(#arrow-attack)" d={this.getPath(user.faction_id, activeUnit.line, activeUnit.position, opponent, activeTarget.line, activeTarget.position, displayClass)} />}
					{combat.phase === 'target' && <React.Fragment>
						{combat.battle.backline[user.faction_id].map((u, k) => <React.Fragment key={k}>
							{!!u.game_unit_id && !!u.target_line && u.target_position >= 0 && <path className="combat-path attack" markerEnd="url(#arrow-attack)" d={this.getPath(user.faction_id, 'backline', u.position, opponent, u.target_line, u.target_position, displayClass)} />}
						</React.Fragment>)}
						{combat.battle.frontline[user.faction_id].map((u, k) => <React.Fragment key={k}>
							{!!u.game_unit_id && !!u.target_line && u.target_position >= 0 && <path className="combat-path attack" markerEnd="url(#arrow-attack)" d={this.getPath(user.faction_id, 'frontline', u.position, opponent, u.target_line, u.target_position, displayClass)} />}
						</React.Fragment>)}
						{combat.battle.backline[opponent].map((u, k) => <React.Fragment key={k}>
							{!!u.game_unit_id && !!u.target_line && u.target_position >= 0 && <path className="combat-path defend" markerEnd="url(#arrow-attack)" d={this.getPath(opponent, 'backline', u.position, user.faction_id, u.target_line, u.target_position, displayClass)} />}
						</React.Fragment>)}
						{combat.battle.frontline[opponent].map((u, k) => <React.Fragment key={k}>
							{!!u.game_unit_id && !!u.target_line && u.target_position >= 0 && <path className="combat-path defend" markerEnd="url(#arrow-attack)" d={this.getPath(opponent, 'frontline', u.position, user.faction_id, u.target_line, u.target_position, displayClass)} />}
						</React.Fragment>)}
					</React.Fragment>}
				</svg>
			</div>
		);
	}
}

export default Active;
