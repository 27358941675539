import React from 'react';
import Joi from 'joi-browser';
import Form from '../common/form';
import { ReactComponent as Binoculars } from '../../images/binoculars.svg';
import { ReactComponent as Satellite } from '../../images/satellite.svg';
import { ReactComponent as Crates } from '../../images/crates.svg';
import { ReactComponent as Logistics } from '../../images/logistics.svg';
import { ReactComponent as Reallocation } from '../../images/reallocation.svg';
import { ReactComponent as Requisition } from '../../images/requisition.svg';
import { ReactComponent as Hospital } from '../../images/hospital.svg';
import { ReactComponent as Drills } from '../../images/drills.svg';
import { ReactComponent as Research } from '../../images/research.svg';
import { ReactComponent as Engineer } from '../../images/engineer.svg';
import { ReactComponent as Tactics } from '../../images/tactics.svg';
import { ReactComponent as Ammunition } from '../../images/ammunition.svg';
import { ReactComponent as Plan } from '../../images/plan.svg';
import { ReactComponent as Manpower } from '../../images/massmanpower.svg';

class PolicyForm extends Form {
	state = {
		data: {
			intelligence: 1,
			logistics: 3,
			economy: 5,
			experience: 7,
			technology: 9,
			military: 11
		},
		errors: {}
	};

	schema = {
		intelligence: Joi.string().required().label('Intelligence Policy'),
		logistics: Joi.string().required().label('Logistics Policy'),
		economy: Joi.string().required().label('Economic Policy'),
		experience: Joi.string().required().label('Experience Policy'),
		technology: Joi.string().required().label('Technology Policy'),
		military: Joi.string().required().label('Military Doctrine'),
	};

	doSubmit = (field, value) => {
		const data = {...this.state.data};

		data[field] = value;

		this.props.onSelection(data);
	};

	render() {
		const policyGroups = {};
		const data = this.state.data;
		const icons = {
			Binoculars: <Binoculars />,
			Satellite: <Satellite />,
			Crates: <Crates />,
			Logistics: <Logistics />,
			Reallocation: <Reallocation />,
			Requisition: <Requisition />,
			Hospital: <Hospital />,
			Drills: <Drills />,
			Research: <Research />,
			Engineer: <Engineer />,
			Tactics: <Tactics />,
			Ammunition: <Ammunition />,
			Plan: <Plan />,
			Manpower: <Manpower />
		};

		this.props.policies.forEach(p => {
			if (! policyGroups[p.category]) {
				policyGroups[p.category] = {};
			}

			policyGroups[p.category][p.id] = p;
		});

		return (
			<form onSubmit={this.handleSubmit}>
				<div className="policy-container">
					{Object.keys(policyGroups).map(category => (
						<div className="policy-column" key={category}>
							<h2>{category.toUpperCase()}</h2>
							{Object.values(policyGroups[category]).map(p => (
								<React.Fragment key={p.id}>
									<input type="radio" className="policy-choice" id={`policy-${p.id}`} name={category} value={p.id} defaultChecked={data[category] === p.id} onChange={event => this.handleChange(event, this.doSubmit)} />
									<label className="policy-option" htmlFor={`policy-${p.id}`}>
										{icons[p.icon]}
										<div className="policy-name">
											<span className="policy-name-heading">{p.name}</span>
											<p><strong>{p.effect_type}</strong>: {p.effect_summary}</p>
										</div>
										<div className="policy-information">
											<p>{p.description_line1}</p>
											{p.description_line2 && <p>{p.description_line2}</p>}
										</div>
									</label>
								</React.Fragment>
							))}
						</div>
					))}
					<div className="policy-countdown">Policy Selection will be locked in {this.props.game.phase_duration}s</div>
				</div>
			</form>
		);
	}
}

export default PolicyForm;