import http from './httpService';

function allTheatres() {
	return http.get('/theatres');
}

function getTheatre(id) {
	return http.get('/theatres/' + id);
}

export default {
	all: allTheatres,
	get: getTheatre
};
