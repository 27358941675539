import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Combat from '../combat';
import Development from '../development';
import MissionForm from '../forms/missionForm';
import StructureForm from '../forms/structureForm';
import UnitForm from '../forms/unitForm';
import { ReactComponent as AntiAir } from '../../images/antiair.svg';
import { ReactComponent as Explosion } from '../../images/explosion.svg';
import { ReactComponent as Fort } from '../../images/fort.svg';
import { ReactComponent as Fuel } from '../../images/fuel.svg';
import { ReactComponent as Loan } from '../../images/loan.svg';
import { ReactComponent as Manpower } from '../../images/manpower.svg';
import { ReactComponent as Money } from '../../images/money.svg';
import { ReactComponent as Repair } from '../../images/repair.svg';
import { ReactComponent as Supply } from '../../images/supplies.svg';
import { ReactComponent as SupplyHalf } from '../../images/supply-half.svg';
import { ReactComponent as SupplyFull } from '../../images/supply-full.svg';

class Area extends Component {
	groupStructures(areaStructures, structures) {
		const data = areaStructures.sort((a, b) => structures[a.structure_id].name.localeCompare(structures[b.structure_id].name)).reduce((count, structure) => {
			if (! count[structures[structure.structure_id].name]) {
				count[structures[structure.structure_id].name] = {id: structure.structure_id, count: 0};
			}

			count[structures[structure.structure_id].name].count = (count[structures[structure.structure_id].name].count || 0) + 1;

			return count;
		}, {});

		return data;
	};

	renderFlags(flags, gameDate) {
		return (
			<div className="area-summary-flags">
				{flags['area-supply-centre-half'] && ! flags['area-supply-centre-full'] && <div className="icon" title="Area grants a supply depot bonus">
					<SupplyHalf fill="white" /></div>}
				{flags['area-supply-centre-full'] && <div className="icon" title="Area grants a supply depot bonus">
					<SupplyFull fill="white" /></div>}
				{flags['area-supply-dump-small'] && <div className="icon" title={this.renderFlagTitle(flags['area-supply-dump-small'], gameDate)}>
					<Supply fill="#CD7F32" /></div>}
				{flags['area-supply-dump-medium'] && <div className="icon" title={this.renderFlagTitle(flags['area-supply-dump-medium'], gameDate)}>
					<Supply fill="silver" /></div>}
				{flags['area-supply-dump-large'] && <div className="icon" title={this.renderFlagTitle(flags['area-supply-dump-large'], gameDate)}>
					<Supply fill="gold" /></div>}
				{flags['area-supply-dump-massive'] && <div className="icon" title={this.renderFlagTitle(flags['area-supply-dump-massive'], gameDate)}>
					<Supply fill="whitesmoke" /></div>}
				{flags['area-repairing'] && <div className="icon" title={this.renderFlagTitle(flags['area-repairing'], gameDate)}>
					<Repair fill="white" /></div>}
				{flags['area-destroyed'] && <div className="icon" title="Area has been destroyed by a superweapon">
					<Explosion fill="white" /></div>}
			</div>
		);
	};

	renderFlagTitle(flag, gameDate) {
		const endDate = new Date(flag.flag_date.date);
		const current = new Date(gameDate.date);
		const timeLeft = (endDate.getTime() - current.getTime()) / 1000;
		const daysLeft = (timeLeft / 86400).toFixed(1);

		if (flag.flag.name === 'area-supply-dump-small') {
			return `Area has a small supply dump; expires in ${daysLeft} days`;
		}

		if (flag.flag.name === 'area-supply-dump-medium') {
			return `Area has a medium supply dump; expires in ${daysLeft} days`;
		}

		if (flag.flag.name === 'area-supply-dump-large') {
			return `Area has a large supply dump; expires in ${daysLeft} days`;
		}

		if (flag.flag.name === 'area-supply-dump-massive') {
			return `Area has a massive supply dump; expires in ${daysLeft} days`;
		}

		if (flag.flag.name === 'area-repairing') {
			return `Area will be repaired in ${daysLeft} days`;
		}
	};

	render() {
		const { areas, combats, game, factions, onAction, onAreaTransfer, structures, units, user } = this.props;
		const id = parseInt(this.props.match.params.id);
		const area  = areas.find(a => a.area.id === id);
		const airWings = this.props.armies.filter(a => a.type === 'air' && a.game_area_id === area.id);
		const armies = this.props.armies.filter(a => a.type === 'land' && a.game_area_id === area.id);
		const borderIds = area.area.border.reduce((ids, border) => {
			ids.push(border.area_2_id); 

			return ids;
		}, []);
		const borders = areas.filter(a => borderIds.indexOf(a.area_id) !== -1);

		if (! area.area) {
			return null;
		}

		const areaStructures = area && area.gameStructure ? this.groupStructures(area.gameStructure, structures) : null;
		const allianceMember = Object.values(user.alliance).find(a => a !== user.faction_id);

		return (
			<React.Fragment>
				<div className="status-province">
					<div className="status-province-area">{area.area.name}</div>
				</div>
				<div className="status-province-large">
					<div className="status-province-heading">Area</div>
					<div className="status-province-list">
						<div>
							<Link className="link" to={`/game/view/area/region/${area.area.region.id}`}>{area.area.region.name}</Link>
						</div>
						<div className="text">
							{factions[area.faction_id].name}
						</div>
						<div className="area-summary">
							<div title="Wealth">
								<Money />
								<span>{area.wealth}</span>
							</div>
							<div title="Supplies">
								<Supply />
								<span>{area.supplies}</span>
							</div>
							<div title="Fuel">
								<Fuel />
								<span>{area.oil}</span>
							</div>
							<div title="Manpower">
								<Manpower /> 
								<span>{area.manpower}</span>
							</div>
						</div>
						<div className="area-summary">
							<div title="Fort Level">
								<Fort />
								<span>{(area.fort_strength / 1000).toFixed(1)}</span>
							</div>
							<div title="Anti Air Level">
								<AntiAir />
								<span>{(area.anti_air_strength / 1000).toFixed(1)}</span>
							</div>
						</div>
						{!!Object.keys(area.gameFlag).length && this.renderFlags(area.gameFlag, game.game_date)}
					</div>
				</div>
				<div className="status-province-large">
					<div className="status-province-heading">Bordering Areas</div>
					<div className="status-province-list">
					{borders.map(b => (
						<React.Fragment key={b.id}>
							<div className="status-province-list-name border-container">
								<Link className="link" to={`/game/view/area/${b.area_id}`}>{b.area.name}</Link>
							</div>
							<div className="status-province-list-count">
								<div className={`owner user-colour faction-${b.faction_id}`} title={factions[b.faction_id].abbreviation}></div>
							</div>
						</React.Fragment>
					))}
					</div>
				</div>
				{area.activeBattles && Object.values(area.activeBattles).map(b => <Combat key={b} combats={combats} id={b} />)}
				{airWings && !!airWings.length && <div className="status-province-large">
					<div className="status-province-heading">Air Wings</div>
					<div className="status-province-list">
					{airWings.map(a => (
						<div key={a.id} className="status-army-container">
							<div className="status-province-list-name">
								<Link className="link" to={`/game/view/army/${a.id}`}>{a.name}</Link>
							</div>
							<div className="status-province-list-count">{a.gameUnit.length}</div>
							<div className="status-province-list">
								<div className="status-province-list-name">
									<div className="status-army-container">
										<MissionForm 
											areas={areas}
											army={a} 
											game={game} 
											units={units}
											user={user}
											onAction={onAction}
										/>
									</div>
								</div>
							</div>
						</div>
					))}
					</div>
				</div>}
				{armies && !!armies.length && <div className="status-province-large">
					<div className="status-province-heading">Armies</div>
					<div className="status-province-list">
					{armies.sort((a, b) => a.name.localeCompare(b.name)).map(a => (
						<div key={a.id} className="status-army-container">
							<div className="status-province-list-name">
								<Link className="link" to={`/game/view/army/${a.id}`}>{a.name}</Link>
							</div>
							<div className="status-province-list-count">{a.gameUnit.length}</div>
							<div className="status-province-list">
								<div className="status-province-list-name">
									<div className="status-army-container">
										<MissionForm 
											areas={areas}
											army={a} 
											game={game} 
											units={units}
											user={user}
											onAction={onAction}
										/>
									</div>
								</div>
							</div>
						</div>
					))}
					</div>
				</div>}
				{area.gameStructure && !!area.gameStructure.length && <div className="status-province-large">
					<div className="status-province-heading">Installations</div>
					<div className="status-province-list">
					{Object.keys(areaStructures).map((k, s) => (
						<React.Fragment key={k}>
							<div className="status-province-list-name">
								<Link className="link" to={`/game/view/area/${area.area_id}/installation/${areaStructures[k].id}`}>{k}</Link>
							</div>
							<div className="status-province-list-count">{areaStructures[k].count}</div>
						</React.Fragment>
					))}
					</div>
				</div>}
				{area.availableUnit && !!area.availableUnit.length && <div className="status-province-large">
					<div className="status-province-heading">Unit Developments</div>
					<div className="status-province-list"><UnitForm area={area} onSubmit={this.props.onNewUnit} /></div>
					{area.unitDevelopment && !!area.unitDevelopment.length && <div className="status-province-list">
						{area.unitDevelopment.map(ud => (
							<Development 
								key={ud.id} 
								type="unit"
								area={area}
								record={ud} 
								game={game} 
								category={units}
								onCancel={this.props.onCancel}
								onPriorityChange={this.props.onPriorityChange} 
							/>
						))}
					</div>}
				</div>}
				{area.availableStructure && !!area.availableStructure.length && <div className="status-province-large">
					<div className="status-province-heading">Installation Developments</div>
					<div className="status-province-list"><StructureForm area={area} onSubmit={this.props.onNewStructure} /></div>
					{area.structureDevelopment && !!area.structureDevelopment.length && <div className="status-province-list">
						{area.structureDevelopment.map(sd => (
							<Development 
								key={sd.id} 
								type="structure"
								area={area}
								record={sd} 
								game={game} 
								category={structures}
								onCancel={this.props.onCancel}
								onPriorityChange={this.props.onPriorityChange} 
							/>
						))}
					</div>}
				</div>}
				{area.faction_id === user.faction_id && area.id !== user.capital_id && allianceMember && <div className="army-abilities">
					<div className="army-ability" title="Transfer Ownership">
						<Loan onClick={() => onAreaTransfer(allianceMember, area.id)}/>
					</div>
				</div>}
			</React.Fragment>
		)
	};
}

export default Area;
