import React from 'react';
import Joi from 'joi-browser';
import Form from '../common/form';

class MergeForm extends Form {
	state = {
		data: {
			mergeId: null,
		},
		errors: {}
	};

	schema = {
		mergeId: Joi.required().label('Merge ID'),
	};

	constructor(props) {
		super(props);

		this.state.data.mergeId = props.areaArmies[0].id;
	};

	componentDidUpdate = () => {
		if (! this.props.areaArmies.find(a => a.id === parseInt(this.state.data.mergeId))) {
			const data = {mergeId: this.props.areaArmies[0].id};

			this.setState({ data });
		}
	};

	doSubmit = async () => {
		const data = {merges: [parseInt(this.state.data.mergeId)], id: this.props.army.id};
		
		this.props.onMerge(data);
	};

	render() {
		const { areaArmies } = this.props;

		return (
			<div className="split-army-container">
				<form onSubmit={this.handleSubmit}>
					<select id="mergeId" name="mergeId" onChange={event => this.handleChange(event)}>
					{areaArmies.map(a => (
						<option key={a.id} value={a.id}>{`${a.name} - ${a.gameUnit.length} Divisions`}</option>
					))}
					</select>
					<button disabled={this.validate()} type="submit" className="action-button" name="merge-army">Merge</button>
				</form>
			</div>
		);
	}
}

export default MergeForm;
