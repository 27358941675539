import React from 'react';

const Connect = ({ time, onAbortConnect }) => {
	return <div id="user-container">
		<h2>Connection Lost</h2>
		{time === 0 && <div className="game-status">Attempting to reconnect...</div>}
		{time === 1 && <div className="game-status">Attempting to reconnect in 1 second</div>}
		{time > 1 && <div className="game-status">Attempting to reconnect in {time} seconds</div>}
		<button onClick={() => onAbortConnect()}>Abort Connection</button>
	</div>;
}

export default Connect;
