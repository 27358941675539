import React, { Component } from 'react';
import Area from './area';
import Army from './army';
import Combat from './combat';
import MapArea from '../map/area';
import MapArmy from '../map/army';
import MapBorder from '../map/border';
import MapDefs from '../map/defs';
import MapPath from '../map/path';
import { formatDate } from '../../utilities/date.js';

class Map extends Component {
	state = {
		area: null,
		army: null,
		armyFocus: null,
		combat: null,
		focusArea: {},
		mapType: 'zoom-1'
	};

	hideInfo = null;
	mapTick = null;
	mapMovements = {};

	constructor(props) {
		super(props);

		this.info = React.createRef();
		this.svg = React.createRef();
	};

	componentDidMount() {
		window.addEventListener('keydown', this.handleKeyDown, false);
		window.addEventListener('keyup', this.handleKeyUp, false);
		window.addEventListener('mousemove', this.handlePan, false);
		document.addEventListener('mouseleave', this.handleLeave, false);
		document.addEventListener('visibilitychange', this.handleVisibilityChange, false);

		this.mapTick = window.setInterval(this.handleMapMove, 10);
	};

	componentWillUnmount() {
		window.removeEventListener('keydown', this.handleKeyDown, false);
		window.removeEventListener('keyup', this.handleKeyUp, false);
		window.removeEventListener('mousemove', this.handlePan, false);
		document.removeEventListener('mouseleave', this.handleLeave, false);
		document.removeEventListener('visibilitychange', this.handleVisibilityChange, false);
		window.clearTimeout(this.hideInfo);
		window.clearInterval(this.mapTick);
	};

	getAreaClass(area, hasCombat, capitals) {
		let className = `owner-${area.faction_id}`;

		if (hasCombat) {
			className += ' combat';
		}

		if (capitals[area.id]) {
			className += ' capital';
		}

		return className;
	};

	getFilterName(area) {
		const selectedArea = this.state.area;
		const { pointOfView, users } = this.props;

		if (! pointOfView || users[pointOfView].alliance[area.faction_id]) {
			return selectedArea === area.id ? 'url(#highlight)' : '';
		}

		if (users[pointOfView].vision[area.id] === true) {
			return selectedArea === area.id ? 'url(#highlight)' : '';
		}

		return selectedArea === area.id ? 'url(#fog-highlight)' : 'url(#fog)';
	};

	getArmyMovement(action, area, targetId) {
		const data = {d: `M ${area.area.left_point} ${area.area.top_point}`};
		const target = this.props.areas.find(a => a.id === targetId);
		const distance = Math.sqrt(Math.pow(target.area.left_point - area.area.left_point, 2) + Math.pow(target.area.top_point - area.area.top_point, 2)) - 7;
		const angle = Math.atan2(Math.abs(target.area.top_point - area.area.top_point), Math.abs(target.area.left_point - area.area.left_point));
        let x = Math.cos(angle) * distance;
        let y = Math.sin(angle) * distance;

        if (target.area.left_point > area.area.left_point) {
        	x += area.area.left_point;
        } else {
        	x = area.area.left_point - x;
        }

        if (target.area.top_point > area.area.top_point) {
        	y += area.area.top_point;
        } else {
        	y = area.area.top_point - y;
        }

		data.d += ` L ${x} ${y}`

		if (action === 'move') {
			data.stroke = 'blue';
			data.markerEnd = 'url(#arrow-move)';
		} else if (action === 'attack') {
			data.stroke = 'red';
			data.markerEnd = 'url(#arrow-attack)';
		} else if (action === 'air_strike' || action === 'artillery_barrage' || action === 'support_attack') {
			data.stroke = 'red';
			data.markerEnd = 'url(#arrow-attack)';
			data.strokeDasharray = 2;
		} else if (action === 'retreat') {
			data.stroke = 'yellow';
			data.markerEnd = 'url(#arrow-retreat)';
		} else if (action === 'redeployment') {
			data.stroke = 'green';
			data.markerEnd = 'url(#arrow-redeployment)';
			data.strokeDasharray = 2;
		}

		return data;
	};

	getDirectionalArrow(start, end) {
        let angle = Math.atan2(end.area.left_point - start.area.left_point, start.area.top_point - end.area.top_point);

        if (angle < 0) {
        	angle += Math.PI * 2;
        }

        angle *= (180 / Math.PI);

        if (angle > 337.5 || angle <= 22.5) {
        	return 'u';
        } else if (angle > 22.5 && angle <= 67.5) {
        	return 'ur';
        } else if (angle > 67.5 && angle <= 112.5) {
        	return 'r';
        } else if (angle > 112.5 && angle <= 157.5) {
        	return 'dr';
        } else if (angle > 157.5 && angle <= 202.5) {
        	return 'd';
        } else if (angle > 202.5 && angle <= 247.5) {
        	return 'dl';
        } else if (angle > 247.5 && angle <= 292.5) {
        	return 'l';
        } else {
        	return 'ul';
        }
	};

	selectArea = (event, area) => {
		if (this.state.area !== area.id && ! area.combat) {
			this.setState({ area: area.id, army: null, armyFocus: null, combat: null, focusArea: {} });
		} else if (this.state.combat === area.combat || ! area.combat) {
			this.setState({ area: null, army: null, armyFocus: null, combat: null, focusArea: {} });
		} else if (area.combat) {
			this.setState({ area: null, army: null, armyFocus: null, combat: area.combat, focusArea: {} });
		}

		event.preventDefault();
	};

	selectArmy = (event, area, gameArea, faction) => {
		const armyIds = this.props.armies.reduce((ids, army) => {
			if (army.game_area_id === gameArea && army.faction_id === faction) {
				ids.push(army.id);
			}

			return ids;
		}, []);

		this.setState({ area: null, army: armyIds, armyFocus: null, combat: null, focusArea: {areaId: gameArea, factionId: faction}});

		event.preventDefault();
	};

	handleAreaNameDisplay = event => {
		const parent = event.target.parentNode;
		const element = this.info.current;

		if (event.target.nodeName === 'polyline') {
			return;
		}

		if (parent.nodeName !== 'g' || ! parent.dataset.name) {
			element.style.display = 'none';

			window.clearTimeout(this.hideInfo);

			return;
		}

		element.innerText = parent.dataset.name;
		element.style.display = 'block';

		if (event.clientX + 132 > window.innerWidth) {
			element.style.right = '12px';
			element.style.left = 'unset';
		} else {
			element.style.left = `${event.clientX + 12}px`;
			element.style.right = 'unset';
		}

		element.style.top = `${event.clientY + 12}px`;

		if (this.hideInfo) {
			window.clearTimeout(this.hideInfo);
		}

		this.hideInfo = window.setTimeout(() => element.style.display = 'none', 1000);
	};

	handleWheel = e => {
		const svg = this.svg.current;
		const theatre = this.props.theatre;
		const viewBox = svg.getAttribute('viewBox').split(' ');
		const scale = theatre.width / theatre.height;
		const svgWidth = parseInt(svg.clientWidth);
		const svgHeight = parseInt(svg.clientHeight);
		const panWidthScale = svgWidth / theatre.width;
		const panHeightScale = svgHeight / theatre.height;
		let width = parseFloat(viewBox[2]);
		let height = parseFloat(viewBox[3]);

		if (e.deltaY > 0) {
			width += theatre.zoom;
			height += theatre.zoom / scale;
		} else {
			width -= theatre.zoom;
			height -= theatre.zoom / scale;
		}

		if (width >= theatre.width) {
			width = theatre.width;
			height = parseInt(theatre.width / scale);
		} else if (width <= theatre.width - theatre.zoom * 3) {
			width = theatre.width - theatre.zoom * 3;
			height = parseInt(width / scale);
		}

		let left = (parseFloat(viewBox[0]) + viewBox[2] / 2) - (width / 2);
		left = (left + width) * panWidthScale > svgWidth ? (svgWidth - width * panWidthScale) / panWidthScale : left;
		left = left <= 0 ? 0 : left;

		let top = (parseFloat(viewBox[1]) + viewBox[3] / 2) - (height / 2);
		top = (top + height) * panHeightScale > svgHeight ? (svgHeight - height * panHeightScale) / panHeightScale : top;
		top = top <= 0 ? 0 : top;

		svg.setAttribute('viewBox', `${left} ${top} ${width} ${height}`);

		const zoomLevel = theatre.zoom > 0 ? (theatre.width - width) / theatre.zoom + 1 : 1;

		this.setState({mapType: `zoom-${zoomLevel}`});
	};

	handleMapMove = () => {
		if (Object.keys(this.mapMovements).length === 0) {
			return;
		}

		const svg = this.svg.current;
		const theatre = this.props.theatre;
		const viewBox = svg.getAttribute('viewBox').split(' ');
		const svgWidth = parseInt(svg.clientWidth);
		const svgHeight = parseInt(svg.clientHeight);
		const panWidthScale = svgWidth / theatre.width;
		const panHeightScale = svgHeight / theatre.height;
		let left = parseFloat(viewBox[0]);
		let top = parseFloat(viewBox[1]);
		let width = parseFloat(viewBox[2]);
		let height = parseFloat(viewBox[3]);

		if (this.mapMovements.left) {
			left -= 4;
		} else if (this.mapMovements.right) {
			left += 4;
		}

		if (this.mapMovements.up) {
			top -= 4;
		} else if (this.mapMovements.down) {
			top += 4;
		}

		left = (left + width) * panWidthScale > svgWidth ? (svgWidth - width * panWidthScale) / panWidthScale : left;
		left = left <= 0 ? 0 : left;

		top = (top + height) * panHeightScale > svgHeight ? (svgHeight - height * panHeightScale) / panHeightScale : top;
		top = top <= 0 ? 0 : top;

		svg.setAttribute('viewBox', `${left} ${top} ${width} ${height}`);
	};

	handleKeyDown = e => {
		switch (e.key) {
			case 'a':
			case 'ArrowLeft':
				this.mapMovements.left = true;
			break;
			case 'w':
			case 'ArrowUp':
				this.mapMovements.up = true;
			break;
			case 'd':
			case 'ArrowRight':
				this.mapMovements.right = true;
			break;
			case 's':
			case 'ArrowDown':
				this.mapMovements.down = true;
			break;
			default:
				return;
		}
	};

	handleKeyUp = e => {
		switch (e.key) {
			case 'a':
			case 'ArrowLeft':
				delete this.mapMovements.left;
			break;
			case 'w':
			case 'ArrowUp':
				delete this.mapMovements.up;
			break;
			case 'd':
			case 'ArrowRight':
				delete this.mapMovements.right;
			break;
			case 's':
			case 'ArrowDown':
				delete this.mapMovements.down;
			break;
			default:
				return;
		}
	};

	handleLeave = e => {
		if (e.clientY <= 0) {
			this.mapMovements.up = true;
		} else if (e.clientY >= window.innerHeight - 1) {
			this.mapMovements.down = true;
		}
	};

	handleVisibilityChange = () => {
		if (document.visibilityState === 'hidden') {
			this.mapMovements = {};

			window.clearInterval(this.mapTick);

			this.mapTick = null;
		} else if (! this.mapTick) {
			this.mapTick = window.setInterval(this.handleMapMove, 10);
		}
	};

	handlePan = e => {
		this.handleAreaNameDisplay(e);

		if (e.clientX === 0) {
			this.mapMovements.left = true;
		} else if (e.clientX === window.innerWidth - 1) {
			this.mapMovements.right = true;
		} else {
			delete this.mapMovements.left;
			delete this.mapMovements.right;
		}

		if (e.clientY === 0) {
			this.mapMovements.up = true;
		} else if (e.clientY === window.innerHeight - 1) {
			this.mapMovements.down = true;
		} else {
			delete this.mapMovements.up;
			delete this.mapMovements.down;
		}
	};

	handleToggleArmyPath = id => {
		if (this.state.armyFocus === id) {
			this.setState({ armyFocus: null });
		} else {
			this.setState({ armyFocus: id });
		}
	};

	render() {
		const { area, army, armyFocus, combat, focusArea, mapType } = this.state;
		const { areas, armies, armySnapshotFields, armySnapshots, combats, equipment, factions, game, hour, largeMovementIndicator, pointOfView, structures, theatre, units, users } = this.props;
		const capitals = Object.values(users).reduce((areas, user) => { 
			areas[user.capital_id] = true;

			return areas;
		}, {});
		const armyLocations = armies.reduce((locations, army) => {
			if (! army.gameUnit.length) {
				return locations;
			}

			if (! locations[army.game_area_id]) {
				locations[army.game_area_id] = {};
			}

			if (! locations[army.game_area_id][army.faction_id]) {
				locations[army.game_area_id][army.faction_id] = {
					count: 0, 
					infantry: false, 
					armor: false, 
					artillery: false, 
					medical: false, 
					hq: false, 
					fighter: false, 
					bomber: false, 
					logistics: false,
					index: 0,
					total: 1,
					faction_id: army.faction_id
				};
			}

			locations[army.game_area_id][army.faction_id].count += army.gameUnit.length;

			army.gameUnit.forEach(u => {
				const unit = units[u.unit_id];

				if (unit.unit_class_id === 1) {
					locations[army.game_area_id][army.faction_id].infantry = true;
				} else if ([2, 3, 4, 9].indexOf(unit.unit_class_id) !== -1) {
					locations[army.game_area_id][army.faction_id].armor = true;
				} else if (unit.unit_class_id === 5) {
					locations[army.game_area_id][army.faction_id].fighter = true;
				} else if ([6, 10].indexOf(unit.unit_class_id) !== -1) {
					locations[army.game_area_id][army.faction_id].bomber = true;
				} else if (unit.unit_class_id === 7) {
					locations[army.game_area_id][army.faction_id].artillery = true;
				} else if (unit.unit_class_id === 8) {
					locations[army.game_area_id][army.faction_id].hq = true;
				} else if (unit.unit_class_id === 11) {
					locations[army.game_area_id][army.faction_id].logistics = true;
				} else if (unit.unit_class_id === 12) {
					locations[army.game_area_id][army.faction_id].medical = true;
				}
			});

			return locations;
		}, {});
		const armyMovements = areas.reduce((locations, area) => {
			['move', 'attack', 'retreat'].forEach(a => {
				if (area.actions[a]) {
					Object.values(area.actions[a]).forEach(t => {
						const arrow = this.getDirectionalArrow(area, areas.find(m => m.id === t.target_id));

						if (! locations[area.id]) {
							locations[area.id] = {};
						}

						if (! locations[area.id][t.faction_id]) {
							locations[area.id][t.faction_id] = {};
						}

						locations[area.id][t.faction_id][arrow] = a;
					});
				}
			});

			return locations;
		}, {});
		const armyPath = armyFocus ? armies.find(a => a.id === armyFocus) : null;
		const focus = focusArea && focusArea.areaId ?  areas.find(a => a.id === focusArea.areaId) : null;
		const counterScale = theatre.counter_scale === 0 ? 1 : (mapType === 'zoom-3' || mapType === 'zoom-4' || theatre.zoom === 0) ? 0.65 : 0.85;

		Object.keys(armyLocations).forEach(l => {
			const total = Object.keys(armyLocations[l]).length;
			let adjustIndex = true;
			let index = total - 1;

			Object.keys(armyLocations[l]).forEach((f, k) => {
				armyLocations[l][f].total = total;
				armyLocations[l][f].index = index;

				if (focusArea && parseInt(f) === focusArea.factionId && parseInt(l) === focusArea.areaId) {
					armyLocations[l][f].index = 0;

					if (k === 0) {
						//adjustIndex = false;
					}
				} else if (focusArea && parseInt(l) === focusArea.areaId && parseInt(f) !== focusArea.factionId && index !== total - 1 && adjustIndex) {
					armyLocations[l][f].index ++; 
				}

				index --;
			});
		});

		return <React.Fragment>
			<div id="map-container" tabIndex="0" onContextMenu={e => e.preventDefault()} onWheel={this.handleWheel}>
				<div id="game-date">{formatDate(game.date)}</div>
				<svg width="100%" height="100%" viewBox={`0 0 ${theatre.width} ${theatre.height}`} ref={this.svg} className={`theatre-${theatre.id} ${mapType}`}>
					<MapDefs />
					{areas.map(a => (
						<g key={a.id} tabIndex="0" filter={this.getFilterName(a)} className={this.getAreaClass(a, a.combat, capitals)} onMouseDown={event => this.selectArea(event, a)} data-name={a.area.name}>
							<MapArea area={a.area} />
						</g>
					))}
					<MapBorder theatre={theatre} />
					{largeMovementIndicator && areas.map(a => (
						<React.Fragment key={a.area.id}>
							{(! pointOfView || users[pointOfView].vision[a.id] || a.faction_id === pointOfView) && <React.Fragment>}
								{a.actions.move && Object.keys(a.actions.move).map(i => <path key={i} {...this.getArmyMovement('move', a, a.actions.move[i].target_id)} className="army-path" />)}
								{a.actions.attack && Object.keys(a.actions.attack).map(i => <path key={i} {...this.getArmyMovement('attack', a, a.actions.attack[i].target_id)} className="army-path" />)}
								{a.actions.retreat && Object.keys(a.actions.retreat).map(i => <path key={i} {...this.getArmyMovement('retreat', a, a.actions.retreat[i].target_id)} className="army-path" />)}
								{a.actions.redeployment && Object.keys(a.actions.redeployment).map(i => <path key={i} {...this.getArmyMovement('redeployment', a, a.actions.redeployment[i].target_id)} className="army-path" />)}
							</React.Fragment>}
						</React.Fragment>
					))}
					{armyPath && !!armyPath.armyAction.length && <MapPath areas={areas} army={armyPath} replay={true} />}
					{areas.map(a => (
						<React.Fragment key={a.area.id}>
							{armyLocations[a.id] && (! pointOfView || users[pointOfView].vision[a.id] || users[pointOfView].alliance[a.faction_id]) && Object.keys(armyLocations[a.id]).map(f => <React.Fragment key={f}>
								{(!focusArea.areaId || focusArea.areaId !== a.id || (focusArea.areaId === a.id && focusArea.factionId !== f)) && <MapArmy 
									area={a}
									army={armyLocations[a.id][f]}
									largeMovementIndicator={largeMovementIndicator}
									movements={armyMovements[a.id] ? armyMovements[a.id][f] : {}}
									scale={counterScale}
									onMouseDown={this.selectArmy}
								/>}
							</React.Fragment>)}
						</React.Fragment>
					))}
					{focusArea.areaId && focusArea.factionId && armyLocations[focusArea.areaId] && armyLocations[focusArea.areaId][focusArea.factionId] && <MapArmy 
						area={focus}
						army={armyLocations[focusArea.areaId][focusArea.factionId]}
						largeMovementIndicator={largeMovementIndicator}
						movements={armyMovements[focusArea.areaId] ? armyMovements[focusArea.areaId][focusArea.factionId] : {}}
						scale={counterScale}
						onMouseDown={this.selectArmy}
					/>}
				</svg>
			</div>
			<div ref={this.info} id="area-information"></div>
			{area && <Area areas={areas} factions={factions} structures={structures} id={area} />}
			{army && <Army 
				areas={areas} 
				armies={armies} 
				armySnapshotFields={armySnapshotFields}
				armySnapshots={armySnapshots} 
				combats={combats} 
				equipment={equipment} 
				factions={factions} 
				hour={hour} 
				units={units} 
				ids={army} 
				onToggleArmyPath={this.handleToggleArmyPath}
			/>}
			{combat && <Combat 
				areas={areas} 
				armies={armies} 
				armySnapshotFields={armySnapshotFields}
				armySnapshots={armySnapshots} 
				combats={combats} 
				equipment={equipment} 
				factions={factions} 
				id={combat} 
			/>}
		</React.Fragment>;
	}
}

export default Map;
