import React, { Component } from 'react';
import { ReactComponent as Exclamation } from '../images/exclamation.svg';
import { ReactComponent as Icon } from '../images/chat.svg';
import Socket from '../services/socketService';

class Chat extends Component {
	state = {
		active: false,
		messages: [],
		unread: false
	};

	shouldScroll = false;

	constructor(props) {
		super(props);

		this.content = React.createRef();
	};

	componentDidMount() {
		Socket.addCallback('onmessage', data => {
			if (data.type === 'message') {
				const messages = [...this.state.messages];
				const unread = this.state.active === false;

				messages.push(data.body);

				this.shouldScroll = this.content.current.offsetHeight + this.content.current.scrollTop >= this.content.current.scrollHeight - 1;
				this.setState({ messages, unread });
			}
		});
	};

	shouldComponentUpdate(nextProps, nextState) {
		if (this.state.messages.length !== nextState.messages.length || this.state.active !== nextState.active) {
			return true;
		}

		return false;
	};

	componentDidUpdate() {
		if (this.shouldScroll) {
			this.content.current.scrollTo(0, this.content.current.scrollHeight);

			this.shouldScroll = false;
		}
	};

	handleKeyDown = event => {
		if (event.key === 'Enter' && ! event.shiftKey) {
			const message = event.target.value;

			if (message.replace(/\s+/, "") === '') {
				return;
			}

			const messages = [...this.state.messages];

			Socket.send({
				action: 'send-message',
				data: { message }
			});

			this.shouldScroll = this.content.current.offsetHeight + this.content.current.scrollTop >= this.content.current.scrollHeight - 1;

			messages.push({name: 'Me', message});

			this.setState({ messages });

			event.target.value = '';
			event.preventDefault();

			return false;
		}
	};

	toggleChat = () => {
		const active = ! this.state.active;

		this.setState({ active, unread: false });
	};

	render() {
		const { messages, unread } = this.state;
		const { user, users } = this.props;
		const active = this.state.active === true ? 'active' : '';
		const participants = Object.values(users).reduce((names, name) => {
			if (user.id !== name.id && user.alliance[name.faction_id]) {
				names.push(name.user.handle);
			}

			return names;
		}, []);

		return (
			<React.Fragment>
				<div className={`mode ${active}`} onClick={() => this.toggleChat()}>
					<Icon />
					{unread && <Exclamation className="unread" />}
				</div>
				<div className={`outer-chat-container mouse ${active}`}>
					<div className="inner-chat-container">
						<div className="chat">
							<div className="chat-header">
								<div className="chat-header-name">{participants.join(', ')}</div>
							</div>
							<div className="chat-body" ref={this.content}>
								<div className="chat-body-content">
								{messages.map((m, k) => (
									<div className="chat-entry" key={k}>
										<span className="chat-entry-from">{m.name}: </span>
										<span className="chat-entry-content">{m.message}</span>
									</div>
								))}
								</div>
							</div>
							<div className="chat-footer">
								<textarea className="editable" onKeyDown={this.handleKeyDown}></textarea>
							</div>
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default Chat;
