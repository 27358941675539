import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { formatDate } from '../../utilities/date';

class CombatHistory extends Component {
	getCombatResult(combat, faction_id) {
		let role = combat.participants[faction_id];

		role = role.charAt(0).toUpperCase() + role.slice(1);

		if (combat.result === 'InProgress' || combat.result === 'OnHold' || combat.result === 'Prepare') {
			return `${role} - Undecided`;
		} else if (combat.result === 'Attacker' && role === 'Attacker') {
			return 'Attacker - Victory';
		} else if (combat.result === 'Defender' && role === 'Attacker') {
			return 'Attacker - Defeat';
		} else if (combat.result === 'Defender' && role === 'Defender') {
			return 'Defender - Victory';
		} else if (combat.result === 'Attacker' && role === 'Defender') {
			return 'Defender - Defeat';
		}
	};

	render() {
		const { combats, completedCombats, user } = this.props;

		return (
			<React.Fragment>
			{[...combats, ...completedCombats].filter(c => c.max_attacker_size > 0 && c.max_defender_size > 0 && c.participants[user.faction_id]).map(c => (
				<div className="status-army" key={c.id}>
					<div className="status-army-area">
						{c.battle_name}
					</div>
					<div>
						<div>
							<div className="status-province-list-name">Role</div>
							<div className="status-province-list-count">{this.getCombatResult(c, user.faction_id)}</div>
						</div>
						<div>
							<div className="status-province-list-name">Start</div>
							<div className="status-province-list-count">{formatDate(c.start_time.date, true, true)}</div>
						</div>
						<div>
							<div className="status-province-list-name">End</div>
							<div className="status-province-list-count">{c.end_time && formatDate(c.end_time.date, true, true)}</div>
						</div>
						<div>
							<div className="status-province-list-name">Attacker Size</div>
							<div className="status-province-list-count">{c.max_attacker_size}</div>
						</div>
						<div>
							<div className="status-province-list-name">Defender Size</div>
							<div className="status-province-list-count">{c.max_defender_size}</div>
						</div>
						<div className="combat-history-link">
							<Link to={`/game/view/stats/combat/${c.id}`} className="link">View Combat</Link>
						</div>
					</div>
				</div>
			))}
			<Link to="/game/view/stats" className="link offset">Back</Link>
			</React.Fragment>
		);
	};
}

export default CombatHistory;
