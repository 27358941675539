import http from './httpService';

function allFactions() {
	return http.get('/factions');
}

function getFaction(id) {
	return http.get('/factions/' + id);
}

export default {
	all: allFactions,
	get: getFaction
};
