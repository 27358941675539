import React, { Component } from 'react';

class Army extends Component {
	getArmySize(armySize) {
		if (armySize >= 20) {
			return 'XXXXX';
		} else if (armySize >= 12) {
			return 'XXXX';
		} else if (armySize >= 6) {
			return 'XXX';
		} else {
			return 'XX';
		}
	};

	getArrowClass(type) {
		if (this.props.largeMovementIndicator === true) {
			return 'arrow';
		}

		const movements = this.props.movements;

		if (! movements || ! movements[type]) {
			return 'arrow';
		}

		return `arrow ${movements[type]}`;
	};

	handleSelectArmies(event, area, gameArea, faction) {
		if (this.props.onSelectArmies) {
			this.props.onSelectArmies(area, gameArea, faction);
		} else if (this.props.onMouseDown) {
			this.props.onMouseDown(event, area, gameArea, faction);
		}
	};

	render() {
		const { area, army, scale } = this.props;
		const offset = -Math.floor(army.total / 2) * 5 + army.index * 5 + (army.total % 2 === 0 ? 2.5 : 0);

		return (
			<g className="army" onClick={event => this.handleSelectArmies(event, parseInt(area.area_id), parseInt(area.id), army.faction_id)} transform={`translate(${area.area.left_point - 15 + offset}, ${area.area.top_point - 15 + offset}) scale(${scale})`}>
				<polygon tabIndex="0" points="11 5, 15 0, 19 5" className={this.getArrowClass('u')} />
				<polygon tabIndex="0" points="21 4, 27 3, 26 9" className={this.getArrowClass('ur')} />
				<polygon tabIndex="0" points="25 11, 30 15, 25 19" className={this.getArrowClass('r')} />
				<polygon tabIndex="0" points="21 26, 27 27, 26 21" className={this.getArrowClass('dr')} />
				<polygon tabIndex="0" points="11 25, 15 30, 19 25" className={this.getArrowClass('d')} />
				<polygon tabIndex="0" points="9 26, 3 27, 4 21" className={this.getArrowClass('dl')} />
				<polygon tabIndex="0" points="5 11, 0 15, 5 19" className={this.getArrowClass('l')} />
				<polygon tabIndex="0" points="9 4, 3 3, 4 9" className={this.getArrowClass('ul')} />
				<rect x="7" y="7" rx="1" filter="url(#fog-highlight)" className={`counter-container faction-${army.faction_id}`} />
				<text className="counter-size" x="15" y="8.5">{this.getArmySize(army.count)}</text>
				<rect x="9" y="10" className="counter-composition" />
				{army.infantry && <React.Fragment>
					<line x1="9" y1="10" x2="21" y2="17" />
					<line x1="9" y1="17" x2="21" y2="10" />
				</React.Fragment>}
				{army.armor && <ellipse cx="15" cy="13.5" rx="4" ry="2" />}
				{army.artillery && <circle className="unit" cx="15" cy="13.5" r="1" />}
				{(army.fighter || army.bomber) && <React.Fragment>
					<path d="M 12 12 Q 10 13.5 12 15 L 18 12 Q 20 13.5 18 15 Z" />
					<line x1="15" y1="11" x2="15" y2="16" />
				</React.Fragment>}
				{army.bomber && <circle className="unit" cx="15" cy="15.5" r="1" />}
				{army.fighter && <React.Fragment>
					<line x1="15" y1="11" x2="14" y2="12" />
					<line x1="15" y1="11" x2="16" y2="12" />
				</React.Fragment>}
				{army.hq && <circle className="counter-modifier" cx="10" cy="20" r="1" />}
				{army.medical && <circle className="counter-modifier" cx="15" cy="20" r="1" />}
				{army.logistics && <circle className="counter-modifier" cx="20" cy="20" r="1" />}
				<rect x="9" y="10" className="counter-composition border" />
				<rect x="9" y="24" width="12" height="6" fill="black" opacity="0.7" rx="1" className="counter-number-outer" />
				<text x="15" y="26.75" className="counter-number">{army.count}</text>
			</g>
		);
	}
}

Army.defaultProps = {
	scale: 1
}

export default Army;
