import React from 'react';
import { Route, Switch, Link, NavLink } from 'react-router-dom';
import DashboardCreateForm from './forms/createForm';
import DashboardJoinForm from './forms/joinForm';
import DashboardHome from './dashboard/home';
import DashboardFaction from './dashboard/faction';
import DashboardHistory from './dashboard/history';
import DashboardStructure from './dashboard/structure';
import DashboardUnit from './dashboard/unit';
import '../styles.css';

const Dashboard = () => {
    return (
        <div id="horizon">
            <div id="contentMain">
                <div id="container">
                    <div id="header">
                        <ul>
                            <li><NavLink to="/game">Introduction</NavLink></li>
                            {sessionStorage.game && <li><NavLink to="/game/view/area">Continue Game</NavLink></li>}
                            {! sessionStorage.game && <li><NavLink to="/game/create">Create Game</NavLink></li>}
                            {! sessionStorage.game && <li><NavLink to="/game/join">Join Game</NavLink></li>}
                            <li><NavLink to="/game/history">Game History</NavLink></li>
                            <li><NavLink to="/game/factions">Factions</NavLink></li>
                            <li><NavLink to="/game/structures">Structures</NavLink></li>
                            <li><NavLink to="/game/units">Units</NavLink></li>
                            <li><Link to="/logout">Logout</Link></li>
                        </ul>
                    </div>
                    <div id="main_content">
                        <div id="middle">
                            <div className="content">
                                <Switch>
                                    <Route path="/game/create" component={DashboardCreateForm} />
                                    <Route path="/game/join" component={DashboardJoinForm} />
                                    <Route path="/game/factions" component={DashboardFaction} />
                                    <Route path="/game/history" render={props => <DashboardHistory user={sessionStorage.user} />} />
                                    <Route path="/game/structures" component={DashboardStructure} />
                                    <Route path="/game/units" component={DashboardUnit} />
                                    <Route path="/game" component={DashboardHome} />
                                </Switch>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Dashboard;
