import React, { Component } from 'react';

class CombatSummary extends Component {
	state = {
		open: true
	};

	toggle = () => {
		this.setState({ open: ! this.state.open });
	};

	render() {
		const { combats, factions, hour } = this.props;
		const stats = {attackerWin: {}, attackerLose: {}, defenderWin: {}, defenderLose: {}};
		const open = this.state.open ? ' open' : '';

		factions.forEach(f => {
			stats.attackerWin[f.id] = 0;
			stats.attackerLose[f.id] = 0;
			stats.defenderWin[f.id] = 0;
			stats.defenderLose[f.id] = 0;
		});

		combats.forEach(c => {
			if (c.end <= hour) {
				if (c.result === 'Attacker') {

					Object.keys(c.defender).forEach(d => {
						stats.defenderLose[d] ++;
					});

					Object.keys(c.attacker).forEach(a => {
						stats.attackerWin[a] ++;
					});
				} else {
					Object.keys(c.defender).forEach(d => {
						stats.defenderWin[d] ++;
					});

					Object.keys(c.attacker).forEach(a => {
						stats.attackerLose[a] ++;
					});
				}
			}
		});
		const factionGroups = Object.values(factions).reduce((groups, faction, index) => {
			const group = Math.floor(index / 2); 

			groups[group] = [].concat((groups[group] || []), faction); 

			return groups;
		}, []);

		return (
			<div className="status-province-large">
				<div className={`status-province-heading toggle${open}`} onClick={this.toggle}>Combat History</div>
				<div className="replay-container">
				{factionGroups.map((g, k) => (
					<div className="replay-group" key={k}>
						<div className="replay-row header">
							<div className="replay-name"></div>
							{g.map(f => (
							<div className="replay-faction" key={f.id}>
								<div className={`owner user-colour faction-${f.id}`} title={f.abbreviation}></div>
							</div>
							))}
						</div>
						<div className="replay-row">
							<div>
								<div className="replay-name">Attacker Win</div>
								{g.map(f => (
								<div className="replay-value" key={f.id}>{stats.attackerWin[f.id]}</div>
								))}
							</div>
						</div>
						<div className="replay-row">
							<div>
								<div className="replay-name">Attacker Loss</div>
								{g.map(f => (
								<div className="replay-value" key={f.id}>{stats.attackerLose[f.id]}</div>
								))}
							</div>
						</div>
						<div className="replay-row">
							<div>
								<div className="replay-name">Defender Win</div>
								{g.map(f => (
								<div className="replay-value" key={f.id}>{stats.defenderWin[f.id]}</div>
								))}
							</div>
						</div>
						<div className="replay-row">
							<div>
								<div className="replay-name">Defender Loss</div>
								{g.map(f => (
								<div className="replay-value" key={f.id}>{stats.defenderLose[f.id]}</div>
								))}
							</div>
						</div>
					</div>
				))}
				</div>
			</div>
		);
	}
}

export default CombatSummary;
