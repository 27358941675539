import React, { useState } from 'react';
import { ReactComponent as Exclamation } from '../images/exclamation.svg';
import { ReactComponent as Fuel } from '../images/fuel.svg';
import { ReactComponent as Money } from '../images/money.svg';
import { ReactComponent as Supply } from '../images/supplies.svg';
import { ReactComponent as Transfer } from '../images/transfer.svg';
import ResourceForm from './forms/resource';

const Trade = props => {
	const [ isActive, setActive ] = useState(false);
	const active = isActive ? 'active' : '';
	const { factions, user } = props;
	const users = Object.values(props.users).reduce((users, user) => {
		users[user.id] = user.faction_id;

		return users;
	}, {});
	const pendingTrades = [...user.trades].filter(t => t.status === 'pending');
	const activeTrades = [...user.trades].filter(t => t.status === 'active');
	const actionRequired = pendingTrades.find(t => t.sender_id === user.id);

	return (
		<React.Fragment>
			<div className={`mode ${active}`} onClick={() => setActive(! isActive)}>
				<Transfer />
				{actionRequired && <Exclamation className="unread" />}
			</div>		
			<div id="resource-panel" className={`mouse ${active}`}>
				<div className="section">
					<div className="header">
						<div className="column"><Money /></div>
						<div className="column"><Supply /></div>
						<div className="column"><Fuel /></div>
						<div className="column large">Faction</div>
						<div className="column small">Daily</div>
						<div className="column">&nbsp;</div>
					</div>
					<div className="row">
						<ResourceForm {...props} action="send" label="Send" />
					</div>
					<div className="row">
						<ResourceForm {...props} action="request" label="Request" />
					</div>
				</div>
				{!!pendingTrades.length && <React.Fragment>
					<h3>Pending</h3>
					<div className="section">
						<div className="header">
							<div className="column"><Money /></div>
							<div className="column"><Supply /></div>
							<div className="column"><Fuel /></div>
							<div className="column">Sender</div>
							<div className="column">Recipient</div>
							<div className="column small">Daily</div>
							<div className="column">&nbsp;</div>
						</div>
						{[...pendingTrades].filter(t => t.sender_id === user.id).map(t => <div className="row" key={t.id}>
							<div className="column">{t.money}</div>
							<div className="column">{t.supplies}</div>
							<div className="column">{t.fuel}</div>
							<div className="column">{factions[users[t.sender_id]].abbreviation}</div>
							<div className="column">{factions[users[t.recipient_id]].abbreviation}</div>
							<div className="column small">{t.daily === 1 && <span>&#x2713;</span>}</div>
							<div className="column">
								<button type="button" onClick={() => props.onAcceptTrade(t.id)}>Accept</button>
								<button type="button" onClick={() => props.onCancelTrade(t.id)}>Reject</button>
							</div>
						</div>)}
						{[...pendingTrades].filter(t => t.sender_id !== user.id).map(t => <div className="row" key={t.id}>
							<div className="column">{t.money}</div>
							<div className="column">{t.supplies}</div>
							<div className="column">{t.fuel}</div>
							<div className="column">{factions[users[t.sender_id]].abbreviation}</div>
							<div className="column">{factions[users[t.recipient_id]].abbreviation}</div>
							<div className="column small">{t.daily === 1 && <span>&#x2713;</span>}</div>
							<div className="column"><button type="button" onClick={() => props.onCancelTrade(t.id)}>Withdraw</button></div>
						</div>)}
					</div>
				</React.Fragment>}
				{!!activeTrades.length && <React.Fragment>
					<h3>Active</h3>
					<div className="section">
						<div className="header">
							<div className="column"><Money /></div>
							<div className="column"><Supply /></div>
							<div className="column"><Fuel /></div>
							<div className="column">Sender</div>
							<div className="column">Recipient</div>
							<div className="column small">Daily</div>
							<div className="column">&nbsp;</div>
						</div>
						{activeTrades.map(t => <div className="row" key={t.id}>
							<div className="column">{t.money}</div>
							<div className="column">{t.supplies}</div>
							<div className="column">{t.fuel}</div>
							<div className="column">{factions[users[t.sender_id]].abbreviation}</div>
							<div className="column">{factions[users[t.recipient_id]].abbreviation}</div>
							<div className="column small">{t.daily === 1 && <span>&#x2713;</span>}</div>
							<div className="column"><button type="button" onClick={() => props.onCancelTrade(t.id)}>Cancel</button></div>
						</div>)}
					</div>
				</React.Fragment>}
			</div>
		</React.Fragment>
	);
};

export default Trade;