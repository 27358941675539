import React from 'react';
import Joi from 'joi-browser';
import Form from '../common/form';
import GameService from '../../services/gameService';
import TheatreService from '../../services/theatreService';

class CreateForm extends Form {
	state = {
		data: {
			theatreId: '',
			factionId: '',
			enhancedCombat: false,
		},
		errors: {},
		theatres: [],
		factions: []
	};

	schema = {
		theatreId: Joi.required().label('Theatre'),
		factionId: Joi.required().label('Faction'),
		enhancedCombat: Joi.boolean().required().label('Enhanced Combat')
	}

	componentDidMount = async () => {
		const { data: theatres } = await TheatreService.all();

		this.setState({ theatres });
		this.selectTheatre('theatreId', theatres[0].id);
	};

	selectTheatre = (field, theatreId) => {
		const factions = theatreId ? this.state.theatres.find(t => t.id === parseInt(theatreId)).factions : [];
		const data = { theatreId, factionId: factions.length ? factions[0].id : '', enhancedCombat: this.state.data.enhancedCombat};

		this.setState({ data, factions });
	};

	doSubmit = async () => {
		const { data } = this.state;

		try {
			const { data: game } = await GameService.save(data);

			sessionStorage.setItem('game', JSON.stringify(game));

			this.props.history.replace('/game/view');
		} catch (e) {
			if (e.response && e.response.status === 400) {
				const errors = e.response.data;

				this.setState({ errors });
			}
		}
	};

	render() {
		const { factions, theatres } = this.state;

		return (
			<form onSubmit={this.handleSubmit}>
				<div className="content_header"><h1>Create Game</h1></div>
				<div className="content_content">
					<div className="create-title">Theatre:</div>
					<div className="create-list">
						{this.renderSelect('theatreId', null, theatres, false, this.selectTheatre)}
					</div>
					<div className="create-title">Faction:</div>
					<div className="create-list">
						{this.renderSelect('factionId', null, factions, false)}
					</div>
					<div className="create-title">Detailed Combat?</div>
					<div className="create-list">
						<input name="enhancedCombat" type="checkbox" value="1" checked={this.state.data.enhancedCombat} onChange={event => this.handleChecked(event)} />
					</div>
					<div className="create-title">{this.renderButton('Create Game')}</div>
				</div>
			</form>
		);
	}
}

export default CreateForm;
