import React from 'react';

const Countdown = ({ time, type }) => {
	const width = type === 'normal' ? 70 : 51;
	const x = width / 2;

	return <svg width={width} height="100" className="combat-position-timer-container">
		<rect className="combat-position-timer-background" width={width} height="100" />
		<circle className="combat-position-timer" r="18" cx={x} cy="42" />
		<text className="combat-position-timer-text" x={x} y="42">{time}</text>
	</svg>;
};

Countdown.defaultProps = {
	type: 'normal'
};

export default Countdown;
