import React, { Component } from 'react';

class Border extends Component {
	lastPoint = {border: null, coast: null}

	shouldComponentUpdate() {
		return false;
	};

	convertIntoPath(coordinates, type) {
		const pairs = coordinates.split(' ');
		let path = '';

		pairs.forEach((p, k) => {
			const [xPoint, yPoint] = p.split(',');
			let x = xPoint;
			let y = yPoint;

			if (this.lastPoint[type]) {
				x -= this.lastPoint[type].x;
				y -= this.lastPoint[type].y;
			}

			this.lastPoint[type] = {x: xPoint, y: yPoint};

			if (k === 0) {
				path += `m${x} ${y}`;
			} else if (x !== 0 && y !== 0) {
				path += `l${x} ${y}`;
			} else if (x !== 0) {
				path += `h${x}`;
			} else if (y !== 0) {
				path += `v${y}`;
			}
		});

		return path;
	};
	
	render() {
		const theatre = this.props.theatre;
		const paths = {border: '', coast: ''};

		theatre.mapBorder.forEach(b => {
			paths[b.type] += this.convertIntoPath(b.coordinates, b.type);
		});

		return (
			<React.Fragment>
			{Object.keys(paths).map(t => (
				<path key={t} d={paths[t]} className={t} />
			))}
			</React.Fragment>
		);
	}
}

export default Border;
