import React, { Component } from 'react';

class Area extends Component {
	lastPoint = null;

	shouldComponentUpdate() {
		return false;
	};

	convertIntoPath(coordinates) {
		const pairs = coordinates.split(' ');
		let path = '';

		pairs.forEach((p, k) => {
			const [xPoint, yPoint] = p.split(',');
			let x = xPoint;
			let y = yPoint;

			if (this.lastPoint) {
				x -= this.lastPoint.x;
				y -= this.lastPoint.y;
			}

			this.lastPoint = {x: xPoint, y: yPoint};

			if (k === 0) {
				path += `m${x} ${y}`;
			} else if (x !== 0 && y !== 0) {
				path += `l${x} ${y}`;
			} else if (x !== 0) {
				path += `h${x}`;
			} else if (y !== 0) {
				path += `v${y}`;
			}
		});

		return path;
	};
	
	render() {
		const area = this.props.area;
		let path = '';

		area.areaCoordinate.forEach(a => {
			path += this.convertIntoPath(a.coordinates);
		});

		return <path d={path} className="area" />;
	}
}

export default Area;
