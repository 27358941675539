import React from 'react';

const Path = ({ areas, army, replay }) => {
	if (! army.armyAction.length) {
		return null;
	}

	const lastAction = [...army.armyAction].pop();
	const armyArea = areas.find(a => a.id === (replay === true ? army.initial_game_area_id : army.game_area_id));
	const data = {d: `M ${armyArea.area.left_point} ${armyArea.area.top_point}`};
	
	army.armyAction.forEach((a, k) => {
		const actionArea = areas.find(area => area.id === a.target_id);

		if (k === army.armyAction.length - 1) {
			const penultimateArea = k === 0 ? armyArea : areas.find(area => area.id === army.armyAction[(k - 1)].target_id);
			const distance = Math.sqrt(Math.pow(penultimateArea.area.left_point - actionArea.area.left_point, 2) + Math.pow(penultimateArea.area.top_point - actionArea.area.top_point, 2)) - 7;
			const angle = Math.atan2(Math.abs(actionArea.area.top_point - penultimateArea.area.top_point), Math.abs(actionArea.area.left_point - penultimateArea.area.left_point));
			let x = Math.cos(angle) * distance;
			let y = Math.sin(angle) * distance;

			if (actionArea.area.left_point > penultimateArea.area.left_point) {
				x += penultimateArea.area.left_point;
			} else {
				x = penultimateArea.area.left_point - x;
			}

			if (actionArea.area.top_point > penultimateArea.area.top_point) {
				y += penultimateArea.area.top_point;
			} else {
				y = penultimateArea.area.top_point - y;
			}

			data.d += ` L ${x} ${y}`;
		} else {
			data.d += ` L ${actionArea.area.left_point} ${actionArea.area.top_point}`
		}
	});

	if (lastAction.action === 'move' || replay === true) {
		data.stroke = 'blue';
		data.markerEnd = 'url(#arrow-move)';
	} else if (lastAction.action === 'attack') {
		data.stroke = 'red';
		data.markerEnd = 'url(#arrow-attack)';
	} else if (lastAction.action === 'retreat') {
		data.stroke = 'yellow';
		data.markerEnd = 'url(#arrow-retreat)';
	} else if (lastAction.action === 'redeployment') {
		data.stroke = 'green';
		data.markerEnd = 'url(#arrow-redeployment)';
		data.strokeDasharray = 2;
	}

	return (
		<path {...data} className="army-path" />
	);
}

export default Path;
