import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import Logout from './components/logout';
import ProtectedRoute from './components/common/protectedRoute';
import Game from './components/game';
import Dashboard from './components/dashboard';
import PoleOfInaccessability from './components/poleOfInaccessability';
import Replay from './components/replay';
import LoginForm from './components/forms/loginForm';
import RegisterForm from './components/forms/registerForm';
//import './App.css';

class App extends Component {
    render() {
        return (
            <Switch>
                <Route path="/register" component={RegisterForm} />
                <Route path="/logout" component={Logout} />
                <ProtectedRoute path="/game/replay/:id" component={Replay} />
                <ProtectedRoute path="/game/view" component={Game} />
                <ProtectedRoute path="/game" component={Dashboard} />
                <ProtectedRoute path="/map/poi/:id" component={PoleOfInaccessability} />
                <Route path="/" component={LoginForm} />
            </Switch>
        );
    }
}

export default App;
