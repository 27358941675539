import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import Auth from '../../services/authService'; 

const ProtectedRoute = ({ component: Component, render, ...others }) => {
	return (
		<Route {...others} render={props => {
			if (! Auth.getUser()) {
				return <Redirect to={{pathname: '/', state: { from: props.location }}} />
			}

			return Component ? <Component {...props} /> : render(props);
		}} />
	);
}

export default ProtectedRoute;
