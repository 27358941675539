import React from 'react';
import { Link } from 'react-router-dom';

const Area = ({ areas, user }) => {
	return (
		<React.Fragment>
		{[...areas].filter(a => a.faction_id === user.faction_id).sort((a, b) => a.area.name.localeCompare(b.area.name)).map(area => (
			<Link key={area.area.id} className="status-province link" to={`/game/view/area/${area.area.id}`}>{area.area.name}</Link>
		))}
		</React.Fragment>
	);
};

export default Area;
