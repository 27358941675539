import React from 'react';
import { ReactComponent as Return } from '../../images/return.svg';

const IntelligenceView = ({ areas, borderingAreas, onExpedition, factions, match, user }) => {
	const areaId = parseInt(match.params.id);
	const factionId = parseInt(match.params.factionId);
	const area  = areas.find(a => a.area_id === areaId);
	const borderingArmies = borderingAreas.find(a => a.area_id === areaId && a.armies[factionId]);
	const faction = factions[factionId];

	if (! area || ! faction) {
		return null;
	}

	if (! borderingArmies) {
		return (
			<React.Fragment>
				<div className="status-province">
					<div className="status-province-area">Intelligence Report</div>
				</div>
				<div className="status-province-large">
					<div className="status-province-heading">{area.area.name}</div>
					<div className="status-province-list text">
						Our intelligence officers report that {faction.name} has no standing forces in this area
					</div>
				</div>
			</React.Fragment>
		);
	}

	const armies = borderingArmies.armies[factionId];
	const divisionNames = {
		armor: 'Armor', 
		artillery: 'Artillery',
		bomber: 'Bombers', 
		fighter: 'Fighters', 
		hq: 'Headquarters', 
		infantry: 'Infantry', 
		logistics: 'Logistics', 
		medical: 'Medical'
	};
	const actions = [];
	const assignedAreas = {};
	const verbs = {attack: 'Attacking', move: 'Moving to', retreat: 'Retreating to'};

	['attack', 'move', 'retreat'].forEach(m => {
		if (armies.actions[m]) {
			armies.actions[m].forEach(a => {
				if (! assignedAreas[a]) {
					const area = areas.find(area => area.id === a);

					actions.push(`${verbs[m]} ${area.area.name}`);
					assignedAreas[a] = true;
				}
			});
		}
	});

	return (
		<React.Fragment>
			<div className="status-province">
				<div className="status-province-area">Intelligence Report</div>
			</div>
			<div className="status-province-large">
				<div className="status-province-heading">{area.area.name}</div>
				<div className="status-province-list text">
					{armies.count === 1 && <span>{faction.name} has approximately {armies.count} division in this area</span>}
					{armies.count > 1 && <span>{faction.name} has approximately {armies.count} divisions in this area</span>}
				</div>
				<div className="text">
					Their forces in this area consist of the following types of divisions:
					<ul>
						{['armor', 'artillery', 'bomber', 'fighter', 'hq', 'infantry', 'logistics', 'medical'].map(t => 
							armies[t] && <li key={t}>{divisionNames[t]}</li>
						)}
					</ul>
				</div>
				<div className="text">
					{!!actions.length && <div>
						We have observed that their forces are currently performing the following actions:
						<ul>
							{actions.map((a, k) =>
								<li key={k}>{a}</li>
							)}
						</ul>
					</div>}
				</div>
			</div>
			{armies && !!armies.loaned.length && user.alliance[factionId] && <div className="army-abilities">
				<div className="army-ability" title="Return Expeditionary Force" onClick={() => onExpedition(user.faction_id, armies.loaned, 'return')}>
					<Return />
				</div>
			</div>}			
		</React.Fragment>
	);
}

export default IntelligenceView;
