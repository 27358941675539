import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import MissionForm from '../forms/missionForm';

class Airforce extends Component {
	groupAirWings(airWings) {
		const airForceAreas = [];
		const areas = this.props.areas;

		airWings.forEach(airWing => {
			if (! airForceAreas[airWing.game_area_id]) {
				let area = areas.find(a => a.id === airWing.game_area_id);

				airForceAreas[airWing.game_area_id] = {...area, airWings: []};
			}

			airForceAreas[airWing.game_area_id].airWings.push(airWing);
		});

		airForceAreas.sort((a, b) => a.area.name.localeCompare(b.area.name));

		return airForceAreas;
	};

	displayArmyCount(army, units) {
		let airWings = 0;

		army.gameUnit.forEach(u => {
			if (units[u.unit_id].unit_class_id === 5 || units[u.unit_id].unit_class_id === 6 || units[u.unit_id].unit_class_id === 10) {
				airWings ++;
			}
		});

		return `${airWings} (${army.gameUnit.length})`;
	};

	render() {
		const { airWings, areas, game, mixedArmies, units, user, onAction } = this.props;
		const airForceAreas = this.groupAirWings([...airWings, ...mixedArmies]);

		return (
			<React.Fragment>
			{airForceAreas.map(area => (
				<div className="status-army" key={area.area.id}>
					<div className="status-army-area">
						{area.area.name}
					</div>
					{[...area.airWings].sort((a, b) => a.name.localeCompare(b.name)).map(airWing => (
						<div key={airWing.id} className="status-army-container">
							<div className="status-army-name">
								<Link className="army-link link" to={`/game/view/army/${airWing.id}`}>{airWing.name}</Link>
							</div>
							<div className="status-army-count">
								{this.displayArmyCount(airWing, units)}
							</div>
							<div className="status-province-list">
								<div className="status-province-list-name">
									<div className="status-army-container"><MissionForm 
										areas={areas} 
										army={airWing} 
										game={game} 
										units={units} 
										user={user} 
										onAction={onAction} 
									/></div>
								</div>
							</div>
						</div>
					))}
				</div>
			))}
		    </React.Fragment>
		)
	}
}

export default Airforce;
