import React from 'react';

const DashboardHome = () => {
	return (
		<React.Fragment>
			<div className="content_header"><h1>Introduction</h1></div>
			<div className="content_content">
				<p>Soon</p>
			</div>
		</React.Fragment>
	);
}

export default DashboardHome;
