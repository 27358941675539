import React, { Component } from 'react';
import CombatSummary from './replay/combatSummary';
import Control from './replay/control';
import Equipment from './replay/equipment';
import EquipmentLoss from './replay/equipmentLoss';
import History from './replay/history';
import { ReactComponent as Loading } from '../images/loading.svg';
import Map from './replay/map';
import Overview from './replay/overview';
import Policy from './replay/policy';
import Resource from './replay/resource';
import Structure from './replay/structure';
import Tech from './replay/tech';
import Unit from './replay/unit';
import ReplayService from '../services/replayService';
import { formatDate } from '../utilities/date.js';

class Replay extends Component {
	state = {
		areas: null,
		armies: null,
		armyActions: null,
		armySnapshotFields: null,
		armySnapshots: null,
		combatPause: false,
		combats: null,
		counterScale: 1,
		equipment: null,
		factions: null,
		filteredHistory: null,
		game: null,
		gameHistory: null,
		gameUnits: null,
		history: null,
		historyVisibility: {},
		hour: 0,
		isActive: false,
		largeMovementIndicator: false,
		pointOfView: null,
		policies: null,
		speed: 1,
		structureDevelopments: null,
		structures: null,
		theatre: null,
		unitDevelopments: null,
		units: null,
		userSnapshotFields: null,
		users: null
	};

	playback = null;

	async componentDidMount() {
		const id = this.props.match.params.id;
		const { data } = await ReplayService.get(id);
		const { areas, armies, armyActions, armySnapshotFields, armySnapshots, combats, equipment, factions, game, gameHistory, gameUnits, history, policies, structureDevelopments, structures, theatre, unitDevelopments, units, userSnapshotFields, users } = data;
		const filteredHistory = {};
		const historyVisibility = {};

		Object.values(users).forEach(u => {
			filteredHistory[u.faction_id] = [];
			historyVisibility[u.faction_id] = true;

			u.trained = 0;
			u.training = 0;
			u.built = 0;
			u.building = 0;
			u.tech = {
				infantryArmor: {level: 0, active: false},
				infantryWeapons: {level: 0, active: false},
				vehicleArmor: {level: 0, active: false},
				vehicleWeapons: {level: 0, active: false},
				aircraftArmor: {level: 0, active: false},
				aircraftWeapons: {level: 0, active: false},
				superweapon: {level: 0, active: false}
			};
			u.vision = this.calculateVision(areas, u);
		});

		areas.forEach(a => {
			a.actions = {attack: {}, move: {}, retreat: {}};
			a.history = [];
		});

		armies.forEach(a => {
			a.armyAction = [];
			a.gameUnit = [];
			a.faction_id = a.initial_faction_id;
			a.game_area_id = a.initial_game_area_id;
		});

		gameUnits.forEach(gu => {
			if (gu.initial === 1) {
				const army = armies.find(a => a.id === gu.initial_army_id);

				army.gameUnit.push(gu);
			}
		});

		this.setState({ 
			areas, 
			armies,
			armyActions,
			armySnapshotFields,
			armySnapshots,
			combats, 
			equipment, 
			factions, 
			filteredHistory, 
			game, 
			gameHistory, 
			gameUnits,
			history, 
			historyVisibility,
			policies,
			structureDevelopments,
			structures, 
			theatre, 
			unitDevelopments,
			units, 
			userSnapshotFields,
			users 
		});
	};

	componentWillUnmount() {
		clearInterval(this.playback);
	};

	calculateVision(areas, user) {
		const filteredAreas = [...areas].filter(a => !user.alliance[a.faction_id]);
		const data = {};

		if (user.spySatellite) {
			filteredAreas.forEach(a => {
				data[a.id] = true;
			});

			return data;
		}

		filteredAreas.forEach(a => {
			a.area.border.forEach(b => {
				const borderArea = areas.find(area => area.area_id === b.area_2_id && user.alliance[area.faction_id]);

				if (borderArea) {
					data[a.id] = true;
				}
			})
		});

		if (user.intelligence_policy_id === 2) {
			const borderAreas = [...areas].filter(a => user.alliance[a.faction_id] && data[a.id] === true);

			borderAreas.forEach(a => {
				a.area.border.forEach(b => {
					const borderArea = areas.find(area => area.area_id === b.area_2_id);

					if (borderArea) {
						data[borderArea.id] = true;
					}
				})
			});
		}

		return data;
	};

	changeDate = (game, hour) => {
		const date = new Date(Date.UTC(2020, 0, 1));

		date.setHours(hour);

		game.date = formatDate(date);
	};

	update = (areas, armies, filteredHistory, hour, users) => {
		const { armyActions, combats, gameHistory, gameUnits, history, structureDevelopments, structures, unitDevelopments, units } = this.state;
		let index = null;

		if (armyActions[hour]) {
			armyActions[hour].end.forEach(a => {
				if (['move', 'attack', 'redeployment', 'retreat'].includes(a.action)) {
					const actionArmy = armies.findIndex(army => army.id === a.army_id);
					const actionArea = areas.findIndex(area => area.id === armies[actionArmy].game_area_id);

					armies[actionArmy].armyAction.push({
						action: a.action, 
						target_id: a.target_id
					});

					if (a.action !== 'redeployment') {
						delete areas[actionArea].actions[a.action][armies[actionArmy].id];
					}
				}
			});
		}

		if (structureDevelopments[hour]) {
			structureDevelopments[hour].start.forEach(d => {
				users[d.faction_id].building ++;
			});

			structureDevelopments[hour].end.forEach(d => {
				users[d.faction_id].building --;
			});
		}

		if (unitDevelopments[hour]) {
			unitDevelopments[hour].start.forEach(d => {
				users[d.faction_id].training ++;
			});

			unitDevelopments[hour].end.forEach(d => {
				users[d.faction_id].training --;
			});
		}

		if (gameHistory[hour]) {
			gameHistory[hour].forEach(h => {
				filteredHistory[h.faction_id] = [h].concat(filteredHistory[h.faction_id]).slice(0, 99);

				switch (history[h.history_id]) {
					case 'their_province_occupation':
						index = areas.findIndex(a => a.id === h.history_values.area_id);

						if (index !== -1) {
							areas[index].faction_id = h.faction_id;
							areas[index].history.push({record: 'Occupied', faction: h.faction_id, date: formatDate(h.history_date, true, true)});

							Object.values(users).forEach(u => {
								u.vision = this.calculateVision(areas, u);
							});
						}
					break;
					case 'structure_complete':
						index = areas.findIndex(a => a.id === h.history_values.area_id);

						if (index !== -1) {
							if (! areas[index].gameStructure[h.history_values.structure_id]) {
								areas[index].gameStructure[h.history_values.structure_id] = 0;
							}

							areas[index].gameStructure[h.history_values.structure_id] ++;
							areas[index].history.push({record: `${structures[h.history_values.structure_id]} Complete`, faction: h.faction_id, date: formatDate(h.history_date, true, true)});
							users[areas[index].faction_id].built ++;
						}
					break;
					case 'army_arrive':
						index = armies.findIndex(a => a.id === h.history_values.army_id);

						if (index !== -1) {
							armies[index].game_area_id = h.history_values.area_id;
						}
					break;
					case 'army_unit_complete':
						let unit = gameUnits.find(u => u.initial_army_id === h.history_values.army_id);
						let areaIndex = areas.findIndex(a => a.id === h.history_values.area_id);

						index = armies.findIndex(a => a.id === h.history_values.army_id);

						if (unit && index !== -1 && areaIndex !== -1) {
							areas[areaIndex].history.push({record: `${units[h.history_values.unit_id].name} Complete`, faction: h.faction_id, date: formatDate(h.history_date, true, true)});
							armies[index].gameUnit = [unit];
							users[armies[index].faction_id].trained ++;
						}
					break;
					case 'artillery_barrage_attack':
					case 'army_combat_attack':
					case 'airforce_bombing_attack':
						let combat = combats.find(c => c.id === h.history_values.battle_id);

						index = areas.findIndex(a => a.id === h.history_values.area_id);

						if (index !== -1 && combat && combat.start_time !== combat.end_time) {
							areas[index].combat = h.history_values.battle_id;
							areas[index].history.push({record: combat.battle_name, faction: Object.keys(combat.attacker)[0], date: combat.start_time});

							if (this.state.combatPause) {
								this.handlePause();
							}
						}
					break;
					case 'army_combat_win':
					case 'army_combat_retreat':
					case 'army_combat_loss':
					case 'artillery_combat_win':
					case 'artillery_combat_retreat':
					case 'artillery_combat_repel':
					case 'artillery_combat_lost':
					case 'airforce_combat_won':
					case 'airforce_combat_lost':
						index = areas.findIndex(a => a.id === h.history_values.area_id);

						if (index !== -1) {
							areas[index].combat = false;
						}
					break;
					case 'army_unit_destroyed':
						index = armies.findIndex(a => a.id === h.history_values.army_id);

						if (index !== -1) {
							armies[index].gameUnit.filter(u => u.id !== h.history_values.unit_id);
						}
					break;
					case 'army_supply_annihilated':
					case 'army_combat_annihilated':
					case 'army_disbanded':
						index = armies.findIndex(a => a.id === h.history_values.army_id);

						if (index !== -1) {
							armies[index].gameUnit = [];
						}
					break;
					case 'army_disband_merge':
						let newArmyIndex = armies.findIndex(a => a.id === h.history_values.new_army_id);

						index = armies.findIndex(a => a.id === h.history_values.army_id);

						if (newArmyIndex !== -1 && index !== -1) {
							armies[newArmyIndex].gameUnit = [...armies[newArmyIndex].gameUnit, ...armies[index].gameUnit];
							armies[index].gameUnit = [];
						}
					break;
					case 'army_create_split':
						let oldArmyIndex = armies.findIndex(a => a.id === h.history_values.old_army_id);

						index = armies.findIndex(a => a.id === h.history_values.army_id);

						if (oldArmyIndex !== -1 && index !== -1) {
							let units = String(h.history_values.unit_ids).split(',').map(u => parseInt(u));

							armies[index].gameUnit = [...armies[oldArmyIndex].gameUnit].filter(u => units.indexOf(u.id) !== -1);
							armies[oldArmyIndex].gameUnit = armies[oldArmyIndex].gameUnit.filter(u => units.indexOf(u.id) === -1);
						}
					break;
					case 'tech_start':
					case 'tech_pause':
						let tech = h.history_values.tech_name.replace(/-([a-z])/g, m => m[1].toUpperCase());

						if (tech.indexOf('superweapon') !== -1) {
							tech = 'superweapon';
						}

						users[h.faction_id].tech[tech].active = ! users[h.faction_id].tech[tech].active;
					break;
					case 'tech_complete':
						let completeTech = h.history_values.tech_name.replace(/-([a-z])/g, m => m[1].toUpperCase());

						if (completeTech.indexOf('superweapon') !== -1) {
							completeTech = 'superweapon';
						}

						users[h.faction_id].tech[completeTech].level ++;
						users[h.faction_id].tech[completeTech].active = false;
					break;
					case 'area_destroyed':
						index = areas.findIndex(a => a.id === h.history_values.area_id);

						if (index !== -1) {
							areas[index].gameStructure = [];
							areas[index].history.push({record: 'Area Destroyed', faction: h.faction_id, date: formatDate(h.history_date, true, true)});
						}
					break;
					case 'ability_use':
						users[h.faction_id].spySatellite = true;
						users[h.faction_id].vision = this.calculateVision(areas, users[h.faction_id]);
					break;
					case 'ability_end':
						users[h.faction_id].spySatellite = false;
						users[h.faction_id].vision = this.calculateVision(areas, users[h.faction_id]);
					break;
					case 'province_transfer':
						index = areas.findIndex(a => a.id === h.history_values.area_id);

						if (index !== -1) {
							areas[index].faction_id = h.faction_id;
							areas[index].history.push({record: 'Transferred Ownership', faction: h.faction_id, date: formatDate(h.history_date, true, true)});
						}
					break;
					case 'game_user_quit':
						if (h.history_values.inherit_id) {
							areas.forEach((a, k) => {
								if (a.faction_id === h.history_values.faction_id) {
									areas[k].faction_id = h.history_values.inherit_id;
									areas[k].history.push({record: 'Inherited', faction: h.history_values.inherit_id, date: formatDate(h.history_date, true, true)});
								}
							});

							armies.forEach((a, k) => {
								if (a.faction_id === h.history_values.faction_id) {
									armies[k].faction_id = h.history_values.inherit_id;
								}
							});
						}
					break;
					default:
					break;
				}
			});
		}

		if (armyActions[hour]) {
			armyActions[hour].start.forEach(a => {
				if (['move', 'attack', 'retreat'].includes(a.action)) {
					const actionArmy = armies.find(army => army.id === a.army_id);
					const actionArea = areas.findIndex(area => area.id === actionArmy.game_area_id);
					
					areas[actionArea].actions[a.action][actionArmy.id] = {target_id: a.target_id, faction_id: actionArmy.faction_id};
				}
			});
		}
	};

	setPlayback = speed => {
		this.playback = setInterval(() => {
			const hour = this.state.hour + 1;
			const { areas, armies, filteredHistory, game, users } = this.state;

			this.changeDate(game, hour);
			this.update(areas, armies, filteredHistory, hour, users);
			this.setState({ areas, armies, filteredHistory, hour, game, users });

			if (hour === game.length) {
				this.handlePause();

				return;
			}
		}, 100 / speed);
	};

	handlePlay = () => {
		if (this.state.hour === this.state.game.length) {
			return;
		}

		this.setPlayback(this.state.speed);
		this.setState({ isActive: true });
	};

	handlePause = () => {
		if (this.playback) {
			clearInterval(this.playback);
		};

		this.setState({ isActive: false });
	};

	handleDateChange = hour => {
		if (this.playback) {
			clearInterval(this.playback);
		};

		const { areas, armies, filteredHistory, game, hour: gameHour, gameUnits, users } = this.state;

		if (hour > gameHour) {
			for (let x = gameHour + 1; x <= hour; x++) {
				this.update(areas, armies, filteredHistory, x, users);
			}
		} else if (hour < gameHour) {
			areas.forEach(a => {
				a.actions = {attack: {}, move: {}, retreat: {}};
				a.combat = false;
				a.faction_id = a.area.faction_id;
				a.gameStructure = Object.keys(a.area.gameStructure).length ? {...a.area.gameStructure} : [];
				a.history = [];
			});

			armies.forEach(a => {
				a.armyAction = [];
				a.gameUnit = [];
				a.faction_id = a.initial_faction_id;
				a.game_area_id = a.initial_game_area_id;
			});

			gameUnits.forEach(gu => {
				if (gu.initial === 1) {
					const army = armies.find(a => a.id === gu.initial_army_id);

					army.gameUnit.push(gu);
				}
			});

			Object.values(users).forEach(u => {
				filteredHistory[u.faction_id] = [];

				u.trained = 0;
				u.training = 0;
				u.built = 0;
				u.building = 0;
				u.tech = {
					infantryArmor: {level: 0, active: false},
					infantryWeapons: {level: 0, active: false},
					vehicleArmor: {level: 0, active: false},
					vehicleWeapons: {level: 0, active: false},
					aircraftArmor: {level: 0, active: false},
					aircraftWeapons: {level: 0, active: false},
					superweapon: {level: 0, active: false}
				};
				u.vision = this.calculateVision(areas, u);
			});

			for (let x = 0; x <= hour; x++) {
				this.update(areas, armies, filteredHistory, x, users);
			}
		}

		this.changeDate(game, hour);
		this.setState({ areas, filteredHistory, hour, users });
	};

	handleSpeedChange = speed => {
		if (this.state.isActive) {
			clearInterval(this.playback);

			this.setPlayback(speed);
		}

		this.setState({ speed: speed });
	};

	handleCombatPause = state => {
		this.setState({ combatPause: state });
	};

	handleMovementIndicatorChange = state => {
		this.setState({ largeMovementIndicator: state });
	};

	handleCounterScaleChange = size => {
		this.setState({ counterScale: size });
	};

	handlePointOfViewChange = faction => {
		this.setState({ pointOfView: faction });
	};

	handleHistoryVisibilityChange = faction => {
		const historyVisibility = this.state.historyVisibility;

		historyVisibility[faction] = !historyVisibility[faction];

		this.setState({ historyVisibility });
	}

	render() {
		const { areas, armies, armySnapshotFields, armySnapshots, combatPause, combats, equipment, factions, filteredHistory, game, historyVisibility, hour, isActive, largeMovementIndicator, pointOfView, policies, speed, structures, theatre, units, userSnapshotFields, users } = this.state;

		if (! game) {
			return <Loading className="loader" />;
		}

		return (
			<div id="game" className="replay">
				<div id="status-container">
					<div id="status-list">
						<Overview areas={areas} armies={armies} factions={factions} users={users} />
						<Policy factions={factions} policies={policies} users={users} />
						<Resource factions={factions} hour={hour} users={users} userSnapshotFields={userSnapshotFields} />
						<Structure areas={areas} factions={factions} structures={structures} />
						<Unit armies={armies} factions={factions} units={units} />
						<Tech factions={factions} users={users} />
						<Equipment armySnapshotFields={armySnapshotFields} armySnapshots={armySnapshots} factions={factions} hour={hour} />
						<EquipmentLoss combats={combats} equipment={equipment} factions={factions} hour={hour} />
						<CombatSummary combats={combats} factions={factions} hour={hour} />
					</div>
				</div>
				<Map 
					areas={areas}
					armies={armies}
					armySnapshotFields={armySnapshotFields}
					armySnapshots={armySnapshots}
					combats={combats}
					equipment={equipment}
					factions={factions}
					game={game}
					hour={hour}
					largeMovementIndicator={largeMovementIndicator}
					pointOfView={pointOfView}
					structures={structures}
					theatre={theatre}
					units={units}
					users={users}
				/>
				<Control 
					combatPause={combatPause}
					factions={factions}
					game={game} 
					hour={hour} 
					isActive={isActive} 
					speed={speed}
					onCombatPause={this.handleCombatPause}
					onCounterScaleChange={this.handleCounterScaleChange}
					onDateChange={this.handleDateChange}
					onHistoryVisibilityChange={this.handleHistoryVisibilityChange}
					onMovementIndicatorChange={this.handleMovementIndicatorChange}
					onPointOfViewChange={this.handlePointOfViewChange}
					onSpeedChange={this.handleSpeedChange}
					onPause={this.handlePause} 
					onPlay={this.handlePlay} 
				/>
				<div id="history-container-outer">
					{Object.values(users).map(u => historyVisibility[u.faction_id] && <History key={u.id} history={filteredHistory[u.faction_id]} id={u.faction_id} />)}
				</div>
			</div>
		);
	}	
}

export default Replay;
