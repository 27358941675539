import http from './httpService';

function allStructures() {
	return http.get('/structures');
}

function saveStructure(structure) {
	if (structure.id) {
		const data = { ...structure };

		delete data.id;

		return http.put('/structures/' + structure.id, data);
	}

	return http.post('/structures/', structure);
}

function getStructure(structureId, areaId) {
	return http.get(`/structures/${structureId}/${areaId}`);
}

export default {
	all: allStructures,
	save: saveStructure,
	get: getStructure
};
