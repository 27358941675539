import React, { Component } from 'react';
import service from '../../services/factionService';

class Faction extends Component {
	state = {
		factions: []
	};

	componentDidMount = async () => {
		const { data: factions } = await service.all();

		this.setState({ factions });
	};

	render() {
		const { factions } = this.state;

		return (
			<React.Fragment>
				<div className="content_header"><h1>Factions</h1></div>
				<div className="content_content">
				{factions.map(faction => (
					<div key={faction.id} className="sub_content">
						<div className="sub_content_heading">{faction.name} ({faction.abbreviation})</div>
						<p>{faction.description}</p>
						<p>Actively Fighting In: {faction.theatres.join(', ')}</p>
					</div>
				))}
				</div>
			</React.Fragment>
		);
	}
}

export default Faction;
