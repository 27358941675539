import React, { Component } from 'react';

class Combat extends Component {
	shouldComponentUpdate(nextProps, nextState) {
		return this.props.id !== nextProps.id;
	};

	displayDuration(combat) {
		const days = Math.ceil((combat.end - combat.start) / 24);

		if (days === 1) {
			return `${combat.end_time}`;
		}

		return `${combat.start_time} - ${combat.end_time} (${days} Days)`;
	};

	displayResult(combat) {
		const factions = this.props.factions;
		const victors = [];

		if (combat.result === 'Attacker') {
			Object.values(combat.attacker).forEach(a => {
				let faction = factions.find(f => f.id === a);

				victors.push(faction.adjective);
			});
		} else {
			let faction = factions.find(f => f.id === combat.defender);

			victors.push(faction.adjective);
		}

		return `${victors.join(', ')} victory`;
	};

	render() {
		const { areas, armies, armySnapshotFields, armySnapshots, combats, equipment, id } = this.props;
		const combat = combats.find(c => c.id === parseInt(id));
		const area = areas.find(a => a.id === combat.game_area_id);
		const capitalize = s => s[0].toUpperCase() + s.slice(1);
		const factions = this.props.factions.reduce((map, faction) => {
			map[faction.id] = faction;

			return map;
		}, {});
		const armyGroups = combat.battleArmy.reduce((group, battleArmy) => {
			const army = armies.find(a => a.id === battleArmy.army_id);

			if (! group[battleArmy.side][battleArmy.faction_id]) {
				group[battleArmy.side][battleArmy.faction_id] = [];
			}

			group[battleArmy.side][battleArmy.faction_id].push(army.name);

			return group;
		}, {attacker: {}, defender: {}});
		const armyEquipment = combat.battleArmy.reduce((group, battleArmy) => {
			const snapshot = armySnapshots[battleArmy.join][battleArmy.army_id];

			if (snapshot) {
				['men', 'aircraft', 'guns', 'tanks', 'vehicles'].forEach(e => {
					if (snapshot[armySnapshotFields[e]] > 0) {
						if (! group[battleArmy.side][battleArmy.faction_id]) {
							group[battleArmy.side][battleArmy.faction_id] = {};
						}

						if (! group[battleArmy.side][battleArmy.faction_id][e]) {
							group[battleArmy.side][battleArmy.faction_id][e] = 0;
						}

						group[battleArmy.side][battleArmy.faction_id][e] += snapshot[armySnapshotFields[e]];
					}
				});
			}

			return group;
		}, {attacker: {}, defender: {}});
		const armyLosses = combat.equipmentLoss.reduce((group, loss) => {
			const side = combat.attacker[loss.faction_id] ? 'attacker' : 'defender';

			if (! group[side][loss.faction_id]) {
				group[side][loss.faction_id] = {};
			}

			if (! group[side][loss.faction_id][equipment[loss.equipment_id]]) {
				group[side][loss.faction_id][equipment[loss.equipment_id]] = 0;
			}

			group[side][loss.faction_id][equipment[loss.equipment_id]] += loss.value;

			return group;
		}, {attacker: {}, defender: {}});

		return (
			<div id="side-container">
				<div className="title">{combat.battle_name}</div>
				<div className="overview">
					<div className="row">
						<div className="text">Date</div>
						<div className="value">{this.displayDuration(combat)}</div>
					</div>
					<div className="row">
						<div className="text">Location</div>
						<div className="value">{area.area.name}, {area.area.region.name}</div>
					</div>
					<div className="row">
						<div className="text">Result</div>
						<div className="value">{this.displayResult(combat)}</div>
					</div>
				</div>
				<div className="title">Belligerents</div>
				<div className="overview">
					<div className="column">
						{Object.values(combat.attacker).map(s => (
							<div className="faction-row" key={s}>
								<div className={`owner user-colour faction-${factions[s].id}`}></div>{factions[s].name}
							</div>
						))}
					</div>
					<div className="column">
						{Object.values(combat.defender).map(s => (
							<div className="faction-row" key={s}>
								<div className={`owner user-colour faction-${factions[s].id}`}></div>{factions[s].name}
							</div>
						))}
					</div>
				</div>
				<div className="title">Units Involved</div>
				<div className="overview">
					<div className="column">
						{Object.keys(armyGroups.attacker).map(s => (
							<div className="faction-row" key={s}>
								<div className={`owner user-colour faction-${factions[s].id}`}></div>{factions[s].name}
								<ul>
									{armyGroups.attacker[s].map((a, k) => <li key={k}>{a}</li>)}
								</ul>
							</div>
						))}
					</div>
					<div className="column">
						{Object.keys(armyGroups.defender).map(s => (
							<div className="faction-row" key={s}>
								<div className={`owner user-colour faction-${factions[s].id}`}></div>{factions[s].name}
								<ul>
									{armyGroups.defender[s].map((a, k) => <li key={k}>{a}</li>)}
								</ul>
							</div>
						))}
					</div>
				</div>
				<div className="title">Strength</div>
				<div className="overview">
					<div className="column">
						{combat.max_attacker_size} Divisions<br /><br />
						{Object.keys(armyEquipment.attacker).map(s => (
							<div className="faction-row" key={s}>
								<div className={`owner user-colour faction-${factions[s].id}`}></div>{factions[s].name}
								<div className="losses">
									{Object.keys(armyEquipment.attacker[s]).map(a => (
										<div className="loss" key={a}>{capitalize(a)}: {Math.round(armyEquipment.attacker[s][a])}</div>
									))}
								</div>
							</div>
						))}
					</div>
					<div className="column">
						{combat.max_defender_size} Divisions<br /><br />
						{Object.keys(armyEquipment.defender).map(s => (
							<div className="faction-row" key={s}>
								<div className={`owner user-colour faction-${factions[s].id}`}></div>{factions[s].name}
								<div className="losses">
									{Object.keys(armyEquipment.defender[s]).map(a => (
										<div className="loss" key={a}>{capitalize(a)}: {Math.round(armyEquipment.defender[s][a])}</div>
									))}
								</div>
							</div>
						))}
					</div>
				</div>
				<div className="title">Losses</div>
				<div className="overview">
					<div className="column">
						{Object.keys(armyLosses.attacker).map(s => (
							<div className="faction-row" key={s}>
								<div className={`owner user-colour faction-${factions[s].id}`}></div>{factions[s].name}
								<div className="losses">
									{Object.keys(armyLosses.attacker[s]).map(a => <div className="loss" key={a}>{a}: {Math.round(armyLosses.attacker[s][a])}</div>)}
								</div>
							</div>
						))}
					</div>
					<div className="column">
						{Object.keys(armyLosses.defender).map(s => (
							<div className="faction-row" key={s}>
								<div className={`owner user-colour faction-${factions[s].id}`}></div>{factions[s].name}
								<div className="losses">
									{Object.keys(armyLosses.defender[s]).map(a => <div className="loss" key={a}>{a}: {Math.round(armyLosses.defender[s][a])}</div>)}
								</div>
							</div>
						))}
					</div>
				</div>
			</div>
		);
	}	
}

export default Combat;
