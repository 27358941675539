import React from 'react';

const ArmyCombatSummary = ({ areas, army, combats, hour }) => {
	const data = {regions: {}, combats: []};

	combats.forEach(c => {
		if (c.start <= hour && c.max_attacker_size > 0 && c.max_defender_size > 0) {
			c.battleArmy.forEach(b => {
				if (b.army_id === army.id) {
					const area = areas.find(a => a.id === c.game_area_id);

					data.regions[area.area.region.name] = true;
					data.combats.push(c.battle_name);
				}
			});
		}
	});

	if (data.combats.length === 0) {
		return 'No recorded history';
	}

	return (
		<React.Fragment>
			{Object.keys(data.regions).map(r => <div key={r}>{r}</div>)}
			<ul>
				{data.combats.map((c, k) => <li key={k}>{c}</li>)}
			</ul>
		</React.Fragment>
	);
}

export default ArmyCombatSummary;
