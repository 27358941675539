import React, { Component } from 'react';
import { Switch, Route, NavLink } from 'react-router-dom';
import AirForceList from './list/airforce';
import AreaList from './list/area';
import AreaView from './view/area';
import ArmyList from './list/army';
import ArmyView from './view/army';
import Combat from './combat/view';
import CombatActive from './combat/active';
import CombatHistoryList from './list/combatHistory';
import CombatHistoryView from './view/combatHistory';
import CombatList from './list/combat';
import CombatView from './view/combat';
import Connect from './connect';
import IntelligenceView from './view/intelligence';
import PolicyForm from './forms/policy';
import RegionView from './view/region';
import StatsView from './view/stats';
import StructureList from './list/structure';
import StructureCreateView from './view/structureCreate';
import StructureView from './view/structure';
import TechView from './view/tech';
import UnitList from './list/unit';
import UnitCreateView from './view/unitCreate';
import UnitView from './view/unit';
import History from './history';
import { ReactComponent as Loading } from '../images/loading.svg';
import Map from './map';
import Overview from './overview';
import UserOverview from './userOverview';
import Socket from '../services/socketService';
import '../game.css';
import '../1.css';

class Game extends Component {
	state = {
		connected: false,
		reconnecting: false,
		reconnectTime: 0,
		game: null,
		fullscreen: false,
		highlightedAreas: null,
		activeAbility: null,
		areas: [],
		armies: [],
		borderingAreas: [],
		combats: [],
		completedCombats: [],
		equipment: [],
		factions: [],
		history: [],
		pings: [],
		policies: [],
		selection: {},
		selectionType: null,
		structures: [],
		theatre: [],
		units: [],
		user: {},
		users: []
	};

	reconnect = null;

	constructor(props) {
		super(props);

		this.game = React.createRef();
	}

	componentDidMount() {
		this.initSocket();
	};

	componentWillUnmount() {
		clearInterval(this.reconnect);

		Socket.disconnect(1000);
	};

	initSocket() {
		Socket.connect({
			onopen: () => {
				clearInterval(this.reconnect);
			},
			onmessage: (data) => {
				if (! data.type) {
					return;
				}

				console.log(data);

				switch (data.type) {
					case 'load':
						const { 
							areas, 
							armies, 
							borderingAreas, 
							combats, 
							completedCombats,
							equipment,
							factions, 
							game, 
							history, 
							policies, 
							structures, 
							theatre, 
							units, 
							user, 
							users 
						} = data.body;

						if (game.closed || user.status === 'defeated' || user.status === 'left') {
							sessionStorage.removeItem('game');

							window.location.replace('/game');

							return;
						}

						this.setState({ 
							areas, 
							armies, 
							borderingAreas, 
							combats, 
							completedCombats,
							connected: true, 
							equipment,
							factions,
							game, 
							history, 
							policies, 
							reconnecting: false,
							structures, 
							theatre, 
							units,
							user, 
							users
						});

						console.log(this.state);
					break;
					case 'update':
						const { add, remove, update } = data.body;
						let areaState = [...this.state.areas];
						let armyState = [...this.state.armies];
						let borderingAreasState = [...this.state.borderingAreas];
						let combatState = [...this.state.combats];
						let completedCombatState = [...this.state.completedCombats];
						let gameState = {...this.state.game};
						let historyState = [...this.state.history];
						let pingsState = {};
						let userState = {...this.state.user};
						let usersState = {...this.state.users};

						if (update.game) {
							Object.keys(update.game).forEach(k => {
								gameState[k] = update.game[k];
							});
						}

						if (update.user) {
							Object.keys(update.user).forEach(k => {
								userState[k] = update.user[k];
							});
						}

						if (update.users) {
							update.users.forEach(v => {
								usersState[v.faction_id] = v;
							});
						}

						if (add.armies) {
							armyState = armyState.concat(add.armies);
						}

						if (add.areas) {
							areaState = areaState.concat(add.areas);
						}

						if (add.completedCombats) {
							completedCombatState = add.completedCombats.concat(completedCombatState);
							combatState = [];
						}

						if (add.history) {
							historyState = add.history.concat(historyState);
						}

						if (remove.armies) {
							Object.keys(remove.armies).forEach(k => {
								let removeIndex = armyState.findIndex(a => a.id === parseInt(k));

								armyState.splice(removeIndex, 1);
							});
						}

						if (update.areas) {
							update.areas.forEach((v, k) => {
								let updateIndex = areaState.findIndex(a => a.id === v.id);

								Object.keys(v).forEach(key => {
									areaState[updateIndex][key] = v[key];
								});
							});
						}

						if (update.armies) {
							update.armies.forEach((v, k) => {
								let updateIndex = armyState.findIndex(a => a.id === v.id);

								Object.keys(v).forEach(key => {
									armyState[updateIndex][key] = v[key];
								});
							});
						}

						if (update.battles) {
							combatState = update.battles;
						}

						if (update.borderingAreas) {
							borderingAreasState = update.borderingAreas;
						}

						if (update.pings) {
							pingsState = update.pings;
						}

						if (userState.status === 'left' || userState.status === 'defeated' || userState.status === 'won') {
							sessionStorage.removeItem('game');
						}

						this.setState({ 
							areas: areaState, 
							armies: armyState, 
							borderingAreas: borderingAreasState, 
							combats: combatState,
							completedCombats: completedCombatState,
							game: gameState, 
							history: historyState,
							pings: pingsState,
							user: userState,
							users: usersState
						});
					break;
					default:

					break;
				}
			},
			onclose: () => {
				clearInterval(this.reconnect);

				this.reconnect = setInterval(() => {
					const reconnectTime = this.state.reconnectTime - 1;

					this.setState({ reconnectTime });

					if (reconnectTime === 0) {
						this.initSocket();

						clearInterval(this.reconnect);
					}
				}, 1000);

				this.setState({ connected: false, reconnecting: true, reconnectTime: 5 });
			}
		});
	};

	handleNewStructure = data => {
		const index = this.state.areas.findIndex(a => a.id === data.area_id);

		if (index === -1) {
			return;
		}

		Socket.send({
			action: 'new-structure',
			data
		});
	};

	handleNewUnit = data => {
		const areas = [...this.state.areas];
		const index = areas.findIndex(a => a.id === data.area_id);

		if (index === -1) {
			return;
		}

		Socket.send({
			action: 'new-unit',
			data
		});
	};

	handlePriorityChange = (areaId, recordId, type, direction) => {
		const areas = [...this.state.areas];
		const index = areas.findIndex(a => a.id === areaId);

		if (index === -1) {
			return;
		}

		if (type === 'structure') {
			const data = {id: recordId, area_id: areaId, direction};

			Socket.send({
				action: 'structure-priority-change',
				data
			});
		} else if (type === 'unit') {
			const data = {id: recordId, area_id: areaId, direction};

			Socket.send({
				action: 'unit-priority-change',
				data
			});
		}
	};

	handleCancel = (areaId, recordId, type) => {
		const areas = [...this.state.areas];
		const index = areas.findIndex(a => a.id === areaId);

		if (index === -1) {
			return;
		}

		if (type === 'structure') {
			const data = {id: recordId, area_id: areaId};

			Socket.send({
				action: 'structure-cancel',
				data
			});
		} else if (type === 'unit') {
			const data = {id: recordId, area_id: areaId};

			Socket.send({
				action: 'unit-cancel',
				data
			});
		}
	};

	handleArmyAction = data => {
		Socket.send({
			action: 'army-action',
			data
		});
	};

	handleArmyRename = (army, name) => {
		if (army.name === name || ! name) {
			return;
		}

		Socket.send({
			action: 'army-rename',
			data: {id: army.id, name}
		});
	};

	handleArmySplit = data => {
		if (! data.splitTo.length) {
			return;
		}

		Socket.send({
			action: 'army-split',
			data
		});
	};

	handleArmyMerge = data => {
		Socket.send({
			action: 'army-merge',
			data
		});
	};

	handleSelection = (data, changeView = false) => {
		this.setState({ selection: data });

		if (changeView) {
			this.props.history.push('/game/view/army/selection');
		}
	};

	handleReady = () => {
		Socket.send({
			action: 'user-ready',
			data: []
		});
	};

	handlePause = () => {
		Socket.send({
			action: 'user-pause',
			data: []
		});
	};

	handleQuit = () => {
		Socket.send({
			action: 'user-quit',
			data: []
		});

		sessionStorage.removeItem('game');

		this.props.history.replace('/game');
	};

	handleHighlightAreas = areas => {
		this.setState({ highlightedAreas: areas });
	};

	handlePolicySelection = data => {
		Socket.send({
			action: 'policy-select',
			data
		});
	};

	handleAbilityUsage = (ability, targetId = null) => {
		const data = {ability, targetId};

		Socket.send({
			action: 'ability-usage',
			data
		});
	};

	handleArmyExpedition = (factionId, expeditionId = null, action = 'loan') => {
		const data = { action, factionId, expeditionId };

		Socket.send({
			action: 'army-expedition',
			data
		});
	};

	handleAreaTransfer = (factionId, areaId) => {
		const data = { factionId, areaId };

		Socket.send({
			action: 'area-transfer',
			data
		});
	};

	handleAbilityActivate = ability => {
		this.setState({ activeAbility: ability });
	};

	handleAbortConnect = () => {
		clearInterval(this.reconnect);
	};

	handleSendMapPing = (x, y) => {
		const user = {...this.state.user};
		const id = Math.floor(Math.random() * 1000000);

		user.pings.push({id, x, y, duration: 4});

		this.setState({ user });

		Socket.send({
			action: 'map-ping',
			data: {id, x, y}
		});
	};

	handleSendMapPath = path => {
		const user = {...this.state.user};
		const id = Math.floor(Math.random() * 1000000);

		user.paths.push({id, user: user.faction_id, path, duration: 4});

		this.setState({ user });

		Socket.send({
			action: 'map-path',
			data: { id, path }
		});
	};

	handleTrade = data => {
		Socket.send({
			action: 'create-trade',
			data
		});
	};

	handleCancelTrade = id => {
		Socket.send({
			action: 'cancel-trade',
			data: { id }
		});
	};

	handleAcceptTrade = id => {
		Socket.send({
			action: 'accept-trade',
			data: { id }
		});
	};

	handleMoveUnit = (battleId, id, lineId) => {
		Socket.send({
			action: 'move-unit',
			data: { battleId, id, lineId }
		});
	};

	handleAttackUnit = (battleId, id, lineId) => {
		Socket.send({
			action: 'attack-unit',
			data: { battleId, id, lineId }
		});
	};

	handleFullscreenToggle = fullscreen => {
		if (! document.fullscreenEnabled) {
			return;
		}

		if (fullscreen) {
			this.game.current.requestFullscreen().then(() => this.setState({ fullscreen: fullscreen }));
		} else {
			document.exitFullscreen().then(() => this.setState({ fullscreen: fullscreen }));
		}
	}

	render() {
		const { 
			activeAbility, 
			areas, 
			armies, 
			borderingAreas, 
			combats, 
			completedCombats,
			connected, 
			equipment,
			factions, 
			fullscreen,
			game, 
			highlightedAreas, 
			history, 
			pings, 
			policies, 
			reconnecting,
			reconnectTime,
			selection, 
			structures, 
			theatre, 
			units, 
			user, 
			users 
		} = this.state;
		const airWings = armies.filter(a => a.type === 'air');
		const mixedArmies = armies.filter(a => a.type === 'land' && a.gameUnit.findIndex(u => units[u.unit_id].unit_class_id === 5 || units[u.unit_id].unit_class_id === 6) !== -1);
		let activeCombat = null;

		if (reconnecting) {
			return <Connect time={reconnectTime} onAbortConnect={this.handleAbortConnect} />;
		}

		if (! connected) {
			return <Loading className="loader" />;
		}

		if (game.paused === 1 || user.status === 'defeated' || user.status === 'won' || user.status === 'left') {
			return <UserOverview factions={factions} user={user} users={users} onReady={this.handleReady} />;
		}

		if (game.phase === 'policy') {
			return <PolicyForm game={game} policies={policies} onSelection={this.handlePolicySelection} />;
		}

		if (game.phase === 'combat-prepare') {
			const combat = combats.find(c => c.phase === 'position');
			const match = this.props.location.pathname.match(/\/game\/view\/combat\/([0-9]+)/);

			if (! match || parseInt(match[1]) !== combat.id) {
				this.props.history.push(`/game/view/combat/${combat.id}`);
			}

			return <Combat combat={combat} game={game} user={user} onMoveUnit={this.handleMoveUnit} />;
		}

		if (game.phase === 'combat-active') {
			const match = this.props.location.pathname.match(/\/game\/view\/combat\/([0-9]+)/);

			if (match && match[0] && match[1]) {
				activeCombat = combats.find(c => c.result === 'InProgress' && c.id === parseInt(match[1]));	
			}
		}

		return (
			<div id="game" ref={this.game}>
				<div id="status-container">
				    <div id="status-menu">
						<NavLink to="/game/view/area">Areas</NavLink>
						<NavLink to="/game/view/army">Armies</NavLink>
						<NavLink to="/game/view/airforce">Air Force</NavLink>
						<NavLink to="/game/view/combat">Combat</NavLink>
						<NavLink to="/game/view/construction">Construction</NavLink>
						<NavLink to="/game/view/unit">Units</NavLink>
						<NavLink to="/game/view/tech">Technology</NavLink>
						<NavLink to="/game/view/stats">Statistics</NavLink>
					</div>
					<div id="status-list">
					<Switch>
						<Route exact path="/game/view/airforce" render={props => 
							<AirForceList {...props}
								airWings={airWings}
								areas={areas}
								combats={combats}
								game={game}
								mixedArmies={mixedArmies}
								user={user}
								units={units}
								onAction={this.handleArmyAction}
							/>} 
						/>
						<Route path="/game/view/area/region/:id" render={props => <RegionView {...props} areas={areas} factions={factions} />} />
						<Route path="/game/view/area/:id/armies/:factionId" render={props => 
							<IntelligenceView {...props} 
								areas={areas} 
								borderingAreas={borderingAreas}
								factions={factions}
								user={user}
								onExpedition={this.handleArmyExpedition}
							/> 
						} />
						<Route path="/game/view/area/:id/installation/:structureId" render={props => 
							<StructureView {...props} 
								areas={areas} 
								structures={structures} 
							/>
						} />
						<Route path="/game/view/area/:id" render={props => 
							<AreaView {...props} 
								airWings={airWings}
								areas={areas} 
								armies={armies} 
								combats={combats}
								factions={factions}
								game={game}
								structures={structures}
								units={units}
								user={user}
								onAction={this.handleArmyAction}
								onAreaTransfer={this.handleAreaTransfer}
								onCancel={this.handleCancel}
								onNewStructure={this.handleNewStructure}
								onNewUnit={this.handleNewUnit}
								onPriorityChange={this.handlePriorityChange} 
							/>
						} />
						<Route path="/game/view/area" render={props => <AreaList areas={areas} user={user} />} />
						<Route path="/game/view/army/:id/unit/:unitId" render={props => 
							<UnitView {...props} 
								armies={armies}
								game={game}
								units={units}
							/>
						} />
						<Route path="/game/view/army/:id(\d+)/:action?" render={props => 
							<ArmyView {...props} 
								areas={areas} 
								armies={armies}
								combats={combats}
								game={game}
								units={units}
								user={user}
								onAction={this.handleArmyAction}
								onExpedition={this.handleArmyExpedition}
								onMerge={this.handleArmyMerge}
								onRename={this.handleArmyRename}
								onSplit={this.handleArmySplit}
								onUsage={this.handleAbilityUsage}
							/>} 
						/>
						<Route exact path="/game/view/army/:selection?" render={props => 
							<ArmyList {...props}
								areas={areas}
								armies={armies}
								combats={combats}
								game={game}
								selection={selection}
								units={units}
								user={user}
								onAction={this.handleArmyAction}
								onExpedition={this.handleArmyExpedition}
								onMerge={this.handleArmyMerge}
								onUsage={this.handleAbilityUsage}
							/>} 
						/>
						<Route path="/game/view/combat/:id" render={props => <CombatView {...props} combats={combats} units={units} />} />
						<Route path="/game/view/combat" render={props => 
							<CombatList {...props} 
								areas={areas} 
								armies={armies} 
								combats={combats}
								units={units}
							/>} 
						/>
						<Route path="/game/view/stats/combat/:id" render={props => 
							<CombatHistoryView {...props}
								combats={combats}
								completedCombats={completedCombats}
								equipment={equipment}
								factions={factions}
								user={user}
							/>}
						/>
						<Route path="/game/view/stats/combat" render={props => 
							<CombatHistoryList
								combats={combats}
								completedCombats={completedCombats}
								user={user}
							/>}
						/>
						<Route path="/game/view/stats" render={props => 
							<StatsView
								areas={areas}
								armies={armies}
								combats={combats}
								completedCombats={completedCombats}
								equipment={equipment}
								factions={factions}
								structures={structures}
								units={units}
								user={user}
							/>}
						/>
						<Route path="/game/view/construction/:id" render={props => 
							<StructureCreateView {...props} 
								areas={areas}
								structures={structures} 
								user={user}
								onHighlightAreas={this.handleHighlightAreas}
								onNewStructure={this.handleNewStructure}
							/>} 
						/>
						<Route path="/game/view/construction" render={props => <StructureList {...props} 
								areas={areas} 
								structures={structures}
								user={user} 
							/>}
						/>
						<Route path="/game/view/tech" render={props => 
							<TechView {...props} 
								game={game}
								user={user}
								onActivate={this.handleAbilityActivate}
								onUsage={this.handleAbilityUsage}
							/>} 
						/>
						<Route path="/game/view/unit/:id" render={props => 
							<UnitCreateView {...props} 
								areas={areas}
								units={units} 
								user={user}
								onHighlightAreas={this.handleHighlightAreas}
								onNewUnit={this.handleNewUnit}
							/>} 
						/>
						<Route path="/game/view/unit" render={props => <UnitList {...props} 
								areas={areas} 
								structures={structures}
								units={units}
								user={user}
							/>} 
						/>
					</Switch>
					</div>
				</div>
				{activeCombat && <CombatActive {...this.props}
					combat={activeCombat}
					combats={combats}
					game={game} 
					user={user} 
					onAttackUnit={this.handleAttackUnit} 
					onMoveUnit={this.handleMoveUnit} 
				/>}
				<Overview areas={areas} armies={armies} game={game} units={units} user={user} onPause={this.handlePause} onQuit={this.handleQuit} />
				<Map {...this.props}
					activeAbility={activeAbility}
					areas={areas} 
					airWings={airWings}
					armies={armies}
					borderingAreas={borderingAreas} 
					combats={combats}
					factions={factions}
					fullscreen={fullscreen}
					game={game}
					highlightedAreas={highlightedAreas}
					pings={pings}
					selection={selection}
					structures={structures}
					theatre={theatre}
					units={units}
					user={user}
					users={users}
					onAcceptTrade={this.handleAcceptTrade}
					onArmyAction={this.handleArmyAction}
					onArmyMerge={this.handleArmyMerge}
					onCancelTrade={this.handleCancelTrade}
					onFullscreenToggle={this.handleFullscreenToggle}
					onNewStructure={this.handleNewStructure}
					onNewUnit={this.handleNewUnit}
					onSelection={this.handleSelection}
					onSendMapPath={this.handleSendMapPath}
					onSendMapPing={this.handleSendMapPing}
					onTrade={this.handleTrade}
					onUsage={this.handleAbilityUsage}
				/>
				<History history={history} />
			</div>
		)
	};
}

export default Game;
