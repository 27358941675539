import React from 'react';

const Input = ({ name, label, error, ...others }) => {
	if (others.type === 'hidden') {
		return <input name={name} {...others} className="form-control" id={name} />;
	}

	return (
		<div className="form-group">
			<label htmlFor={name}>{label}</label>
			<input name={name} {...others} className="form-control" id={name} />
			{error && <div className="alert alert-danger">{error}</div>}
		</div>
	);
}

export default Input;
