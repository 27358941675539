import React, { Component } from 'react';

class ContextMenu extends Component {
	onArmyAction = (event, action) => {
		const { area, selectedArmies, units } = this.props.data;
		const targetId = area.id;

		if (! selectedArmies.length) {
			return;
		}

		const data = [];

		selectedArmies.forEach(army => {
			let hasAir = false;
			let hasArtillery = false;

			if (action === 'attack' && army.type === 'air') {
				return;
			}

			if ((action === 'attack' || action === 'support_attack' || action === 'artillery_barrage' || action === 'air_strike') && this.props.game.phase !== 'combat' && this.props.game.phase !== 'combat-active') {
				return;
			}

			army.gameUnit.forEach(u => {
				if (units[u.unit_id].unit_class_id === 5 || units[u.unit_id].unit_class_id === 6) {
					hasAir = true;
				} else if (units[u.unit_id].unit_class_id === 7) {
					hasArtillery = true;
				}
			});

			if (action === 'air_strike' && ! hasAir) {
				return;
			}

			if (action === 'artillery_barrage' && ! hasArtillery) {
				return;
			}

			if ((army.gameFlag.hasOwnProperty('army-entrenching') || army.gameFlag.hasOwnProperty('army-entrenching-fast') || army.gameFlag.hasOwnProperty('army-entrenched') ||
				army.gameFlag.hasOwnProperty('army-detrenching') || army.gameFlag.hasOwnProperty('army-detrenching-fast')) && action !== 'retreat') {
				return;
			}

			return data.push({id: army.id, action, target_id: targetId, add: event.shiftKey});
		});

		if (data.length) {
			this.props.onArmyAction(data);
		}

		this.props.onClose();
	};

	onArmyMerge = () => {
		const { selectedArmies } = this.props.data;

		if (! selectedArmies.length) {
			return;
		}

		selectedArmies.sort((a, b) => a.gameUnit.length > b.gameUnit.length);

		const main = selectedArmies.shift();
		const merges = [];

		selectedArmies.forEach(a => {
			merges.push(a.id);
		});

		this.props.onArmyMerge({id: main.id, merges});
		this.props.onClose();
	};

	onAreaAction = (type, id) => {
		const areaId = this.props.data.area.id;

		if (type === 'structure') {
			this.props.onNewStructure({structure_id: id, area_id: areaId });
		} else if (type === 'unit') {
			this.props.onNewUnit({unit_id: id, area_id: areaId });
		}

		this.props.onClose();
	};

	render() {
		const { area, className, selectedArmies, x, y, visible, user, units } = this.props.data;

		if (! visible) {
			return null;
		}

		const canAttack = selectedArmies.findIndex(a => a.type === 'land');
		const canAirStrike = selectedArmies.findIndex(a => a.type === 'air' || a.gameUnit.findIndex(u => units[u.unit_id].unit_class_id === 5 || units[u.unit_id].unit_class_id === 6) !== -1);
		const canBarrage = selectedArmies.findIndex(a => a.gameUnit.findIndex(u => units[u.unit_id].unit_class_id === 7) !== -1);
		const cancel = selectedArmies.find(a => a.armyAction.length && a.armyAction[0].action !== 'retreat' && a.armyAction[0].action !== 'redeployment');
		const reserve = selectedArmies.find(a => ! a.armyAction.length);
		const retreat = selectedArmies.find(a => a.battleArmy.length);
		const attack = canAttack !== -1 && selectedArmies.length && ! user.alliance[area.faction_id] && selectedArmies[0].game_area_id !== area.id ? true : false;
		const supportAttack = canAttack !== -1 && selectedArmies.length && ! user.alliance[area.faction_id] && selectedArmies[0].game_area_id !== area.id ? true : false;
		const airStrike = canAirStrike !== -1 && selectedArmies.length && ! user.alliance[area.faction_id] && selectedArmies[0].game_area_id !== area.id ? true : false;
		const artilleryBarrage = canBarrage !== -1 && selectedArmies.length && ! user.alliance[area.faction_id] && selectedArmies[0].game_area_id !== area.id ? true : false;
		const move = selectedArmies.length && user.alliance[area.faction_id] && selectedArmies[0].game_area_id !== area.id ? true : false;
		const redeployment = selectedArmies.length && user.alliance[area.faction_id] && selectedArmies[0].game_area_id !== area.id ? true : false;
		const isCombatPhase = this.props.game.phase === 'combat' || this.props.game.phase === 'combat-active';
		let merge = selectedArmies.length > 1;

		selectedArmies.forEach(a => {
			merge = merge === true && a.game_area_id === selectedArmies[0].game_area_id;
		})

		return (
			<div id="context" className={`context-menu${className}`} style={{left: x, top: y}}>
				<ul className="context-menu-top">
					<li className="heading">{area.area.name}</li>
					{attack && ! retreat && isCombatPhase && <li onClick={event => this.onArmyAction(event, 'attack')}><span>Attack</span></li>}
					{supportAttack && ! retreat && isCombatPhase && <li onClick={event => this.onArmyAction(event, 'support_attack')}><span>Support Attack</span></li>}
					{airStrike && ! retreat && isCombatPhase && <li onClick={event => this.onArmyAction(event, 'air_strike')}><span>Air Strike</span></li>}
					{artilleryBarrage && ! retreat && isCombatPhase && <li onClick={event => this.onArmyAction(event, 'artillery_barrage')}><span>Artillery Barrage</span></li>}
					{move && <li onClick={event => this.onArmyAction(event, 'move')}><span>Move</span></li>}
					{redeployment && ! retreat && <li onClick={event => this.onArmyAction(event, 'redeployment')}><span>Redeploy</span></li>}
					{merge && ! retreat && <li onClick={() => this.onArmyMerge()}><span>Merge</span></li>}
					{reserve && <li onClick={event => this.onArmyAction(event, 'reserve')}><span>Reserve</span></li>}
					{cancel && <li onClick={event => this.onArmyAction(event, 'cancel')}><span>Cancel Action</span></li>}
					{area && area.availableStructure && !!area.availableStructure.length && !selectedArmies.length && <li>
						<span>Installations</span>
						<ul>
							{area.availableStructure.map(s => (
								<li key={s.id} onClick={() => this.onAreaAction('structure', s.id)}><span>{s.name}</span></li>
							))}
						</ul>
					</li>}
					{area && area.availableUnit && !!area.availableUnit.length && !selectedArmies.length && <li>
						<span>Units</span>
						<ul>
							{area.availableUnit.map(u => (
								<li key={u.id} onClick={() => this.onAreaAction('unit', u.id)}><span>{u.name}</span></li>
							))}
						</ul>
					</li>}
					<li onClick={() => this.props.onClose()}><span>Close</span></li>
				</ul>
			</div>
		);
	}
}

export default ContextMenu;
