import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class UnitCreate extends Component {
	componentDidMount() {
		const id = parseInt(this.props.match.params.id);
		const areas = this.props.areas.filter(a => a.availableUnit.length && a.availableUnit.findIndex(u => u.id === id) !== -1);

		if (! areas.length) {
			this.props.history.replace('/game/view/unit');
		}

		this.props.onHighlightAreas(areas.reduce((ids, area) => {
			ids[area.area.id] = 1;

			return ids;
		}, {}));
	};

	componentWillUnmount() {
		this.props.onHighlightAreas(null);
	};

	getAreaQueue(area, unitId) {
		if (! area.unitDevelopment.length) {
			return null;
		}

		const count = area.unitDevelopment.filter(d => d.unit_id === parseInt(unitId)).length;

		return `${area.unitDevelopment.length} in queue (${count})`;
	};

	renderTechBonus(unit, field) {
		const { user } = this.props;
		const techType = (['soft_attack', 'hard_attack', 'air_attack'].indexOf(field) !== -1) ? 'weapons' : 'armor';
		let techLevel = 0;
		let policyBonus = 0;

		if ([1].indexOf(unit.unitClass.id) !== -1) {
			techLevel = user.gameFlag[`infantry-${techType}`] ? Object.keys(user.gameFlag[`infantry-${techType}`]).length : 0;
		} else if ([2, 3, 4, 7, 8, 9, 11, 12].indexOf(unit.unitClass.id) !== -1) {
			techLevel = user.gameFlag[`vehicle-${techType}`] ? Object.keys(user.gameFlag[`vehicle-${techType}`]).length : 0;
		} else if ([5, 6, 10].indexOf(unit.unitClass.id) !== -1) {
			techLevel = user.gameFlag[`aircraft-${techType}`] ? Object.keys(user.gameFlag[`aircraft-${techType}`]).length : 0;
		}

		if (user.military_policy_id === 12) {
			if (field === 'soft_attack' && [5, 6, 7].indexOf(unit.unitClass.id) !== -1) {
				policyBonus = 2;
			} else if (field === 'hard_attack') {
				policyBonus = 2;
			}
		} else if (user.military_policy_id === 13 && field === 'defensiveness' && unit.unitClass.id === 1) {
			policyBonus = 10;
		}

		return unit[field] + Math.ceil(unit[field] / 10) * techLevel + policyBonus;
	};

	handleClick = (areaId, unitId) => {
		this.props.onNewUnit({area_id: areaId, unit_id: unitId});
	};

	render() {
		const { user } = this.props;
		const id = parseInt(this.props.match.params.id);
		const areas = this.props.areas.filter(a => a.availableUnit.length && a.availableUnit.findIndex(u => u.id === id) !== -1);
		const unit = this.props.units[id];

		return (
			<React.Fragment>
				<div className="status-province">
					<div className="status-province-area">{unit.name}</div>
				</div>
				<div className="status-province-large">
					<div className="status-province-heading">Information</div>
					<div className="status-province-list">
						<div>
							<div className="status-province-list-name">Unit Class</div>
							<div className="status-province-list-count">{unit.unitClass.name}</div>
						</div>
						<div>
							<div className="status-province-list-name">Manpower</div>
							<div className="status-province-list-count">{unit.manpower}</div>
						</div>
						<div>
							<div className="status-province-list-name">Development Time</div>
							<div className="status-province-list-count">{unit.development_time}</div>
						</div>
						<div>
							<div className="status-province-list-name">Soft Attack</div>
							<div className="status-province-list-count">{this.renderTechBonus(unit, 'soft_attack')}</div>
						</div>
						<div>
							<div className="status-province-list-name">Hard Attack</div>
							<div className="status-province-list-count">{this.renderTechBonus(unit, 'hard_attack')}</div>
						</div>
						<div>
							<div className="status-province-list-name">Air Attack</div>
							<div className="status-province-list-count">{this.renderTechBonus(unit, 'air_attack')}</div>
						</div>
						<div>
							<div className="status-province-list-name">Defensiveness</div>
							<div className="status-province-list-count">{this.renderTechBonus(unit, 'defensiveness')}</div>
						</div>
						<div>
							<div className="status-province-list-name">Toughness</div>
							<div className="status-province-list-count">{this.renderTechBonus(unit, 'toughness')}</div>
						</div>
						<div>
							<div className="status-province-list-name">Air Defence</div>
							<div className="status-province-list-count">{this.renderTechBonus(unit, 'air_defence')}</div>
						</div>
						<div>
							<div className="status-province-list-name">Softness</div>
							<div className="status-province-list-count">{unit.softness}</div>
						</div>
						<div>
							<div className="status-province-list-name">Speed</div>
							<div className="status-province-list-count">{unit.speed}</div>
						</div>
					</div>
				</div>
				<div className="status-province-large">
					<div className="status-province-heading">Equipment</div>
					{unit.unitEquipment.map(e => (
					<div className="status-province-list" key={e.id}>
						<div>
							<div className="status-province-list-name">{e.equipment.name}</div>
							<div className="status-province-list-count">{e.value}</div>
						</div>
					</div>
					))}
				</div>
				{areas.sort((a, b) => a.area.name.localeCompare(b.area.name)).map(area => (
					<div className="status-province queue" key={area.area.id}>
						<Link key={area.area.id} className="link" to={`/game/view/area/${area.area.id}`}>{area.area.name}</Link>
						<div className="current-queue">{this.getAreaQueue(area, id)}</div>
						{user.manpower < unit.manpower && <button className="add-production" disabled>+</button>}
						{user.manpower >= unit.manpower && <button className="add-production" onClick={() => this.handleClick(area.id, id)}>+</button>}
					</div>
				))}
			</React.Fragment>
		)
	}
}

export default UnitCreate;
