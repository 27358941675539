import React, { Component } from 'react';

class Area extends Component {
	render() {
		const { areas, id, structures } = this.props;
		const area = areas.find(a => a.id === id);
		const factions = this.props.factions.reduce((map, faction) => {
			map[faction.id] = faction;

			return map;
		}, {});

		return (
			<div id="side-container">
				<div className="title">{area.area.name}</div>
				<div className="overview">
					<div className="row">
						<div className="text">Region</div>
						<div className="value">{area.area.region.name}</div>
					</div>
					<div className="row">
						<div className="text">Faction</div>
						<div className="value"><div className={`owner user-colour faction-${factions[area.faction_id].id}`}></div>{factions[area.faction_id].name}</div>
					</div>
				</div>
				<div className="title">Installations</div>
				{Object.keys(area.gameStructure).length === 0 && <div className="overview">
					<div className="data-row">
						<div className="text">No recorded constructions</div>
					</div>
				</div>}
				{Object.keys(area.gameStructure).length !== 0 && <div className="overview">
				{Object.keys(area.gameStructure).map(s => (
					<div className="data-row" key={s}>
						<div className="text">{structures[s]}</div>
						<div className="value">{area.gameStructure[s]}</div>
					</div>
				))}
				</div>}
				<div className="title">History</div>
				{area.history.length === 0 && <div className="overview">
					<div className="data-row">
						<div className="text">No recorded history</div>
					</div>
				</div>}
				{area.history.length !== 0 && <div className="overview">
				{area.history.map((o, k) => (
					<div className="data-row" key={k}>
						<div className="date">{o.date}</div>
						<div className="record">{o.record}</div>
						<div className="faction"><div className={`owner user-colour faction-${o.faction}`}></div></div>
					</div>
				))}
				</div>}
			</div>
		);
	}
}

export default Area;
