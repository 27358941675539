import React, { Component } from 'react';
import service from '../../services/unitService';

class Unit extends Component {
	state = {
		units: []
	};

	componentDidMount = async () => {
		const { data: units } = await service.all();

		this.setState({ units });
	};

	render() {
		const { units } = this.state;

		return (
			<React.Fragment>
				<div className="content_header"><h1>Units</h1></div>
				<div className="content_content">
				{Object.keys(units).map(faction => (
					<div className="sub_content" key={faction}>
						<div className="sub_content_heading">
							{faction}
						</div>
						<div>
							{units[faction].map(unit => (
							<p key={unit.id}>
								<span className="sub_title">{unit.name}</span><br />
								Class: {unit.unit_class}<br />
								Prerequisites: {unit.prerequisites.join(', ')}<br />
								Cost: {unit.cost}<br />
						        Development Time: {unit.development_time}<br />
						        Defensiveness: {unit.defensiveness}<br />
						        Softness: {unit.softness}<br />
						        Hardness: {unit.toughness}<br />
						        Soft Attack: {unit.soft_attack}<br />
						        Hard Attack: {unit.hard_attack}<br />
						        Air Attack: {unit.air_attack}<br />
						        Air Defence: {unit.air_defence}<br />
						        Speed: {unit.speed}<br />
						        <br />
								{unit.description}
							</p>
						))}
						</div>
					</div>				
				))}
				</div>
			</React.Fragment>
		);
	};
}

export default Unit;
