import React from 'react';
import { formatDate } from '../utilities/date.js';

const History = ({ history }) => {
	return (
		<div id="history-container">
			{history.map(row => (
				<div key={row.id} className="history-entry">
					{formatDate(row.history_date.date)} : {row.value}
				</div>
			))}
		</div>
	)
}

export default History;
