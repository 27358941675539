import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class StructureCreate extends Component {
	componentDidMount() {
		const id = parseInt(this.props.match.params.id);
		const areas = this.props.areas.filter(a => a.availableStructure.length && a.availableStructure.findIndex(s => s.id === id) !== -1);

		if (! areas.length) {
			return this.props.history.replace('/game/view/construction');
		}

		this.props.onHighlightAreas(areas.reduce((ids, area) => {
			ids[area.area.id] = 1;

			return ids;
		}, {}));
	};

	componentWillUnmount() {
		this.props.onHighlightAreas(null);
	};

	getAreaQueue(area, structureId) {
		if (! area.structureDevelopment.length) {
			return null;
		}

		const count = area.structureDevelopment.filter(d => d.structure_id === parseInt(structureId)).length;

		return `${area.structureDevelopment.length} in queue (${count})`;
	};

	handleClick = (areaId, structureId) => {
		this.props.onNewStructure({area_id: areaId, structure_id: structureId});
	};

	render() {
		const { user } = this.props;
		const id = parseInt(this.props.match.params.id);
		const structure = this.props.structures[id];
		const areas = this.props.areas.filter(a => a.availableStructure.length && a.availableStructure.findIndex(s => s.id === id) !== -1);

		return (
			<React.Fragment>
				<div className="status-province">
					<div className="status-province-area">{structure.name}</div>
				</div>
				<div className="status-province-large">
					<div className="status-province-heading">Information</div>
					<div className="status-province-list">
						<div>
							<div className="status-province-list-name">Class</div>
							<div className="status-province-list-count">{structure.structureClass.name}</div>
						</div>
						<div>
							<div className="status-province-list-name">Cost</div>
							<div className="status-province-list-count">{structure.cost}</div>
						</div>
						<div>
							<div className="status-province-list-name">Development Time</div>
							<div className="status-province-list-count">{structure.development_time}</div>
						</div>
						<div>
							<div className="status-province-list-name">Power</div>
							<div className="status-province-list-count">{structure.power}</div>
						</div>
					</div>
				</div>
				{areas.sort((a, b) => a.area.name.localeCompare(b.area.name)).map(area => (
					<div className="status-province queue" key={area.area.id}>
						<Link key={area.area.id} className="link" to={`/game/view/area/${area.area.id}`}>{area.area.name}</Link>
						<div className="current-queue">{this.getAreaQueue(area, id)}</div>
						{user.money < structure.cost && <button className="add-production" disabled>+</button>}
						{user.money >= structure.cost && <button className="add-production" onClick={() => this.handleClick(area.id, id)}>+</button>}
					</div>
				))}
			</React.Fragment>
		)
	}
}

export default StructureCreate;
