import React from 'react';
import Joi from 'joi-browser';
import Form from '../common/form';

class ResourceForm extends Form {
	state = {
		data: {
			action: null,
			money: 0,
			supplies: 0,
			fuel: 0,
			factionId: null,
			daily: false
		},
		errors: {}
	};

	schema = {
		action: Joi.string().required().label('Action'),
		money: Joi.number().required().label('Money'),
		supplies: Joi.number().required().label('Supplies'),
		fuel: Joi.number().required().label('Fuel'),
		factionId: Joi.number().required().label('Faction'),
		daily: Joi.boolean().required().label('Daily')
	};

	constructor(props) {
		super(props);

		this.state.data.action = this.props.action;
	}

	componentDidMount() {
		const user = this.props.user;
		const data = {...this.state.data};

		Object.keys(user.alliance).some(a => {
			if (parseInt(a) !== user.faction_id) {
				data.factionId = a;

				this.setState({ data });

				return true;
			}

			return false;
		});
	};

	doSubmit = () => {
		const data = {...this.state.data};
		const user = this.props.user;
		let valid = true;

		['money', 'fuel', 'supplies'].forEach(f => {
			if (data[f] > user[f]) {
				valid = false;
			} else if (data[f] < 0) {
				valid = false;
			}
		});

		if (valid) {
			this.props.onTrade(data);

			data.money = 0;
			data.supplies = 0;
			data.fuel = 0;
			data.daily = false;

			this.setState({ data });
		}
	};

	render() {
		const { factions, label, user } = this.props;
		const allianceMembers = Object.values(user.alliance).filter(a => a !== user.faction_id);

		return (
			<form onSubmit={this.handleSubmit}>
				<div className="column"><input name="money" type="number" min="0" max={user.money} step="0.01" value={this.state.data.money} onChange={event => this.handleChange(event)} /></div>
				<div className="column"><input name="supplies" type="number" min="0" max={user.supplies} step="0.01" value={this.state.data.supplies} onChange={event => this.handleChange(event)} /></div>
				<div className="column"><input name="fuel" type="number" min="0" max={user.fuel} step="0.01" value={this.state.data.fuel} onChange={event => this.handleChange(event)} /></div>
				<div className="column large">
					{allianceMembers.length > 1 && <select name="factionId" onChange={event => this.handleChange(event)}>
						{Object.keys(user.alliance).map(a => <React.Fragment key={a}>
							{parseInt(a) !== user.faction_id && <option value={a}>{factions[a].name}</option>}
						</React.Fragment>)}
					</select>}
					{allianceMembers.length === 1 && <span>{factions[allianceMembers[0]].name}</span>}
				</div>
				<div className="column small"><input name="daily" type="checkbox" value="1" checked={this.state.data.daily} onChange={event => this.handleChecked(event)} /></div>
				<div className="column"><button type="submit">{label}</button></div>
			</form>
		);
	}
}

export default ResourceForm;
