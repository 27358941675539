
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import MissionForm from '../forms/missionForm';
import { ReactComponent as Merge } from '../../images/merge.svg';
import { ReactComponent as Tactics } from '../../images/tactics.svg';
import { ReactComponent as Ammunition } from '../../images/ammunition.svg';
import { ReactComponent as Plan } from '../../images/plan.svg';
import { ReactComponent as Manpower } from '../../images/massmanpower.svg'
import { ReactComponent as Buff } from '../../images/uparrow.svg'
import { ReactComponent as Reserve } from '../../images/reserve.svg'
import { ReactComponent as Loan } from '../../images/loan.svg';
import { ReactComponent as Return } from '../../images/return.svg';

class Army extends Component {
	renderAbility(armyAreas, ability, title, cost, Icon) {
		const { units, user } = this.props;
		const allianceMember = Object.values(user.alliance).find(a => a !== user.faction_id);
		const eligibleArmies = [];

		if (ability === 'unit-blitz' && user.military_policy_id !== 11) {
			return null;
		}

		if (ability === 'unit-shock-and-awe' && user.military_policy_id !== 12) {
			return null;
		}

		if (ability === 'army-plan-offensive' && user.military_policy_id !== 13) {
			return null;
		}

		if (ability === 'unit-unrelenting-assault' && user.military_policy_id !== 14) {
			return null;
		}

		if (ability === 'merge' && armyAreas.length > 1) {
			return null;
		}

		if (ability === 'loan') {
			if (! allianceMember) {
				return null;
			}

			armyAreas.forEach(area => {
				area.armies.forEach(army => {
					if (army.initial_faction_id === user.faction_id) {
						eligibleArmies.push(army.id);
					}
				});
			});

			if (eligibleArmies.length && allianceMember) {
				return <div className="army-ability" title={title} onClick={() => this.props.onExpedition(allianceMember, eligibleArmies)}>
					{Icon}
				</div>;
			}

			return null;
		}

		if (ability === 'return') {
			if (! allianceMember) {
				return null;
			}
			
			armyAreas.forEach(area => {
				area.armies.forEach(army => {
					if (army.initial_faction_id !== user.faction_id) {
						eligibleArmies.push(army.id);
					}
				});
			});

			if (eligibleArmies.length) {
				return <div className="army-ability" title={title} onClick={() => this.props.onExpedition(allianceMember, eligibleArmies)}>
					{Icon}
				</div>;
			}

			return null;
		}

		let fuelCost = 0;
		let supplyCost = 0;
		let reserves = 0;
		let armies = 0;

		armyAreas.forEach(area => {
			area.armies.forEach(army => {
				let canUseAbility = !army.armyAction.length && !army.battleArmy.length && !army.gameFlag.hasOwnProperty('army-plan-offensive');
				let isFortify = army.gameFlag.hasOwnProperty('army-entrenching') || army.gameFlag.hasOwnProperty('army-detrenching');

				if (isFortify || ! canUseAbility) {
					return;
				}

				if (ability === 'army-reserve') {
					if (army.reserve === 1) {
						reserves ++;
					}

					armies ++;

					eligibleArmies.push(army);

					return;
				}

				if (cost > 0 && !army.supplied) {
					return;
				}

				let logistics = 0;
				let unitFlagActive = false;

				army.gameUnit.forEach(u => {
					let unit = units[u.unit_id];

					if (unit.unitClass.id === 11) {
						logistics = u.experience;
					}

					unitFlagActive = unitFlagActive === false ? u.gameFlag.hasOwnProperty(ability) : unitFlagActive;
					unitFlagActive = unitFlagActive === false ? u.gameFlag.hasOwnProperty('unit-plan-offensive') : unitFlagActive;
				});

				if (unitFlagActive) {
					return;
				}

				if (cost) {
					army.gameUnit.forEach(u => {
						let unit = units[u.unit_id];
						let modifier = logistics > 0 ? (1 - (0.2 * (1 + logistics / 10000))) : 1;

						if (user.military_policy_id === 14 && unit.unitClass.id === 1) {
							modifier -= 0.2;
						}

						supplyCost += unit.supply_consumption * modifier;
						fuelCost += unit.fuel_consumption * modifier;
					});
				}

				if ((ability === 'merge' && army.initial_faction_id === user.faction_id) || ability !== 'merge') {
					eligibleArmies.push(army);
				}
			});
		});

		if (ability === 'army-reserve' && eligibleArmies.length) {
			if (reserves > 0 && reserves === armies) {
				return <div className="army-ability active" title={title} onClick={() => this.doUsage('army-reserve', eligibleArmies)}>
					{Icon}
				</div>;
			} else {
				return <div className="army-ability" title={title} onClick={() => this.doUsage('army-reserve', eligibleArmies)}>
					{Icon}
				</div>;
			}
		}

		if (ability === 'merge') {
			if (eligibleArmies.length <= 1) {
				return null;
			} else if (eligibleArmies.length) {
				return <div className="army-ability" title={title} onClick={() => this.doMerge(eligibleArmies)}>
					{Icon}
				</div>;
			} else {
				return <div className="army-ability inactive" title="You cannot merge these armies at this time">
					{Icon}
				</div>;
			}
		}

		if (! eligibleArmies.length) {
			return <div className="army-ability inactive" title={title}>
				{Icon}
			</div>;
		}

		if (cost) {
			supplyCost *= cost;

			if (supplyCost > 0 && supplyCost > user.supplies) {
				const supplyDeficit = (supplyCost - user.supplies).toFixed(2);

				return <div className="army-ability inactive" title={`${title}: We require ${supplyDeficit} more supplies`}>
					{Icon}
				</div>;
			}

			fuelCost *= cost;

			if (fuelCost > 0 && fuelCost > user.fuel) {
				const fuelDeficit = (fuelCost - user.fuel).toFixed(2);

				return <div className="army-ability inactive" title={`${title}: We require ${fuelDeficit} more fuel`}>
					{Icon}
				</div>;
			}
		}

		return <div className="army-ability" title={title} onClick={() => this.doUsage(ability, eligibleArmies)}>
			{Icon}
		</div>;
	};

	doMerge(eligibleArmies) {
		if (! eligibleArmies.length) {
			return;
		}

		const data = {id: null, merges: []};
		let largestArmy = null;

		eligibleArmies.forEach(a => {
			if (a.gameUnit.length > largestArmy) {
				data.id = a.id;

				largestArmy = a.gameUnit.length;
			}
		});

		eligibleArmies.forEach(a => {
			if (a.id !== data.id) {
				data.merges.push(a.id);
			}
		});

		this.props.onMerge(data);
	};

	doUsage(ability, eligibleArmies) {
		if (! eligibleArmies.length) {
			return;
		}

		const armyIds = [];

		eligibleArmies.forEach(a => {
			armyIds.push(a.id);
		});

		this.props.onUsage(ability, armyIds);
	};

	groupArmies(armies) {
		const areas = this.props.areas;
		const armyAreas = {};

		armies.forEach(army => {
			if (! armyAreas[army.game_area_id]) {
				let area = areas.find(a => a.id === army.game_area_id);

				armyAreas[army.game_area_id] = {...area, armies: []};
			}

			armyAreas[army.game_area_id].armies.push(army);
		});

		return Object.values(armyAreas).sort((a, b) => a.area.name.localeCompare(b.area.name));
	};

	renderArmyBuff(army) {
		let hasActiveBuff = false;

		army.gameUnit.forEach(u => {
			if (u.gameFlag.hasOwnProperty('unit-blitz') || u.gameFlag.hasOwnProperty('unit-shock-and-awe') || 
				u.gameFlag.hasOwnProperty('unit-plan-offensive') || u.gameFlag.hasOwnProperty('unit-unrelenting-assault')) {
				hasActiveBuff = true;
			}
		});

		if (hasActiveBuff) {
			return <div className="icon" title="Army is currently on an offensive"><Buff fill="white" /></div>
		}
	};

	render() {
		const { areas, armies, game, selection, units, user, onAction } = this.props;
		const selectedArmies = this.props.match.params.selection ? armies.filter(a => selection.hasOwnProperty(a.id)) : null;	
		const armyAreas = this.groupArmies(this.props.location.pathname === '/game/view/army/selection' ? selectedArmies : armies);
		const armyTotals = this.props.location.pathname === '/game/view/army/selection' ? armyAreas.reduce((totals, area) => {
			area.armies.forEach(a => {
				totals.armies ++;
				totals.divisions += a.gameUnit.length;
			});

			return totals;
		}, {armies: 0, divisions: 0}) : null;

		return (
			<React.Fragment>
				{armyAreas.map(area => (
				<div className="status-army" key={area.area.id}>
					<div className="status-army-area">
						{area.area.name}
					</div>
					{[...area.armies].sort((a, b) => a.name.localeCompare(b.name)).map(army => (
						<div key={army.id} className="status-army-container">
							<div className="status-army-name">
								<Link className="army-link link" to={`/game/view/army/${army.id}`}>{army.name}</Link>
							</div>
							<div className="status-army-ability">
								{this.renderArmyBuff(army)}
							</div>
							<div className="status-army-count">
								{army.gameUnit.length}
							</div>
							<div className="status-province-list">
								<div className="status-province-list-name">
									<div className="status-army-container"><MissionForm 
										areas={areas} 
										army={army} 
										game={game} 
										units={units} 
										user={user} 
										onAction={onAction} 
									/></div>
								</div>
							</div>
						</div>
					))}
				</div>
				))}
				{armyTotals && armyTotals.armies >= 2 && <div className="army-totals">
					{armyTotals.armies} Armies ({armyTotals.divisions} Divisions)
				</div>}
				{selectedArmies && !!selectedArmies.length && <div className="army-abilities">
					{this.renderAbility(armyAreas, 'loan', 'Send Expeditionary Force', 0, <Loan />)}
					{this.renderAbility(armyAreas, 'return', 'Return Expeditionary Force', 0, <Return />)}
					{this.renderAbility(armyAreas, 'merge', 'Merge', 0, <Merge />)}
					{this.renderAbility(armyAreas, 'army-reserve', 'Reserve', 0, <Reserve />)}
					{this.renderAbility(armyAreas, 'unit-blitz', 'Blitz', 336, <Tactics />)}
					{this.renderAbility(armyAreas, 'unit-shock-and-awe', 'Shock and Awe', 336, <Ammunition />)}
					{this.renderAbility(armyAreas, 'army-plan-offensive', 'Plan Offensive', 0, <Plan />)}
					{this.renderAbility(armyAreas, 'unit-unrelenting-assault', 'Unrelenting Assault', 168, <Manpower />)}
				</div>}
		    </React.Fragment>
		)
	}
}

export default Army;
