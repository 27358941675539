import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Unit extends Component {
	getClassName(unitId, availableAreas) {
		if (availableAreas === 0) {
			return 'status-army disabled';
		}

		return 'status-army';
	};

	getUnitContent(unit) {
		const structures = this.props.structures;
		const unitPrerequisite = [];

		if (unit.unitPrerequisite) {
			unit.unitPrerequisite.forEach(u => {
				unitPrerequisite.push(structures[u.prerequisite_id].name);
			});
		}

		return <React.Fragment>
			<div className="status-create-name">
				{unit.name}
			</div>
			<div className="status-create-type">
				{unit.unitClass.name}
			</div>
			<div className="status-create-name-small">
				Requires: {unitPrerequisite.sort((a, b) => a.localeCompare(b)).join(', ')}
			</div>
		</React.Fragment>;
	};

	render() {
		const user = this.props.user;
		const areas = this.props.areas.filter(a => a.availableUnit.length);
		const airUnits = Object.values(this.props.units)
			.filter(u => u.factions[user.faction_id] && (u.unitClass.id === 5 || u.unitClass.id === 6 || u.unitClass.id === 10))
			.sort((a, b) => a.name.localeCompare(b.name));
		const landUnits = Object.values(this.props.units)
			.filter(u => u.factions[user.faction_id] && u.unitClass.id !== 5 && u.unitClass.id !== 6 && u.unitClass.id !== 10)
			.sort((a, b) => a.name.localeCompare(b.name));
		const availableAirAreas = [...airUnits].reduce((count, unit) => {
			count[unit.id] = areas.filter(a => a.availableUnit.findIndex(u => u.id === unit.id) !== -1).length;

			return count;
		}, {});
		const availableLandAreas = [...landUnits].reduce((count, unit) => {
			count[unit.id] = areas.filter(a => a.availableUnit.findIndex(u => u.id === unit.id) !== -1).length;

			return count;
		}, {});

		return <React.Fragment>
			<div className="status-province">
				<div className="status-province-area">
					Air
				</div>
			</div>
			{airUnits.map(u => (
				<div className={this.getClassName(u.id, availableAirAreas[u.id])} key={u.id}>
					{availableAirAreas[u.id] !== 0 && <Link to={`/game/view/unit/${u.id}`} className="link-block">
						{this.getUnitContent(u)}
					</Link>}
					{!availableAirAreas[u.id] && <div className="link-block">
						{this.getUnitContent(u)}
					</div>}
				</div>
			))}
			<div className="status-province">
				<div className="status-province-area">
					Land
				</div>
			</div>
			{landUnits.map(u => (
				<div className={this.getClassName(u.id, availableLandAreas[u.id])} key={u.id}>
					{availableLandAreas[u.id] !== 0 && <Link to={`/game/view/unit/${u.id}`} className="link-block">
						{this.getUnitContent(u)}
					</Link>}
					{!availableLandAreas[u.id] && <div className="link-block">
						{this.getUnitContent(u)}
					</div>}
				</div>
			))}
		</React.Fragment>;
	}
}

export default Unit;
