import React, { Component } from 'react';

class Ping extends Component {
	constructor(props) {
		super(props);

		this.radius = React.createRef();
		this.stroke = React.createRef();
		this.opacity = React.createRef();
	};

	componentDidMount() {
		this.radius.current.beginElement();
		this.stroke.current.beginElement();
		this.opacity.current.beginElement();
	};

	shouldComponentUpdate() {
		return false;
	};

	render() {
		const ping = this.props.ping;

		return (
			<circle cx={ping.x} cy={ping.y} r="5" className="ping">
				<animate ref={this.radius} attributeType="SVG" attributeName="r" begin="indefinite" dur="1s" repeatCount="4" from="5" to="50" />
				<animate ref={this.stroke} attributeType="CSS" attributeName="stroke-width" begin="indefinite" dur="1s" repeatCount="4" from="8" to="0" />
				<animate ref={this.opacity} attributeType="CSS" attributeName="opacity" begin="indefinite" dur="1s" repeatCount="4" from="1" to="0" fill="freeze" />
			</circle>
		);
	}
}

export default Ping;
