import React from 'react';
import Joi from 'joi-browser';
import Form from '../common/form';

class UnitForm extends Form {
	state = {
		data: {
			unit_id: '',
		},
		errors: {}
	};

	schema = {
		unit_id: Joi.string().required().label('Unit ID'),
	};

	doSubmit = async () => {
		const data = {...this.state.data, area_id: this.props.area.id};

		this.props.onSubmit(data);
	};

	render() {
		return (
			<form onSubmit={this.handleSubmit}>
				<select name="unit_id" id="unit_id" onChange={event => this.handleChange(event)}>
					<option value="">Available Units</option>
				{[...this.props.area.availableUnit].sort((a, b) => a.name.localeCompare(b.name)).map(o => (
					<option key={o.id} value={o.id}>{o.name}</option>
				))}
				</select>
				{this.renderButton('Go')}
			</form>
		);
	}
}

export default UnitForm;
